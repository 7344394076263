import './Blog.scss';

import React, { Fragment, useEffect, useState } from 'react';

import ReloadIcon from '../../../general/assets/svg/ReloadIcon';
import Preloader from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import usePagination from '../../../general/hooks/usePagination';
import { useResize } from '../../../general/hooks/useResize';
import { queryKeys } from '../../../general/queryKeys';
import createArticlesService from '../../../general/services/articles';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import { Article, PaggingObj } from '../../../general/types/types';
import BlogPost from '../../components/blog-post/BlogPost';
import Pagination from '../../components/pagination/Pagination';
import CanonicalLink from '../../components/SEO/CanonicalLink';

const Blog: React.FC = () => {
  const [articles, setArticles] = useState<PaggingObj<Article> | null>(null);

  const [isShowMore, setIsShowMore] = useState<number | null>(null);
  const [isShowMorePreloader, setIsShowMorePreloader] = useState<boolean>(false);
  const { width } = useResize();

  const defaultPageSize = width <= 768 ? 8 : width <= 1024 ? 10 : width <= 1440 ? 10 : 13;
  const { page, pageSize, setPage } = usePagination(defaultPageSize);

  const { isLoading: articleLoading, refetch: getArticles } = useCustomQuery<PaggingObj<Article>>(
    queryKeys.BLOGS,
    () => createArticlesService(clientInstance).getActiveArticles(pageSize, page),
    undefined,
    undefined,
    {
      keepPreviousData: false,
      onSuccess: (data: any) => {
        if (isShowMore === null) {
          window.scrollTo(0, 0);
          setArticles(data);
        }
      },
    },
  );

  const showMore = async () => {
    setIsShowMorePreloader(true);
    const currentPage = page;
    if (isShowMore === null) {
      setIsShowMore(currentPage);
    }

    const response = await createArticlesService(clientInstance).getActiveArticles(
      pageSize,
      currentPage + 1,
    );

    setPage(currentPage + 1);

    setArticles((prevArticles) => {
      if (!prevArticles) return response;
      const newPartion = response.data;
      return {
        ...newPartion,
        items: [...prevArticles.items, ...newPartion.items],
      };
    });

    setIsShowMorePreloader(false);
  };

  useEffect(() => {
    if (isShowMore === null) {
      getArticles();
    }
  }, [getArticles, page, isShowMore]);

  return (
    <>
      <CanonicalLink href={window.location.href} />
      <div className="page-container blog-section">
        {articleLoading && !articles ? (
          <div className="blog-posts__preloader">
            <Preloader />
          </div>
        ) : (
          <>
            <div className="blog-posts">
              {isShowMorePreloader && (
                <div className="blog-posts__overlay-preloader">
                  <Preloader />
                </div>
              )}
              {articles?.items?.map((article, index) => (
                <Fragment key={index}>
                  <BlogPost article={article} box={(index % 5) + 1} />
                </Fragment>
              ))}
            </div>
            <div className="pagination-section">
              {articles && articles.pageSize < articles.totalCount && articles.hasNextPage && (
                <div className="pagination-section__show-more">
                  <button className="show-more__button" onClick={showMore}>
                    <ReloadIcon />
                    <span>show more</span>
                  </button>
                </div>
              )}

              {articles && articles.pageSize < articles.totalCount && (
                <Pagination
                  page={page}
                  setPage={setPage}
                  isNextPage={articles.hasNextPage}
                  isPrevPage={articles.hasPreviousPage}
                  pageSize={articles.pageSize}
                  total={articles.totalCount}
                  isShowMore={isShowMore}
                  setIsShowMore={setIsShowMore}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Blog;
