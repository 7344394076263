import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import DeleteActionIcon from '../../../../general/assets/svg/DeleteActionIcon';
import EditIconAction from '../../../../general/assets/svg/EditIconAction';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import useKey, { keys } from '../../../../general/hooks/useKey';
import usePagination from '../../../../general/hooks/usePagination';
import { useResize } from '../../../../general/hooks/useResize';
import { queryKeys } from '../../../../general/queryKeys';
import { setStoreToEdit } from '../../../../general/redux/reducers/Stores-Reducer';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createShippingService from '../../../../general/services/shipping';
import { PaggingObj, ProductsStoreAddress } from '../../../../general/types/types';
import Confirm from '../../../components/confirm/Confirm';
import CustomPagination from '../../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../../components/tables/custom-table/CustomTable';
import StoreEditor from './StoreEditor';

interface ActionButtonsProps {
  item: ProductsStoreAddress;
  deleteMethod?: (id: string) => void;
  editMethod?: (id: string) => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ item, deleteMethod, editMethod }) => {
  const [deleteAffiliateAsk, setDeleteAffiliateAsk] = useState(false);

  useKey((event) => {
    if (event.key === keys.ESCAPE) {
      deleteAffiliateAsk && setDeleteAffiliateAsk(false);
    }
  });

  return (
    <>
      <button
        onClick={() => editMethod && editMethod(item.id)}
        className="table-block__tbody__change-item__button"
      >
        <EditIconAction />
      </button>
      <button
        onClick={() => setDeleteAffiliateAsk(true)}
        className="table-block__tbody__change-item__button"
      >
        <DeleteActionIcon />
      </button>
      {deleteAffiliateAsk && (
        <Confirm
          title="Delete confirm"
          text={'Are you shure want delete this store?'}
          handler={setDeleteAffiliateAsk}
          clickEvent={() => {
            if (deleteMethod) {
              deleteMethod(item.id);
            }
          }}
        />
      )}
    </>
  );
};

const Stores: React.FC = () => {
  const dispatch = useDispatch();
  const size = useResize();
  const headers = ['address', 'country', 'status'];
  const { page, pageSize, searchQuery, setPage, setPageSize, setSearchQuery } = usePagination(
    5,
    'product_stores_locations',
  );
  const [isModal, setIsModal] = useState<boolean>(false);

  const {
    data: addresses,
    isLoading: addressesLoading,
    refetch: getStores,
  } = useCustomQuery<PaggingObj<ProductsStoreAddress>>(queryKeys.AVAILABLE_STORES_LOCATIONS, () =>
    createShippingService(adminInstance).getProductsStores(pageSize, page, searchQuery),
  );

  useEffect(() => {
    getStores();
  }, [page, pageSize, searchQuery, getStores]);

  const changeQuerry = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const { mutate: removeStore } = useCustomMutation(
    createShippingService(adminInstance).deleteProductsStore,
    undefined,
    {
      onSuccess: () => {
        getStores();
      },
    },
  );

  const handleEdit = (id: string) => {
    const store = addresses?.items.find((el) => el.id === id);
    if (store) {
      dispatch(setStoreToEdit(store));
      setIsModal(true);
    }
  };

  const handleRemove = (id: string) => {
    removeStore(id);
  };

  const openEditorToAddNew = () => {
    setIsModal(true);
    dispatch(setStoreToEdit(null));
  };

  const actionButtons = (
    item: ProductsStoreAddress,
    deleteMethod?: (id: any) => void,
    editMethod?: (id: any) => void,
  ) => <ActionButtons item={item} deleteMethod={deleteMethod} editMethod={editMethod} />;

  return (
    <>
      {isModal && <StoreEditor isOpen={setIsModal} refresh={getStores} />}
      {addressesLoading ? (
        <Preloader />
      ) : (
        <>
          <div className="top-section">
            <span className="block-header">Available products stores</span>
            <input
              style={{ flex: '1' }}
              type="text"
              placeholder="Search store by address, country or type (active, inactive)"
              className="input_symbols_panel"
              value={searchQuery}
              onChange={changeQuerry}
            />
          </div>
          <CustomTable
            data={addresses ? addresses?.items : []}
            nameOfAction={'actions'}
            headers={headers}
            refreshMethod={handleEdit}
            deleteMethod={handleRemove}
            isAction={true}
            isMobile={size.width < 850}
            actionComponent={actionButtons}
          />
          <button className="section-btn-gradient add-new" onClick={openEditorToAddNew}>
            Add new store location
          </button>
          {addresses && addresses?.pageSize < addresses?.totalCount && (
            <CustomPagination
              currentPage={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              isNextPage={addresses.hasNextPage}
              isPrevPage={addresses.hasPreviousPage}
              total={addresses.totalCount}
            />
          )}
        </>
      )}
    </>
  );
};

export default Stores;
