import { AxiosInstance } from 'axios';

interface HelpCenterService {
  getAllQA: () => Promise<any>;
  getAllFAQ: () => Promise<any>;
}

const createHelpCenterServiceService = (instance: AxiosInstance): HelpCenterService => ({
  async getAllQA() {
    try {
      const response = await instance.get(`/QuestionAnswerGroup/all`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getAllFAQ() {
    try {
      const response = await instance.get(`/FaqGroup/all`);
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createHelpCenterServiceService;
