import React from 'react';

import { Theme, themes } from '../../contexts/ThemeContext';

interface CartIconProps {
  isUser: string;
  theme: Theme;
}

const CartIcon: React.FC<CartIconProps> = ({ isUser, theme }) => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      fill={isUser !== '' ? (theme === themes.light ? 'black' : '#25A8CF') : ''}
      stroke={
        isUser !== ''
          ? theme === themes.light
            ? 'black'
            : '#25A8CF'
          : theme === themes.light
            ? 'black'
            : 'white'
      }
      x="1.04541"
      y="1"
      width="39"
      height="39"
      rx="19.5"
    />
    <path
      stroke={
        isUser !== ''
          ? theme === themes.light
            ? 'white'
            : 'black'
          : theme === themes.light
            ? 'black'
            : 'white'
      }
      d="M11.9554 15.73H29.1354V28.14C29.1354 28.8985 28.8341 29.626 28.2977 30.1623C27.7614 30.6987 27.0339 31 26.2754 31H14.8154C14.0569 31 13.3294 30.6987 12.7931 30.1623C12.2567 29.626 11.9554 28.8985 11.9554 28.14V15.73Z"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      stroke={
        isUser !== ''
          ? theme === themes.light
            ? 'white'
            : 'black'
          : theme === themes.light
            ? 'black'
            : 'white'
      }
      d="M15.7754 18.59V14.77C15.7754 13.5049 16.278 12.2916 17.1725 11.3971C18.0671 10.5026 19.2803 10 20.5454 10C21.8105 10 23.0238 10.5026 23.9183 11.3971C24.8129 12.2916 25.3154 13.5049 25.3154 14.77V18.59"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default CartIcon;
