import './AboutSlider-slick-theme.scss';
import './AboutSlider-slick.scss';

import React from 'react';
import Slider from 'react-slick';

import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { useResize } from '../../../../general/hooks/useResize';
import { queryKeys } from '../../../../general/queryKeys';
import createAboutUsService from '../../../../general/services/aboutUs';
import { clientInstance } from '../../../../general/services/main/axiosInstances';

interface Image {
  url: string;
}

const AboutSlider: React.FC = () => {
  const { width } = useResize();

  const { data: images, isLoading: imagesLoading } = useCustomQuery<Image[]>(
    queryKeys.ABOUT_US_PICTURES,
    () => createAboutUsService(clientInstance).getAllSliderImages(),
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: width <= 768 ? 1 : width <= 1024 ? 2 : 3,
    slidesToScroll: width <= 768 ? 1 : width <= 1024 ? 2 : 3,
    arrows: true,
    adaptiveHeight: true,
    centerMode: false,
  };

  return (
    <>
      {imagesLoading ? (
        <div className="about-us-section__slider__preloader">
          <Preloader />
        </div>
      ) : (
        <Slider {...settings}>
          {images?.map((img, index) => <img key={index} src={img.url} alt="" />)}
        </Slider>
      )}
    </>
  );
};

export default AboutSlider;
