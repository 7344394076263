import React from 'react';
import { Helmet } from 'react-helmet';

interface CanonicalLinkProps {
  href: string; // URL канонической ссылки, который передается в компонент
}

const CanonicalLink: React.FC<CanonicalLinkProps> = ({ href }) => {
  return (
    <Helmet>
      <link rel="canonical" href={href} />
    </Helmet>
  );
};

export default CanonicalLink;
