import './AdminOrder.scss';

import React, { useEffect, useRef, useState } from 'react';

import DropDownIcon from '../../../../general/assets/svg/DropDownIcon';

const options = ['New', 'Awaiting Payment', 'Processing', 'Shipped', 'Delivered', 'Cancelled'];

interface OrderStatusProps {
  currentStatus: string;
}

const OrderStatus: React.FC<OrderStatusProps> = ({ currentStatus }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(currentStatus);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Handle option selection
  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  // Close dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="order-status" ref={dropdownRef}>
      <div className="order-status-select" onClick={() => setIsOpen(!isOpen)}>
        <div className="order-status__current-options">
          <div className="order-status__current-options__name">{selectedOption}</div>
          <DropDownIcon />
        </div>
      </div>
      {isOpen && (
        <div className="order-status__list-options">
          <ul>
            {options.map((option, index) => (
              <li
                key={index}
                className="order-status__list-options__option"
                onClick={() => handleOptionClick(option)}
                style={{
                  backgroundColor: selectedOption === option ? '#0F1928' : '',
                }}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default OrderStatus;
