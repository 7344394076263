import { FileData } from '@draft-js-plugins/drag-n-drop-upload/lib/handleDroppedFiles';

interface FileToUpload {
  src: string;
  name: string;
  type: string;
  retainSrc?: boolean; // Add any additional fields as needed
}

type SuccessFunction = (uploadedFiles: Array<FileToUpload>) => void;
type FailFunction = (file: FileToUpload) => void;
type ProgressFunction = (percent: number, file: FileToUpload) => void;

function readFile(file: File): Promise<FileToUpload> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () =>
      resolve({ src: reader.result as string, name: file.name, type: file.type });
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

function mockUpload(
  data: FileData,
  success: SuccessFunction,
  failed: FailFunction,
  progress: ProgressFunction,
): string {
  // First, convert each File to FileToUpload
  const fileToUploadPromises = data.files.map((file) => readFile(file));

  Promise.all(fileToUploadPromises)
    .then((filesToUpload) => {
      // Process each fileToUpload in the array
      filesToUpload.forEach((fileToUpload) => {
        doProgress(fileToUpload);
      });
    })
    .catch((error) => {
      // Handle the error if any file reading fails
      data.files.forEach((file) => failed({ src: '', name: file.name, type: file.type }));
    });

  function doProgress(file: FileToUpload, percent = 0): void {
    progress(percent, file); // Call progress with the correct arguments
    if (percent === 100) {
      success([file]); // Pass the uploaded file to the success function
    } else {
      setTimeout(() => doProgress(file, percent + 10), 250);
    }
  }

  return 'uploading';
}

export default mockUpload;
