import { AxiosInstance } from 'axios';

interface MessagesService {
  putMessageStatus: (body: { id: string; isProcessed: boolean }) => Promise<any>;
  deleteMessage: (id: string) => Promise<any>;
  deleteSimpleMessage: (id: string) => Promise<any>;
  putSimpleMessageStatus: (body: { id: string; isProcessed: boolean }) => Promise<any>;
  getMessageCount: () => Promise<any>;
  getSimpleMessageCount: () => Promise<any>;
  getMessage: (pageSize: number, page: number) => Promise<any>;
  getSimpleMessage: (pageSize: number, page: number) => Promise<any>;
}

const createMessagesService = (instance: AxiosInstance): MessagesService => ({
  async putMessageStatus(data: any) {
    try {
      const response = await instance.put(`/ContactMessage/status`, data);
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteMessage(id: string) {
    try {
      const response = await instance.delete(`/ContactMessage`, {
        data: { id: id },
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteSimpleMessage(id: string) {
    try {
      const response = await instance.delete(`/ContactMessage/simple`, {
        data: { id: id },
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async putSimpleMessageStatus(body: { id: string; isProcessed: boolean }) {
    try {
      const response = await instance.put(`/ContactMessage/simpleStatus`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getSimpleMessage(pageSize: number, page: number) {
    try {
      const response = await instance.get(
        `/ContactMessage/simple?PageSize=${pageSize}&Page=${page}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async getMessage(pageSize: number, page: number) {
    try {
      const response = await instance.get(`/ContactMessage?PageSize=${pageSize}&Page=${page}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getMessageCount() {
    try {
      const response = await instance.get(
        `/ContactMessage/messagesWithStatusCount?IsProcessed=false`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async getSimpleMessageCount() {
    try {
      const response = await instance.get(
        `/ContactMessage/simpleMessagesWithStatusCount?IsProcessed=false`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createMessagesService;
