import React from 'react';

interface RoundArrow45Props {
  stroke: string;
}

const RoundArrow45: React.FC<RoundArrow45Props> = ({ stroke }) => (
  <svg
    width="117"
    height="117"
    viewBox="0 0 117 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.144531" y="0.325195" width="116.065" height="116.065" rx="58.0325" fill="#08080A" />
    <path
      d="M41.5078 75.0247L74.8411 41.6914"
      stroke={stroke}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M41.5078 52.1079L41.5078 75.0246L64.4245 75.0246"
      stroke={stroke}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

export default RoundArrow45;
