import React from 'react';

const LocationMarkerIcon: React.FC<{ fill: string }> = ({ fill }) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.64 9.64C19.64 16.32 12 23 12 23C12 23 4.35999 16.32 4.35999 9.64C4.35999 7.61375 5.16491 5.67048 6.59769 4.2377C8.03047 2.80493 9.97373 2 12 2C14.0262 2 15.9695 2.80493 17.4023 4.2377C18.8351 5.67048 19.64 7.61375 19.64 9.64Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M12 12.4998C13.5795 12.4998 14.86 11.2193 14.86 9.63979C14.86 8.06025 13.5795 6.77979 12 6.77979C10.4205 6.77979 9.14001 8.06025 9.14001 9.63979C9.14001 11.2193 10.4205 12.4998 12 12.4998Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default LocationMarkerIcon;
