import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';

const AdminProducts: React.FC = () => {
  return (
    <div className="page-section">
      <div className="page-section__top-tabs">
        <NavLink
          end
          to="/admin/products"
          className={({ isActive }) =>
            'page-section__top__tab' + (isActive ? ' page-active-tab' : '')
          }
        >
          Product List
        </NavLink>
        <NavLink
          to="categories"
          className={({ isActive }) =>
            'page-section__top__tab' + (isActive ? ' page-active-tab' : '')
          }
        >
          Categories
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default AdminProducts;
