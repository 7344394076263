import React, { MouseEvent } from 'react';
import './CustomPagination.scss';

interface CustomPaginationProps {
  currentPage: number;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  isNextPage: boolean;
  isPrevPage: boolean;
  total: number;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  currentPage,
  pageSize,
  setPage,
  setPageSize,
  isNextPage,
  isPrevPage,
  total,
}) => {
  const pageNumbers: (number | string)[] = [];
  const totalSize = Math.ceil(total / pageSize);

  for (let i = 1; i <= totalSize; i++) {
    if (i === 1 || i === totalSize || (i >= currentPage - 1 && i <= currentPage + 1)) {
      pageNumbers.push(i);
    } else if (i < currentPage - 2 && !pageNumbers.includes('...left')) {
      pageNumbers.push('...left');
    } else if (i > currentPage + 2 && !pageNumbers.includes('...right')) {
      pageNumbers.push('...right');
    }
  }

  const setCurrentPage = (e: MouseEvent<HTMLButtonElement>) => {
    const newPage = parseInt(e.currentTarget.dataset.page || '1', 10);
    setPage(newPage);
  };

  return (
    <div className="page-section__pagination-page-items">
      <div className="page-section__pagination">
        {pageNumbers.map((number, index) => (
          <div key={index} className="page-section__pagination__btn">
            {number === '...left' || number === '...right' ? (
              <span className="page-section__pagination__dote">...</span>
            ) : (
              <button
                data-page={`${number}`}
                onClick={setCurrentPage}
                className={currentPage === number ? 'is-active-page' : ''}
              >
                {number}
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomPagination;
