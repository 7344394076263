import React from 'react';

const ClientsIcon: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8134 16.0399C15.9798 16.0399 18.5467 13.473 18.5467 10.3066C18.5467 7.14014 15.9798 4.57324 12.8134 4.57324C9.64698 4.57324 7.08008 7.14014 7.08008 10.3066C7.08008 13.473 9.64698 16.0399 12.8134 16.0399Z"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M2 28.76L2.92 24.1467C3.37313 21.8544 4.60652 19.79 6.41028 18.3047C8.21405 16.8194 10.4767 16.005 12.8133 16C15.1444 16.0091 17.4003 16.8259 19.1968 18.3113C20.9934 19.7967 22.2196 21.8588 22.6667 24.1467L23.6 28.76"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M16 15.0666C16.8629 15.6459 17.867 15.9798 18.905 16.0325C19.943 16.0853 20.9758 15.8548 21.8929 15.3659C22.8101 14.877 23.5771 14.148 24.1119 13.2568C24.6467 12.3657 24.9292 11.3459 24.9292 10.3066C24.9292 9.26726 24.6467 8.24748 24.1119 7.35632C23.5771 6.46516 22.8101 5.73614 21.8929 5.24723C20.9758 4.75831 19.943 4.5279 18.905 4.58063C17.867 4.63335 16.8629 4.96724 16 5.54658"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M30 28.7599L29.0667 24.1332C28.7883 22.7312 28.2146 21.4045 27.3836 20.2414C26.5526 19.0783 25.4835 18.1056 24.2473 17.3879C23.0112 16.6701 21.6363 16.2239 20.2143 16.0788C18.7922 15.9338 17.3556 16.0932 16 16.5466"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default ClientsIcon;
