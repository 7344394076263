import React from 'react';

const LocationProdStoresIcon: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.3327 13.4798C27.3327 22.2932 15.9993 29.8532 15.9993 29.8532C15.9993 29.8532 4.66602 22.2932 4.66602 13.4798C4.66602 10.474 5.86006 7.59135 7.98547 5.46594C10.1109 3.34053 12.9936 2.14648 15.9993 2.14648C19.0051 2.14648 21.8878 3.34053 24.0132 5.46594C26.1386 7.59135 27.3327 10.474 27.3327 13.4798Z"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <circle cx="16.0009" cy="13.4804" r="5.04" stroke="#25A8CF" strokeWidth="1.5" />
  </svg>
);

export default LocationProdStoresIcon;
