import React from 'react';

const MarkerDoneIcon: React.FC = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_906_328)">
      <path
        d="M7 13.125C10.3827 13.125 13.125 10.3827 13.125 7C13.125 3.61726 10.3827 0.875 7 0.875C3.61726 0.875 0.875 3.61726 0.875 7C0.875 10.3827 3.61726 13.125 7 13.125Z"
        stroke="#25A8CF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M3.65771 6.99982L5.88605 9.22815L10.3427 4.77148"
        stroke="#25A8CF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_906_328">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MarkerDoneIcon;
