import React from 'react';

const ContactUsIcon: React.FC = () => (
  <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_135_1451)">
      <path
        d="M14.5582 8.20755C15.2776 8.21023 15.9668 8.49719 16.4755 9.00588C16.9842 9.51457 17.2711 10.2037 17.2738 10.9231"
        stroke="white"
        strokeWidth="1.83072"
        strokeMiterlimit="10"
      />
      <path
        d="M14.5582 4.95255C16.1416 4.95255 17.6602 5.58155 18.7798 6.70117C19.8994 7.8208 20.5284 9.33934 20.5284 10.9227"
        stroke="white"
        strokeWidth="1.83072"
        strokeMiterlimit="10"
      />
      <path
        d="M14.5582 1.69844C17.0048 1.69844 19.3512 2.67034 21.0812 4.40032C22.8111 6.13031 23.783 8.47668 23.783 10.9233"
        stroke="white"
        strokeWidth="1.83072"
        strokeMiterlimit="10"
      />
      <path
        d="M19.969 21.9173C17.4975 24.3786 11.8325 22.7208 7.29633 18.1846C2.7602 13.6485 1.10238 7.98344 3.56369 5.51197L6.26909 2.80656L11.4867 8.02413L9.53388 9.99724C9.14136 10.3918 8.92101 10.9256 8.92101 11.4822C8.92101 12.0387 9.14136 12.5726 9.53388 12.9671L12.5139 15.9471C12.9084 16.3396 13.4423 16.56 13.9988 16.56C14.5553 16.56 15.0892 16.3396 15.4837 15.9471L17.4568 13.9943L22.6744 19.2119L19.969 21.9173Z"
        stroke="white"
        strokeWidth="1.83072"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_135_1451">
        <rect
          width="24.4096"
          height="24.4096"
          fill="white"
          transform="translate(0.898926 0.173111)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ContactUsIcon;
