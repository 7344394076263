import React from 'react';

const EditIconAction: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_622_32)">
      <path
        d="M7.22995 20.59L2.44995 21.59L3.44995 16.81L17.89 2.29001C18.1405 2.03889 18.4382 1.83982 18.766 1.70424C19.0938 1.56865 19.4452 1.49925 19.7999 1.50001C20.516 1.50001 21.2028 1.78447 21.7091 2.29082C22.2155 2.79717 22.5 3.48392 22.5 4.20001C22.5007 4.55474 22.4313 4.90611 22.2957 5.23391C22.1601 5.56171 21.9611 5.85945 21.7099 6.11001L7.22995 20.59Z"
        stroke="#25A8CF"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M0.550049 22.5H23.4501" stroke="#25A8CF" strokeWidth="2" strokeMiterlimit="10" />
      <path
        d="M19.6399 8.18011L15.8199 4.36011"
        stroke="#25A8CF"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_622_32">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EditIconAction;
