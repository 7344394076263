import './ImagesSlider.scss';

import React, { useState } from 'react';

import NextIcon from '../../../general/assets/svg/NextIcon';
import PrevIcon from '../../../general/assets/svg/PrevIcon';

interface Image {
  id: string;
  imageUrl: string;
}

// interface FileImage {
//   file: File;
// }

export type SliderImage = Image;

interface ImageSliderProps {
  images: SliderImage[];
  removeCallback?: (imgId: string) => void;
}

const ImagesSlider: React.FC<ImageSliderProps> = ({ images, removeCallback }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const getImageUrl = (image: SliderImage) => {
    return image.imageUrl;
  };

  return (
    <div className="image-slider">
      <div className="image-container">
        <button
          style={{ marginRight: '10px', display: images.length <= 1 ? 'none' : '' }}
          onClick={goToPrevious}
          className="slider-button"
        >
          <PrevIcon />
        </button>
        <div
          className="slider-image"
          onClick={() => {
            if (removeCallback) {
              if (!images[currentIndex]) {
                goToNext();
              } else {
                removeCallback((images[currentIndex] as Image).id);
              }
            }
          }}
          style={{
            backgroundImage: images[currentIndex] && `url(${getImageUrl(images[currentIndex])})`,
          }}
        ></div>
        <button
          style={{ marginLeft: '10px', display: images.length <= 1 ? 'none' : '' }}
          onClick={goToNext}
          className="slider-button"
        >
          <NextIcon />
        </button>
      </div>
    </div>
  );
};

export default ImagesSlider;
