import React from 'react';

const CartProdIcon: React.FC = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_813_74)">
      <path
        d="M10.0682 23.0894C11.1231 23.0894 11.9782 22.2342 11.9782 21.1794C11.9782 20.1245 11.1231 19.2694 10.0682 19.2694C9.01334 19.2694 8.1582 20.1245 8.1582 21.1794C8.1582 22.2342 9.01334 23.0894 10.0682 23.0894Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M18.659 23.0894C19.7139 23.0894 20.569 22.2342 20.569 21.1794C20.569 20.1245 19.7139 19.2694 18.659 19.2694C17.6042 19.2694 16.749 20.1245 16.749 21.1794C16.749 22.2342 17.6042 23.0894 18.659 23.0894Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M0.518555 2.08936H3.17855C3.78853 2.08829 4.38298 2.28159 4.87567 2.64121C5.36836 3.00084 5.73365 3.50808 5.91855 4.08936L9.10855 14.4994H8.63856C8.00469 14.4994 7.39678 14.7512 6.94857 15.1994C6.50036 15.6476 6.24855 16.2555 6.24855 16.8894C6.2512 17.5215 6.50417 18.1268 6.9521 18.5729C7.40002 19.0189 8.00642 19.2694 8.63856 19.2694H18.6386"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M7.20898 5.90936H22.479V7.81936L20.569 14.4994H9.10898"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_813_74">
        <rect width="24" height="24" fill="white" transform="translate(-0.000976562 0.589355)" />
      </clipPath>
    </defs>
  </svg>
);

export default CartProdIcon;
