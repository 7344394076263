import React from 'react';

const ExportIcon: React.FC = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1598_126)">
      <path d="M10 4.94165V20.0833" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
      <path
        d="M16.375 11.3166L10 4.94165L3.625 11.3166"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M16.375 1.75H3.625" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_1598_126">
        <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default ExportIcon;
