import { useMemo } from 'react';
import { QuestionGroup } from '../../client/pages/help-center/faq-and-qa/FAQ';
import { routesClient } from '../routes';

const useRedirectToHelpCenter = (qaes?: QuestionGroup[], faqs?: QuestionGroup[]): string => {
  return useMemo(() => {
    if (qaes && faqs) {
      if (qaes.length > 0 && faqs.length === 0) {
        return `${routesClient.helpCenterQA.path}`;
      } else if (faqs.length > 0 && qaes.length === 0) {
        return `${routesClient.helpCenterFAQ.path}`;
      }
    }
    return `${routesClient.helpCenterQA.path}`;
  }, [qaes, faqs]);
};

export default useRedirectToHelpCenter;
