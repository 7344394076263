import React from 'react';

const ImportIcon: React.FC = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1598_125)">
      <path d="M10 16.0583L10 0.916683" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
      <path
        d="M3.625 9.68335L10 16.0583L16.375 9.68335"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M3.625 19.25L16.375 19.25" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_1598_125">
        <rect width="20" height="20" fill="white" transform="translate(20 20.5) rotate(-180)" />
      </clipPath>
    </defs>
  </svg>
);

export default ImportIcon;
