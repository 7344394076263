import React from 'react';

const TableHeadeArrowTopIcon: React.FC<{ className: string }> = ({ className }) => (
  <svg
    className={className}
    width="7"
    height="6"
    viewBox="0 0 7 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.06699 0.550048C3.25944 0.216715 3.74056 0.216715 3.93301 0.550049L6.53109 5.05005C6.72354 5.38338 6.48298 5.80005 6.09808 5.80005H0.901923C0.517023 5.80005 0.276461 5.38338 0.468911 5.05005L3.06699 0.550048Z"
      fill="#19151E"
    />
  </svg>
);

export default TableHeadeArrowTopIcon;
