import React from 'react';

interface XLinkIconProps {
  width?: string | number;
  height?: string | number;
  viewBox?: string;
}

const FacebookLinkIcon: React.FC<XLinkIconProps> = ({ width, height, viewBox }) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8201 4.18V1H11.1534C9.80833 1.0088 8.52137 1.5493 7.57339 2.50354C6.6254 3.45777 6.09336 4.74825 6.09339 6.09333V6.72667H4.18005V9.27333H6.09339V15H9.27339V9.27333H11.1801V6.72667H9.27339V6.09333C9.27251 5.84239 9.32118 5.59373 9.41661 5.36164C9.51203 5.12954 9.65234 4.91856 9.82947 4.7408C10.0066 4.56305 10.2171 4.42201 10.4489 4.32577C10.6806 4.22954 10.9291 4.18 11.1801 4.18H11.8201Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default FacebookLinkIcon;
