import React from 'react';

const IndividualConditionIcon: React.FC = () => (
  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.8464 11.5658C26.9561 11.5658 28.6664 9.85551 28.6664 7.74578C28.6664 5.63605 26.9561 3.92578 24.8464 3.92578C22.7366 3.92578 21.0264 5.63605 21.0264 7.74578C21.0264 9.85551 22.7366 11.5658 24.8464 11.5658Z"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M19.127 17.2857C19.127 15.7686 19.7296 14.3137 20.8023 13.241C21.875 12.1683 23.3299 11.5657 24.847 11.5657C26.364 11.5657 27.8189 12.1683 28.8916 13.241C29.9643 14.3137 30.567 15.7686 30.567 17.2857"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M40.1266 34.4657C42.2364 34.4657 43.9466 32.7554 43.9466 30.6457C43.9466 28.536 42.2364 26.8257 40.1266 26.8257C38.0169 26.8257 36.3066 28.536 36.3066 30.6457C36.3066 32.7554 38.0169 34.4657 40.1266 34.4657Z"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M34.3868 40.2059C34.3841 39.4513 34.5308 38.7037 34.8183 38.0061C35.1058 37.3085 35.5285 36.6747 36.0621 36.1412C36.5956 35.6076 37.2294 35.1849 37.927 34.8974C38.6246 34.6099 39.3722 34.4632 40.1268 34.4659C40.8796 34.4659 41.625 34.6145 42.3203 34.9032C43.0156 35.1919 43.6471 35.615 44.1785 36.1483C44.7099 36.6815 45.1308 37.3145 45.4171 38.0108C45.7034 38.7071 45.8494 39.453 45.8468 40.2059"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M9.56707 34.4657C11.6768 34.4657 13.3871 32.7554 13.3871 30.6457C13.3871 28.536 11.6768 26.8257 9.56707 26.8257C7.45734 26.8257 5.74707 28.536 5.74707 30.6457C5.74707 32.7554 7.45734 34.4657 9.56707 34.4657Z"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M3.84671 40.2059C3.84408 39.453 3.99009 38.7071 4.27637 38.0108C4.56265 37.3145 4.98356 36.6815 5.51498 36.1483C6.04639 35.615 6.67785 35.1919 7.37314 34.9032C8.06843 34.6145 8.81387 34.4659 9.56672 34.4659C10.3212 34.4632 11.0688 34.6099 11.7664 34.8974C12.464 35.1849 13.0979 35.6076 13.6314 36.1412C14.1649 36.6747 14.5876 37.3085 14.8752 38.0061C15.1627 38.7037 15.3094 39.4513 15.3067 40.2059"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M5.78711 23.6658C5.99738 20.4751 7.00703 17.3885 8.7231 14.6903C10.4392 11.9922 12.8065 9.76909 15.6071 8.22583"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M34.0869 8.22583C36.8875 9.76909 39.2549 11.9922 40.9709 14.6903C42.687 17.3885 43.6966 20.4751 43.9069 23.6658"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M17.627 44.5059C22.254 46.3997 27.4399 46.3997 32.067 44.5059"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default IndividualConditionIcon;
