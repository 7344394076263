import './AdminTestimonials.scss';

import React, { MouseEvent, useState } from 'react';

import AddIcon from '../../../../general/assets/svg/AddIcon';
import EditIcon from '../../../../general/assets/svg/EditIconAction';
import LocationMarkerIcon from '../../../../general/assets/svg/LocationMarkerIcon';
import StarIcon from '../../../../general/assets/svg/StarIcon';
import ThumbsUpIcon from '../../../../general/assets/svg/ThumbsUpIcon';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import createHomePageService from '../../../../general/services/homePage';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import { images } from '../../../../general/StaticData';
import { TestimonialFull } from '../../../../general/types/types';
import ModalTestimonial from './ModalTestimonial';

const AdminTestimonials: React.FC = () => {
  const [isModal, setIsModal] = useState<boolean>(false);
  const [editTestimonialId, setEditTestimonialId] = useState<string | null>(null);

  const openEditModal = (e: MouseEvent<HTMLButtonElement>) => {
    setIsModal(true);
    setEditTestimonialId(e.currentTarget.dataset.id || null);
  };

  const openModal = () => {
    setIsModal(true);
    setEditTestimonialId(null);
  };

  const { data: testimonials, isLoading: isLoadingTestimonials } = useCustomQuery<
    TestimonialFull[]
  >(queryKeys.TESTIMONIALS_FULL, () => createHomePageService(adminInstance).getAllTestimoneals());

  return (
    <>
      {isModal && <ModalTestimonial isOpen={setIsModal} id={editTestimonialId} />}
      <div className="home-container__header">
        <div className="home-container__header__icon-name">
          <ThumbsUpIcon />
          <span>Testimonials</span>
        </div>
        <button className="home-container__header__button" onClick={openModal}>
          <AddIcon color="#25A8CF" />
        </button>
      </div>
      <div className="home-container__testimonials">
        {isLoadingTestimonials ? (
          <div className="home-container__testimonials__preloader">
            <Preloader />
          </div>
        ) : (
          <>
            {!testimonials || testimonials?.length === 0 ? (
              <div className="home-container__testimonials__empty">No testimonials</div>
            ) : (
              <ul>
                {testimonials?.map((testimonial, index) => (
                  <li key={index}>
                    <div className="home-container__testimonial">
                      <div
                        className="selected-img"
                        style={{
                          backgroundImage: `url(${
                            testimonial?.imageUrl === null || testimonial?.imageUrl === undefined
                              ? images.defaultImg
                              : testimonial?.imageUrl + `?` + Math.random()
                          })`,
                          margin: '0 10px',
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          height: '90px',
                          width: '90px',
                        }}
                      />
                      <div className="testimonials-info-container">
                        <div className="home-container__testimonials__info">
                          <span>{testimonial?.username}</span>
                          <span className="home-container__testimonials__info__country">
                            <LocationMarkerIcon fill="white" />
                            {testimonial?.country}
                          </span>
                        </div>
                        <div className="home-container__testimonials__starts">
                          {[...Array(testimonial?.rating).keys()].map((star, index) => (
                            <React.Fragment key={index}>
                              <StarIcon />
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="home-container__testimonial__hover">
                      <button data-id={testimonial?.id} onClick={openEditModal}>
                        <EditIcon />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AdminTestimonials;
