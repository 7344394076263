import React from 'react';

const ProductsIcon: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.6398 14.7334H13.4531" stroke="#6E7EA8" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M23.6398 19.8132H13.4531" stroke="#6E7EA8" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M23.6398 24.9067H13.4531" stroke="#6E7EA8" strokeWidth="1.5" strokeMiterlimit="10" />
    <path
      d="M27.4535 8.36V30H4.54688V2H21.0935L27.4535 8.36Z"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M27.4535 8.36V9.64H19.8135V2H21.0935L27.4535 8.36Z"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M8.35986 14.7334H10.9065" stroke="#6E7EA8" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M8.35986 19.8132H10.9065" stroke="#6E7EA8" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M8.35986 24.9067H10.9065" stroke="#6E7EA8" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);

export default ProductsIcon;
