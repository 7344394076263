import React from 'react';

const LogsIcon: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 29H1.00019V7.36L7.36019 1H23.9069V17"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M0.999892 7.36V8.64H8.63989V1H7.35989L0.999892 7.36Z"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <g clipPath="url(#clip0_1598_238)">
      <path
        d="M17 29C20.866 29 24 25.866 24 22C24 18.134 20.866 15 17 15C13.134 15 10 18.134 10 22C10 25.866 13.134 29 17 29Z"
        stroke="#6E7EA8"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M17 22.6334C17.3498 22.6334 17.6334 22.3498 17.6334 22C17.6334 21.6503 17.3498 21.3667 17 21.3667C16.6503 21.3667 16.3667 21.6503 16.3667 22C16.3667 22.3498 16.6503 22.6334 17 22.6334Z"
        stroke="#6E7EA8"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M17 16.9067V22.0001L20.18 25.1801"
        stroke="#6E7EA8"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_1598_238">
        <rect width="16" height="16" fill="white" transform="translate(9 14)" />
      </clipPath>
    </defs>
  </svg>
);

export default LogsIcon;
