import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';

import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import { setInfo, setType, types } from '../../../../general/redux/reducers/Error-Reducer';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createShippingService from '../../../../general/services/shipping';
import { AvailableProdLocations } from '../../../../general/types/types';
import Confirm from '../../../components/confirm/Confirm';

const Locations = () => {
  const dispatch = useDispatch();
  const [availableLocationNew, setAvailableLocationNew] = useState<string>('');
  const [deleteArticleAsk, setDeleteArticleAsk] = useState(false);
  const [locationIdToDelete, setLocationIdToDelete] = useState<string>('');

  const { data: availableLocations, refetch: getLocations } = useCustomQuery<
    AvailableProdLocations[]
  >(queryKeys.AVAILABLE_PROD_LOCATIONS, () =>
    createShippingService(adminInstance).getAvailableProdLocations(),
  );

  const { mutate: deleteLocation } = useCustomMutation(
    createShippingService(adminInstance).removeAvailableProdLocation,
    undefined,
    {
      onSuccess: (data: any) => {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: data },
          }),
        );

        getLocations();
      },
    },
  );

  const { mutate: saveNewLocation } = useCustomMutation(
    createShippingService(adminInstance).saveNewProdLocation,
    undefined,
    {
      onSuccess: (data: any) => {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: `New location - ${data.country}, was sucessfully added` },
          }),
        );

        getLocations();
      },
    },
  );

  const handleNewAvailableLoaction = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAvailableLocationNew(value);
  };

  const handleSaveNewLocation = async () => {
    if (availableLocationNew.trim() === '') {
      dispatch(setType(types.WARNING));
      dispatch(
        setInfo({
          n: types.WARNING,
          r: { s: 'Must contains latters' },
        }),
      );
    } else {
      saveNewLocation(availableLocationNew);
    }

    setAvailableLocationNew('');
  };

  const handleDeleteArticleClick = (index: string) => {
    setDeleteArticleAsk(true);
    setLocationIdToDelete(index);
  };

  return (
    <>
      <div className="top-section">
        <span className="block-header">Available prod locations</span>
        <div className="input-block">
          <input
            type="text"
            placeholder="New available location"
            value={availableLocationNew}
            onChange={handleNewAvailableLoaction}
          />
          {availableLocationNew && (
            <button className="section-btn-gradient" onClick={handleSaveNewLocation}>
              Add new location
            </button>
          )}
        </div>
      </div>
      <div className="locations">
        {availableLocations && availableLocations?.length === 0 ? (
          <p className="empty"> No locations</p>
        ) : (
          <>
            {availableLocations &&
              availableLocations?.map((location, index) => (
                <div
                  key={index}
                  className="available-location"
                  onClick={() => handleDeleteArticleClick(location.id)}
                >
                  <span>{location.country}</span>
                </div>
              ))}
          </>
        )}
      </div>
      {deleteArticleAsk && (
        <Confirm
          title="Delete confirm"
          text={'Are you shure want delete this location?'}
          handler={setDeleteArticleAsk}
          clickEvent={() => {
            deleteLocation(locationIdToDelete);
          }}
        />
      )}
    </>
  );
};

export default Locations;
