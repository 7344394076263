import { AxiosInstance } from 'axios';

interface ProductsService {
  getCharacteristicsMainGeneralOfProduct: (productId: string) => Promise<any>;
  getProductByURL: (url: string) => Promise<any>;
  getAllProducts: (search: string, pageSize: number, page: number) => Promise<any>;
  getProductsCategories: () => Promise<any>;
  getFiltersMainMining: (categoryId: string) => Promise<any>;
  getFiltersMainGeneralOfCategory: (categoryId: string) => Promise<any>;
  getProducts: (
    categoryId: string,
    isRetail: boolean,
    pageSize: number,
    page: number,
    queryStr: any,
    price: any,
    sort: any,
  ) => Promise<any>;
  getMainGeneralOfProduct: (id: string) => Promise<any>;
  getMainGeneralNames: (id: string) => Promise<any>;
  getEditProduct: (id: string) => Promise<any>;
  getXLSXProducts: () => Promise<any>;
  getXLSXProductsPrice: () => Promise<any>;
  postXLSXProducts: (data: FormData) => Promise<any>;
  putXLSXProductsPrice: (data: FormData) => Promise<any>;
  deleteProduct: (id: string) => Promise<any>;
  postMainGeneralToCategory: (body: { categoryId: string; name: string }) => Promise<any>;
  postProductCategory: (data: {
    image: FormData;
    category: string;
    minig: boolean;
  }) => Promise<any>;
  deleteMainGeneralCategoryCharacteristic: (id: string) => Promise<any>;
  putProductCategory: (body: {
    categoryId: string;
    description: string;
    category: string;
  }) => Promise<any>;
  putProductCategoryImage: (data: { categoryId: string; image: FormData }) => Promise<any>;
  deleteProductCategory: (categoryId: string) => Promise<any>;
  deleteProductImg: (id: string) => Promise<any>;
  updateImagesOredr: (body: {
    productId: string;
    images: {
      id: any;
      order: any;
    }[];
  }) => Promise<any>;
  postImagesProduct: (data: { id: string; data: FormData }) => Promise<any>;
  putProductCharacteristics: (body: {
    id: string;
    productId: string;
    name: string;
    value: string;
    order: string;
  }) => Promise<any>;
  postAdditionalCharacteristic: (body: {
    productId: string;
    name: string;
    value: string;
    order: string;
  }) => Promise<any>;
  deleteProductCharacteristics: (id: string) => Promise<any>;
  putProductMainGeneralOfProduct: (body: {
    generalMainCharacteristicId: string;
    value: string;
  }) => Promise<any>;
  putProductMarket: (body: {
    productId: string;
    isRetail: boolean;
    isWholesale: boolean;
  }) => Promise<any>;
  putProductMainInformation: (body: {
    id: string;
    batch: Date;
    name: string;
    description: string;
    price: string;
    minimalOrderQuantity: string;
    rating: string;
    amount: string;
    isActive: boolean;
    percentageDiscount: string;
  }) => Promise<any>;
  putProductMainMiningCharacteristics: (body: {
    productId: string;
    manufacturer: string;
    condition: string;
    algorithm: string;
    hashrate: string;
    energyConsumption: string;
    energyEfficiency: string;
    countryLocation: string;
    stores: any;
  }) => Promise<any>;
  postProduct: (body: any) => Promise<any>;
  postCopyProducts: (id: string) => Promise<any>;
}

const createProductsService = (instance: AxiosInstance): ProductsService => ({
  async postCopyProducts(id: string) {
    try {
      const response = await instance.post(`/Product/copyProduct`, {
        productId: id,
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async postProduct(body: any) {
    try {
      const response = await instance.post(`/Product`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async putProductMainMiningCharacteristics(body: {
    productId: string;
    manufacturer: string;
    condition: string;
    algorithm: string;
    hashrate: string;
    energyConsumption: string;
    energyEfficiency: string;
    countryLocation: string;
    stores: any;
  }) {
    try {
      const response = await instance.put(`/Characteristics/mainMining`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async putProductMainInformation(body: {
    id: string;
    batch: Date;
    name: string;
    description: string;
    price: string;
    minimalOrderQuantity: string;
    rating: string;
    amount: string;
    isActive: boolean;
    percentageDiscount: string;
  }) {
    try {
      const response = await instance.put(`/Product`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async putProductMarket(body: { productId: string; isRetail: boolean; isWholesale: boolean }) {
    try {
      const response = await instance.put(`/Product/market`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async putProductMainGeneralOfProduct(body: {
    generalMainCharacteristicId: string;
    value: string;
  }) {
    try {
      const response = await instance.put(`/Characteristics/updateMainGeneralOfProduct`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteProductCharacteristics(id: string) {
    try {
      const response = await instance.delete(`/Characteristics/additional`, {
        data: { id: id },
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async postAdditionalCharacteristic(body: {
    productId: string;
    name: string;
    value: string;
    order: string;
  }) {
    try {
      const response = await instance.post(`/Characteristics/additional`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async putProductCharacteristics(body: {
    id: string;
    productId: string;
    name: string;
    value: string;
    order: string;
  }) {
    try {
      const response = await instance.put(`/Characteristics/additional`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async postImagesProduct(data: { id: string; data: FormData }) {
    try {
      const response = await instance.post(`/Product/addImages?ProductId=${data.id}`, data.data);
      return response;
    } catch (error) {
      return error;
    }
  },

  async updateImagesOredr(body: {
    productId: string;
    images: {
      id: any;
      order: any;
    }[];
  }) {
    try {
      const response = await instance.put(`/Product/updateImagesOrder`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteProductImg(id: string) {
    try {
      const response = await instance.delete(`/Product/removeImage`, {
        data: { imageId: id },
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteProductCategory(categoryId: string) {
    try {
      const response = await instance.delete(`/Category`, {
        data: { id: categoryId },
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async putProductCategoryImage(data: { categoryId: string; image: FormData }) {
    try {
      const response = await instance.post(
        `/Category/updateImage?CategoryId=${data.categoryId}`,
        data.image,
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async putProductCategory(body: { categoryId: string; description: string; category: string }) {
    try {
      const response = await instance.put(`/Category`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteMainGeneralCategoryCharacteristic(id: string) {
    try {
      const response = await instance.delete(`/Characteristics/mainGeneralInCategory`, {
        data: { generalMainCharacteristicDefinitionId: id },
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async postProductCategory(data: { image: FormData; category: string; minig: boolean }) {
    try {
      const response = await instance.post(
        `/Category?Description=${'1'}&Name=${data.category}&AreMiningCharacteristics=${data.minig}`,
        data.image,
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async postMainGeneralToCategory(body: { categoryId: string; name: string }) {
    try {
      const response = await instance.post(`/Characteristics/mainGeneralToCategory`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteProduct(id: string) {
    try {
      const response = await instance.delete(`/Product`, {
        data: { id: id },
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async putXLSXProductsPrice(data: FormData) {
    const headers: any = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await instance.put(`/Product/xlsxPrices`, data, headers);
      return response;
    } catch (error) {
      return error;
    }
  },

  async postXLSXProducts(data: FormData) {
    const headers: any = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await instance.post(`/Product/xlsxProducts`, data, headers);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getXLSXProductsPrice() {
    const headers: any = {
      'Content-Type': 'blob',
      responseType: 'arraybuffer',
    };

    try {
      const response = await instance.get(`/Product/xlsxPrices`, headers);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getXLSXProducts() {
    const headers: any = {
      'Content-Type': 'blob',
      responseType: 'arraybuffer',
    };

    try {
      const response = await instance.get(`/Product/xlsxProducts`, headers);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getEditProduct(id: string) {
    try {
      const response = await instance.get(`Product/byIdAdmin?ProductId=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getMainGeneralNames(id: string) {
    try {
      const response = await instance.get(`/Characteristics/mainGeneralNames?CategoryId=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getMainGeneralOfProduct(id: string) {
    try {
      const response = await instance.get(`/Characteristics/mainGeneralOfProduct?ProductId=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getCharacteristicsMainGeneralOfProduct(productId) {
    try {
      const response = await instance.get(
        `/Characteristics/mainGeneralOfProduct?ProductId=${productId}`,
      );

      return response;
    } catch (error) {
      return error;
    }
  },

  async getProductByURL(url) {
    try {
      const response = await instance.get(`/Product/byUrl?Url=${url}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getProducts(categoryId, isRetail, pageSize, page, queryStr, price, sort) {
    try {
      if (!Array.isArray(queryStr)) {
        throw new Error('queryStr должен быть массивом');
      }

      const params = new URLSearchParams();

      if (categoryId) params.append('CategoryId', categoryId);
      if (typeof isRetail === 'boolean') params.append('IsRetail', isRetail.toString());
      if (pageSize) params.append('PageSize', pageSize.toString());
      if (page) params.append('Page', page.toString());
      if (queryStr.length > 0) params.append('QueryString', encodeURIComponent(queryStr.join('&')));
      if (price && price.hasOwnProperty('PriceFrom'))
        params.append('PriceFrom', price.PriceFrom.toString());
      if (price && price.hasOwnProperty('PriceTo'))
        params.append('PriceTo', price.PriceTo.toString());
      if (sort && sort.hasOwnProperty('SortColumn')) params.append('SortColumn', sort.SortColumn);
      if (sort && sort.hasOwnProperty('SortOrder')) params.append('SortOrder', sort.SortOrder);

      const queryString = params.toString();

      const response = await instance.get(`/Product/byQuery?${queryString}`);

      return response;
    } catch (error) {
      throw error;
    }
  },

  async getProductsCategories() {
    try {
      const response = await instance.get(`/Category`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getAllProducts(search: string, pageSize: number, page: number) {
    try {
      const response = await instance.get(
        `/Product/all?Name=${encodeURIComponent(search)}&PageSize=${pageSize}&Page=${page}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async getFiltersMainMining(categoryId) {
    try {
      const response = await instance.get(`/Characteristics/mainMining?CategoryId=${categoryId}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getFiltersMainGeneralOfCategory(categoryId) {
    try {
      const response = await instance.get(
        `/Characteristics/mainGeneralOfCategory?CategoryId=${categoryId}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createProductsService;
