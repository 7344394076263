import React from 'react';

const QaFaqTitleDecorationIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1755_3315)">
      <path
        d="M18.334 1.66671L1.66732 18.3334"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M18.334 13.125L18.334 1.66667L6.87565 1.66667"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_1755_3315">
        <rect width="20" height="20" fill="white" transform="translate(20 20) rotate(-180)" />
      </clipPath>
    </defs>
  </svg>
);

export default QaFaqTitleDecorationIcon;
