import React from 'react';

interface ToolTripProps {
  className?: string;
  selectedCountry: string;
  selectedProvince: string;
  style?: React.CSSProperties;
}

const TaxToolTrip: React.FC<ToolTripProps> = ({
  className,
  selectedCountry,
  selectedProvince,
  style,
}) => (
  <span className={className} style={style}>
    <h2>Tax Information</h2>
    <p>
      <strong>Selected Country:</strong> {selectedCountry}
    </p>
    <p>
      <strong>Selected Province:</strong> {selectedProvince}
    </p>
    <>
      <p>
        <strong>Quebec:</strong> In Quebec, the applicable taxes are:
      </p>
      <ul>
        <li>
          <strong>TPS:</strong> Taxe sur les produits et services (Goods and Services Tax) at a rate
          of 5%
        </li>
        <li>
          <strong>TVQ:</strong> Taxe de vente du Québec (Quebec Sales Tax) at a rate of 9.975%
        </li>
      </ul>
      <p>
        <strong>Other Provinces:</strong> In other provinces, the applicable tax is:
      </p>
      <ul>
        <li>
          <strong>GST:</strong> Goods and Services Tax at a rate of 5%
        </li>
      </ul>
    </>
  </span>
);

export default TaxToolTrip;
