import '../StaticAgreements.scss';
import './CookiesPolice.scss';

import React from 'react';

import CookieIcon from '../../../../general/assets/svg/CookieIcon';

const CookiesPolicy: React.FC = () => {
  return (
    <div className="static-agreements-page page-container">
      <div className="static-agreements-page__title">Cookie Policy</div>
      <div className="static-agreements-page__content">
        <div className="static-agreements-page__accessible-from">
          <article>
            <CookieIcon />
          </article>
          This is the Cookie Policy for Asicxchange, accessible from https://asicxchange.com.
        </div>
        <div className="static-agreements-page__content__text-block">
          <h3 className="static-agreements-page__content__text-block__title">What Are Cookies?</h3>
          <span className="static-agreements-page__content__text-block__text">
            As is common practice with almost all professional websites, Asicxchange uses cookies,
            which are tiny files that are downloaded to your computer, to improve your experience.
            This page describes what information they gather, how we use it, and why we sometimes
            need to store these cookies. We will also share how you can prevent these cookies from
            being stored, however, this may downgrade or 'break' certain elements of the site's
            functionality.
          </span>
        </div>
        <div className="static-agreements-page__content__text-block">
          <h3 className="static-agreements-page__content__text-block__title">How We Use Cookies</h3>
          <span className="static-agreements-page__content__text-block__text">
            We use cookies for a variety of reasons detailed below. Unfortunately, in most cases,
            there are no industry standard options for disabling cookies without completely
            disabling the functionality and features they add to this site. It is recommended that
            you leave on all cookies if you are not sure whether you need them or not, in case they
            are used to provide a service that you use.
          </span>
        </div>
        <div className="static-agreements-page__content__text-block">
          <h3 className="static-agreements-page__content__text-block__title">
            Types of Cookies We Use
          </h3>
          <span className="static-agreements-page__content__text-block__text">
            The following section details which cookies you might encounter through this site:
          </span>
          <ul>
            <li>
              <span className="static-agreements-page__content__text-block__list-number">1</span>
              Session Cookies: Used to remember you as you navigate our website.
            </li>
            <li>
              <span className="static-agreements-page__content__text-block__list-number">2</span>
              Preference Cookies: Used to remember your preferences and various settings.
            </li>
            <li>
              <span className="static-agreements-page__content__text-block__list-number">3</span>
              Security Cookies: Used for security purposes.
            </li>
            <li>
              <span className="static-agreements-page__content__text-block__list-number">4</span>
              Analytics Cookies: These cookies help us understand how our visitors use the website.
            </li>
          </ul>
        </div>
        <div className="static-agreements-page__content__text-block">
          <h3 className="static-agreements-page__content__text-block__title">
            Managing Your Cookies
          </h3>
          <span className="static-agreements-page__content__text-block__text">
            You can prevent the setting of cookies by adjusting the settings on your browser (see
            your browser's 'Help' option on how to do this). Be aware that disabling cookies will
            affect the functionality of this and many other websites that you visit. Disabling
            cookies will usually result in also disabling certain functionality and features of this
            site. Therefore, it is recommended that you do not disable cookies.
          </span>
        </div>
        <div className="static-agreements-page__content__text-block">
          <h3 className="static-agreements-page__content__text-block__title">
            Third Party Cookies
          </h3>
          <span className="static-agreements-page__content__text-block__text">
            In some special cases, we also use cookies provided by trusted third parties. The
            following section details which third party cookies you might encounter through this
            site: - Google Analytics: This site uses Google Analytics to help us understand how you
            use the site and ways that we can improve your experience. These cookies may track
            things such as how long you spend on the site and the pages that you visit.
          </span>
        </div>
        <div className="static-agreements-page__content__text-block">
          <h3 className="static-agreements-page__content__text-block__title">
            Managing Your Cookies
          </h3>
          <span className="static-agreements-page__content__text-block__text">
            If you are looking for more information, you can contact us through our preferred
            contact method:
          </span>
          <p className="static-agreements-page__content__text-block__text">
            Email: contact@asicxchange.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default CookiesPolicy;
