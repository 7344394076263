import './AdminProductList.scss';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import CloseBig from '../../../../general/assets/svg/CloseBig';
import Preloader, { preloaderStyles } from '../../../../general/components/preloader/Preloader';
import { setInfo } from '../../../../general/redux/reducers/Error-Reducer';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createProductsService from '../../../../general/services/products';
import DragAndDrop from './DragAndDrop';

interface ImportProps {
  isActive: (active: boolean) => void;
  getData: () => void;
}

const Import: React.FC<ImportProps> = ({ isActive, getData }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [isPreloader, setIsPreloader] = useState<boolean>(false);

  const closeModal = () => {
    isActive(false);
  };

  const importXLSX = async () => {
    setIsPreloader(true);
    if (file) {
      const formData = new FormData();
      formData.append('File', file);

      const exportTypeElement = document.querySelector<HTMLInputElement>(
        'input[name=import]:checked',
      );
      if (!exportTypeElement) {
        console.error('No import type selected');
        setIsPreloader(false);
        return;
      }

      const export_type = exportTypeElement.value;
      let response;

      try {
        if (export_type === 'products') {
          const { data } = await createProductsService(adminInstance).postXLSXProducts(formData);
          response = data;
        } else if (export_type === 'price') {
          const { data } =
            await createProductsService(adminInstance).putXLSXProductsPrice(formData);
          response = data;
        }

        if (response?.name === 'AxiosError') {
          dispatch(
            setInfo({
              n: response.message,
              r: Object.prototype.hasOwnProperty.call(response, 'response')
                ? { d: response.response.data, s: response.response.statusText }
                : '',
            }),
          );
        } else {
          getData();
        }
      } catch (error) {
        console.error('Error during import:', error);
      } finally {
        setIsPreloader(false);
        isActive(false);
      }
    } else {
      console.error('No file selected for import');
      setIsPreloader(false);
    }
  };

  return (
    <div className="modal-window-action">
      <div className="modal-window-action__overlay" onClick={closeModal} />
      <div className="modal-window-action__modal__import-export" style={{ width: 600 }}>
        <div className="modal-window-action__modal__title">
          <span>Import</span>
          <button onClick={closeModal}>
            <CloseBig />
          </button>
        </div>
        <div className="modal-window-action__modal__exports">
          <div>
            <input
              type="radio"
              name="import"
              value="products"
              id="export-products"
              defaultChecked={true}
            />
            <label htmlFor="export-products">Import all products in xlsx file</label>
          </div>
          <div>
            <input type="radio" name="import" value="price" id="export-price" />
            <label htmlFor="export-price">Import prices for all products in xlsx file</label>
          </div>
        </div>
        <div>
          <DragAndDrop file={file} setFile={setFile} />
        </div>
        <div className="modal-window-action__modal__button">
          <button className="section-btn-gradient" onClick={importXLSX}>
            {isPreloader ? <Preloader style={preloaderStyles.DARK} /> : 'Import'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Import;
