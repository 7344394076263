import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

import { logOutAdmin } from '../../redux/reducers/Admin-Reducer';
import { clientLogout } from '../../redux/reducers/ClientProfile-Reducer';
import store from '../../redux/store';
import { getDataForage, saveDataForage } from '../../utils/localforage';
import { url } from './axiosInstances';

interface AdminTokenData {
  a4d: string; // User ID
  a4ac: string; // Access Token
  a4rt: string; // Refresh Token
}

interface ClientTokenData {
  c5d: string; // User ID
  c5ac: string; // Access Token
  c5rt: string; // Refresh Token
}

const isTokenExpired = (token: string): boolean => {
  try {
    const payload = token.split('.')[1];
    const decodedPayload = atob(payload);
    const payloadObj = JSON.parse(decodedPayload);
    const exp = payloadObj.exp;
    const currentTime = Date.now() / 1000;

    return exp < currentTime;
  } catch (error) {
    return false;
  }
};

const getToken = async (key: string): Promise<string> => {
  const storedData = await getDataForage<any>(key);
  if (storedData) {
    const parsedData: AdminTokenData | ClientTokenData = JSON.parse(storedData);
    const resp =
      (parsedData as AdminTokenData)?.a4ac || (parsedData as ClientTokenData)?.c5ac || ' ';
    return resp;
  }
  return ' ';
};

const refreshToken = async (
  userSessionData: AdminTokenData | ClientTokenData,
  instanceType: 'admin' | 'client',
): Promise<string | null> => {
  if (!userSessionData) {
    return null;
  }

  // Проверка истечения refresh token
  const refreshTokenValue =
    instanceType === 'admin'
      ? (userSessionData as AdminTokenData).a4rt
      : (userSessionData as ClientTokenData).c5rt;

  if (isTokenExpired(refreshTokenValue)) {
    store.dispatch(clientLogout());
    store.dispatch(logOutAdmin());
  }

  const data =
    instanceType === 'admin'
      ? {
          userId: (userSessionData as AdminTokenData).a4d,
          refreshToken: (userSessionData as AdminTokenData).a4rt,
          accessToken: (userSessionData as AdminTokenData).a4ac,
        }
      : {
          userId: (userSessionData as ClientTokenData).c5d,
          refreshToken: (userSessionData as ClientTokenData).c5rt,
          accessToken: (userSessionData as ClientTokenData).c5ac,
        };

  try {
    const response = await axios.post(`${url}/Auth/refreshAccessToken`, data);
    const newTokenData =
      instanceType === 'admin'
        ? {
            a4d: (userSessionData as AdminTokenData).a4d,
            a4ac: response.data,
            a4rt: (userSessionData as AdminTokenData).a4rt,
          }
        : {
            c5d: (userSessionData as ClientTokenData).c5d,
            c5ac: response.data,
            c5rt: (userSessionData as ClientTokenData).c5rt,
          };

    await saveDataForage(instanceType === 'admin' ? 'a4ad' : 'clp', JSON.stringify(newTokenData));
    return response.data;
  } catch (error) {
    return null;
  }
};

let isRefreshingToken = false;
let failedQueue: {
  resolve: (token: string) => void;
  reject: (error: any) => void;
}[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token as string);
    }
  });

  failedQueue = [];
};

export const addInterceptor = (instance: AxiosInstance, instanceType: 'admin' | 'client') => {
  instance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      let userSessionData: AdminTokenData | ClientTokenData | null = JSON.parse(
        (await getDataForage<any>(instanceType === 'admin' ? 'a4ad' : 'clp')) || 'null',
      );
      if (userSessionData) {
        const token =
          instanceType === 'admin'
            ? (userSessionData as AdminTokenData).a4ac
            : (userSessionData as ClientTokenData).c5ac;
        if (isTokenExpired(token)) {
          if (!isRefreshingToken) {
            isRefreshingToken = true;
            const newToken = await refreshToken(userSessionData, instanceType);
            if (newToken) {
              if (instanceType === 'admin') {
                (userSessionData as AdminTokenData).a4ac = newToken;
              } else {
                (userSessionData as ClientTokenData).c5ac = newToken;
              }
              await saveDataForage(
                instanceType === 'admin' ? 'a4ad' : 'clp',
                JSON.stringify(userSessionData),
              );
              config.headers['Authorization'] = `Bearer ${newToken}`;
              processQueue(null, newToken);
            } else {
              processQueue(new Error('Ошибка обновления токена'), null);
            }
            isRefreshingToken = false;
          } else {
            return new Promise<string>((resolve, reject) => {
              failedQueue.push({ resolve, reject });
            })
              .then((newToken) => {
                config.headers['Authorization'] = `Bearer ${newToken}`;
                return config;
              })
              .catch((error) => Promise.reject(error));
          }
        } else {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      }
      return config;
    },
    (error: any) => Promise.reject(error),
  );
};

export const addAuthInterceptor = (instance: AxiosInstance, tokenKey: string) => {
  instance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      const token = await getToken(tokenKey);
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
};
