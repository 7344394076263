import React from 'react';

const FilterIcon: React.FC<{ isSvgFilterColor: boolean }> = ({ isSvgFilterColor }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill={isSvgFilterColor ? '#25A8CF' : 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="47"
      height="47"
      rx="23.5"
      stroke={isSvgFilterColor ? '#25A8CF' : 'white'}
    />
    <g clipPath="url(#clip0_3031_3731)">
      <path
        d="M15.667 15.666V18.6993L22.4837 27.0327V32.3327L25.517 30.816V27.0327L32.3337 18.6993V15.666H15.667Z"
        stroke={isSvgFilterColor ? 'black' : 'white'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_3031_3731">
        <rect width="20" height="20" fill="white" transform="translate(14 14)" />
      </clipPath>
    </defs>
  </svg>
);

export default FilterIcon;
