import './CookieModal.scss';

import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { acceptCookiePolicy } from '../../../general/redux/reducers/CookiePolicy-Reducer';

const CookieModal: React.FC = () => {
  const dispatch = useDispatch();

  const acceptCookies = () => {
    dispatch(acceptCookiePolicy('Accepted'));
  };

  return (
    <div className="cookies-modal">
      <span>
        We use cookies and similar technologies as specified in our
        <Link to="/cookies-policy">Cookies Policy</Link>.
      </span>
      <button onClick={acceptCookies}>Accept Cookies</button>
    </div>
  );
};

export default CookieModal;
