import './AdminList.scss';

import React, { useState } from 'react';

import DeleteActionIcon from '../../../../general/assets/svg/DeleteActionIcon';
import EditIconAction from '../../../../general/assets/svg/EditIconAction';
import AdminModal from './AdminModal';

interface ActionButtonsProps {
  item: any;
  deleteMethod: () => void;
  refreshMethod: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ item, deleteMethod, refreshMethod }) => {
  const [isModalMessage, setIsModalMessage] = useState(false);

  const openModal = () => {
    setIsModalMessage(true);
  };

  return (
    <>
      {isModalMessage && (
        <AdminModal
          isEdit={true}
          isOpen={setIsModalMessage}
          refreshMethod={refreshMethod}
          admin={item}
        />
      )}
      <button
        style={{ width: 'auto' }}
        onClick={openModal}
        className="table-block__tbody__change-item__button"
      >
        <EditIconAction />
      </button>
      <button
        data-id={item.id}
        onClick={deleteMethod}
        className="table-block__tbody__change-item__button"
      >
        <DeleteActionIcon />
      </button>
    </>
  );
};

export default ActionButtons;
