import React from 'react';

const ArticlesIcon: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.4535 8.36V30H4.54688V2H21.0935L27.4535 8.36Z"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M27.4535 8.36V9.64H19.8135V2H21.0935L27.4535 8.36Z"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M21.0935 14.72L14.7202 21.0933L11.5469 17.9066"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default ArticlesIcon;
