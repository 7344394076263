import React, { useState } from 'react';

import CloseBig from '../../../../general/assets/svg/CloseBig';
import Preloader, { preloaderStyles } from '../../../../general/components/preloader/Preloader';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createProductsService from '../../../../general/services/products';
import getFile from '../../../../general/utils/GetFile';

// Define the types for props
interface ExportProps {
  isActive: (active: boolean) => void;
}

const Export: React.FC<ExportProps> = ({ isActive }) => {
  const [isPreloader, setIsPreloader] = useState<boolean>(false);

  const closeModal = () => {
    isActive(false);
  };

  const exportXLSX = async () => {
    setIsPreloader(true);
    const exportTypeElement = document.querySelector<HTMLInputElement>(
      'input[name=export]:checked',
    );

    if (!exportTypeElement) {
      console.error('No export type selected');
      setIsPreloader(false);
      return;
    }

    const export_type = exportTypeElement.value;
    let response;

    try {
      if (export_type === 'products') {
        const { data } = await createProductsService(adminInstance).getXLSXProducts();
        response = data;
      } else if (export_type === 'price') {
        const { data } = await createProductsService(adminInstance).getXLSXProductsPrice();
        response = data;
      }

      if (response) {
        getFile(response, export_type);
      }
    } catch (error) {
      console.error('Error exporting file:', error);
    } finally {
      setIsPreloader(false);
      isActive(false);
    }
  };

  return (
    <div className="modal-window-action">
      <div className="modal-window-action__overlay" onClick={closeModal} />
      <div className="modal-window-action__modal__import-export" style={{ width: 600 }}>
        <div className="modal-window-action__modal__title">
          <span>Export</span>
          <button onClick={closeModal}>
            <CloseBig />
          </button>
        </div>
        <div className="modal-window-action__modal__exports">
          <div>
            <input
              type="radio"
              name="export"
              value="products"
              id="export-products"
              defaultChecked={true}
            />
            <label htmlFor="export-products">Export all products in xlsx file</label>
          </div>
          <div>
            <input type="radio" name="export" value="price" id="export-price" />
            <label htmlFor="export-price">Export prices for all products in xlsx file</label>
          </div>
        </div>
        <div className="modal-window-action__modal__button">
          <button className="section-btn-gradient" onClick={exportXLSX}>
            {isPreloader ? <Preloader style={preloaderStyles.DARK} /> : 'Export'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Export;
