import './cardSlider-slick-theme.scss';
import './cardSlider-slick.scss';

import React from 'react';
import Slider from 'react-slick';

import { Image } from '../product-slider/ProductSlider';

const CardSlider: React.FC<{ images: Image[] }> = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings}>
      {images.map((image, index) => (
        <div key={index} className="slider-item-img">
          <img src={image.url} alt={image.description || `Product ${index + 1}`} />
        </div>
      ))}
    </Slider>
  );
};

export default CardSlider;
