import React from 'react';

const RemoteCustomerSupportIcon: React.FC = () => (
  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39.6325 26.8458C41.7422 26.8458 43.4525 25.1355 43.4525 23.0258C43.4525 20.9161 41.7422 19.2058 39.6325 19.2058C37.5228 19.2058 35.8125 20.9161 35.8125 23.0258C35.8125 25.1355 37.5228 26.8458 39.6325 26.8458Z"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M33.8926 32.5658C33.9188 31.0608 34.5351 29.6264 35.6087 28.5715C36.6824 27.5166 38.1274 26.9256 39.6326 26.9258C41.1515 26.9311 42.6063 27.5382 43.6784 28.614C44.7506 29.6899 45.3526 31.1469 45.3526 32.6658"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M9.07293 26.8458C11.1827 26.8458 12.8929 25.1355 12.8929 23.0258C12.8929 20.9161 11.1827 19.2058 9.07293 19.2058C6.9632 19.2058 5.25293 20.9161 5.25293 23.0258C5.25293 25.1355 6.9632 26.8458 9.07293 26.8458Z"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M3.35254 32.5658C3.37869 31.0643 3.99223 29.6328 5.06156 28.5785C6.13088 27.5241 7.57083 26.9308 9.07254 26.9258C9.82633 26.9258 10.5727 27.0743 11.2691 27.3627C11.9656 27.6512 12.5983 28.074 13.1313 28.607C13.6643 29.14 14.0871 29.7728 14.3756 30.4692C14.6641 31.1656 14.8125 31.912 14.8125 32.6658"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M20.5325 5.8457L14.8125 11.5657H37.7125"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M28.1722 44.0259L33.8922 38.2859H10.9922"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default RemoteCustomerSupportIcon;
