import React from 'react';

const LightningIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.52669 1.14001H12.3334L9.24002 6.09335H11.7134L4.90669 13.5267L6.14002 8.57335H3.66669L5.52669 1.14001Z"
      stroke="white"
      strokeMiterlimit="10"
    />
  </svg>
);

export default LightningIcon;
