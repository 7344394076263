import './AuditLogs.scss';

import React, { useEffect } from 'react';

import Preloader from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import usePagination from '../../../general/hooks/usePagination';
import { useResize } from '../../../general/hooks/useResize';
import { queryKeys } from '../../../general/queryKeys';
import createLogsService from '../../../general/services/logs';
import { adminInstance } from '../../../general/services/main/axiosInstances';
import AdminSearch from '../../components/search/Search';
import CustomPagination from '../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../components/tables/custom-table/CustomTable';

const AuditLogs: React.FC = () => {
  const { page, pageSize, searchQuery, setPage, setPageSize, setSearchQuery } = usePagination();
  const size = useResize();

  const {
    data: logs,
    isLoading: logsLoading,
    refetch: getData,
  } = useCustomQuery<any>(queryKeys.ADMIN_LOGS, () =>
    createLogsService(adminInstance).getAllLogs(searchQuery, pageSize, page),
  );

  useEffect(() => {
    getData();
  }, [searchQuery, page, pageSize, getData]);

  return (
    <div className="page-section">
      <div className="page-section__content">
        <div className="page-section__top">
          <div className="page-section__top__title">AUDIT LOGS</div>
        </div>
        <div>
          <AdminSearch
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            placeholder={'Search by name, message'}
          />
        </div>
        <div className="page-section__content__list-section">
          <div className="list-section__content">
            {logsLoading && (
              <div className="page-update-overlay">
                <Preloader />
              </div>
            )}

            <CustomTable
              data={logs ? logs?.items : []}
              headers={['admin-name', 'date', 'log-message']}
              isAction={false}
              isMobile={size.width < 850}
            />
          </div>
        </div>
        {logs && logs?.pageSize < logs?.totalCount && (
          <CustomPagination
            currentPage={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            isNextPage={logs.hasNextPage}
            isPrevPage={logs.hasPreviousPage}
            total={logs.totalCount}
          />
        )}
      </div>
    </div>
  );
};

export default AuditLogs;
