import './AdminClient.scss';

import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import createClientService from '../../../../general/services/client';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import { Client, Order } from '../../../../general/types/types';
import { formatToTwoPlaces } from '../../../../general/utils/Validations';
import RemoveClientModal from './RemoveClient';

const AdminClient: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [isRemove, setIsRemove] = useState(false);

  const { data: client } = useCustomQuery<Client>(
    queryKeys.CLIENT_IN_ADMIN,
    () => createClientService(adminInstance).getCLient(id as string),
    undefined,
    undefined,
    {
      enabled: !!id,
    },
  );

  const { data: orders } = useCustomQuery<Order[]>(
    queryKeys.CLIENT_IN_ADMIN_ORDERS,
    () => createClientService(adminInstance).getCLientOrders(id as string),
    undefined,
    undefined,
    {
      enabled: !!id,
    },
  );

  const isRemoveClient = async () => {
    setIsRemove(true);
  };

  return (
    <>
      {isRemove && <RemoveClientModal isOpen={setIsRemove} />}
      <div className="page-section">
        <div className="page-section__top">
          <div className="page-section__top__title">CLIENT</div>
          <button className="delete-user-information" onClick={isRemoveClient}>
            Delete
          </button>
        </div>
        <div className="page-section__client">
          <div className="page-section__client-grid">
            <div className="page-section__client__info">
              <div className="page-section__client__block__top">Information</div>
              <div className="page-section__client__block__main-info">
                <div className="page-section__client__text-block client-colmn-a">
                  <span className="page-section__client__text-block__title">Full name:</span>
                  <span className="page-section__client__text-block__text">
                    {client?.name || 'empty'} {client?.surname || 'empty'}
                  </span>
                </div>
                <div className="page-section__client__text-block client-colmn-b">
                  <span className="page-section__client__text-block__title">Phone:</span>
                  <span className="page-section__client__text-block__text">
                    {client?.phoneNumber || 'empty'}
                  </span>
                </div>
                <div className="page-section__client__text-block client-colmn-b">
                  <span className="page-section__client__text-block__title">Email:</span>
                  <span className="page-section__client__text-block__text">
                    {client?.email || 'empty'}
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="page-section__client__area">
              <div className="page-section__client__block__top">Addresses</div>
            </div> */}
            <div className="page-section__client__orders">
              <div className="page-section__client__block__top">Orders</div>
              {orders && orders?.length !== 0 ? (
                <ul className="page-section__client__orders__list">
                  {orders?.map((order, index) => (
                    <li key={index}>
                      <div className="order-item__order-info">
                        <div className="order-item__order-status-data">
                          <span
                            className={`order-item__order-status order-${
                              order.orderStatuses[order.orderStatuses.length - 1].name.split(' ')[0]
                            }`}
                          >
                            {' '}
                            {order.orderStatuses[order.orderStatuses.length - 1].name}
                          </span>
                          <span className="order-item__order-data">
                            {new Date(order.createdAt)
                              .toLocaleString()
                              .split(',')[0]
                              .split('/')
                              .join('.')}
                          </span>
                        </div>
                        <span className="order-item__order-number">
                          <Link to={`/admin/orders/${order.id}`}> Order #{order.idFormatted} </Link>
                        </span>
                      </div>

                      <div className="order-item__more-info">
                        <div className="order-item__more-info__count">
                          {order.totalProductsQuantity}
                          {order.totalProductsQuantity > 1 ? ' products ' : ' product '}
                        </div>
                        <div className="order-item__order-total-price-payment__block">
                          <div className="order-item__order-total-price-payment">
                            <span
                              className={`order-payment ${
                                order.isPaid ? 'is-paid' : 'is-not-paid'
                              }`}
                            >
                              {order.isPaid ? 'Paid' : 'Not paid'}
                            </span>
                            <span className="order-total-price">
                              {formatToTwoPlaces(order.totalPrice)}$
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="page-section__client__orders__list__empty">
                  This client has no orders yet
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminClient;
