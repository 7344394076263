import React from 'react';
import TemplateModalWindow from '../modals-window/TemplateModalWindow';

import './ContactForm.scss';

interface NewMessageProps {
  isOpen: (isOpen: boolean) => void;
}

const NewMessage: React.FC<NewMessageProps> = ({ isOpen }) => {
  const closeModal = () => {
    isOpen(false);
  };

  return (
    <>
      <TemplateModalWindow isOpen={isOpen} messageClass="">
        <div className="footer-modal-window__content__title">Thank you for message.</div>
        <div className="footer-modal-window__content__text">
          Our specialist will contact you shortly.
        </div>
        <div className="footer-modal-window__content__action">
          <button className="section-btn-gradient" onClick={closeModal}>
            Ok
          </button>
        </div>
      </TemplateModalWindow>
    </>
  );
};

export default NewMessage;
