import './AdminProfile.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Preloader from '../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../general/queryKeys';
import { setInfo, setType, types } from '../../../general/redux/reducers/Error-Reducer';
import createAdminsService from '../../../general/services/admins';
import { adminInstance } from '../../../general/services/main/axiosInstances';
import { Admin } from '../../../general/types/types';
import { getDataForage } from '../../../general/utils/localforage';

const AdminProfile: React.FC = () => {
  const dispatch = useDispatch();
  const [adminId, setAdminId] = useState<string>('');

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const data: string | null = await getDataForage('a4ad');
        const parsedData = JSON.parse(data || '{}');
        const userId = parsedData?.a4d || '';
        setAdminId(userId);
      } catch (err) {
        console.error('Error parsing user data:', err);
      }
    };

    fetchUserId();
  }, []);

  const { data: admin } = useCustomQuery<Admin>(
    queryKeys.CURRENT_ADMIN,
    () => createAdminsService(adminInstance).getAdmin(adminId),
    undefined,
    undefined,
    {
      enabled: !!adminId,
    },
  );

  const { data: adminRoles } = useCustomQuery<string[]>(
    queryKeys.ADMIN_ROLES,
    () => createAdminsService(adminInstance).getAdminRoles(adminId),
    undefined,
    undefined,
    {
      enabled: !!adminId,
    },
  );

  const { mutate: updateAdmin, isLoading: updating } = useCustomMutation(
    createAdminsService(adminInstance).putAdminInfo,
    undefined,
    {
      onSuccess: () => {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'Admin info sucessfully updated' },
          }),
        );
      },
    },
  );

  const { mutate: updatePassword, isLoading: updatingPassword } = useCustomMutation(
    createAdminsService(adminInstance).putAdminPassword,
    undefined,
    {
      onSuccess: () => {
        dispatch(setType(types.WARNING));
        dispatch(
          setInfo({
            n: types.WARNING,
            r: {
              s: 'Admin password sucessfully updated. Please remember it',
            },
          }),
        );
      },
    },
  );

  const updateAdminInfo = async () => {
    const block = document.querySelector('.page-section__admin__information__main-info');
    const inputs = block?.querySelectorAll('input') as NodeListOf<HTMLInputElement>;

    const required: boolean[] = [];

    if (inputs[0].value.trim() === '') {
      inputs[0].style.border = '1px solid red';
      required.push(false);
    }

    if (inputs[1].value.trim() === '') {
      inputs[1].style.border = '1px solid red';
      required.push(false);
    }
    if (inputs[2].value.trim() === '') {
      inputs[2].style.border = '1px solid red';
      required.push(false);
    }
    if (inputs[3].value.trim() === '') {
      inputs[3].style.border = '1px solid red';
      required.push(false);
    }

    if (admin) {
      const result = {
        authorizedAdminId: admin.id,
        name: inputs[1].value.trim(),
        surname: inputs[2].value.trim(),
        username: inputs[0].value.trim(),
        email: inputs[3].value.trim(),
      };

      updateAdmin(result);
    }
  };

  const updateAdminPassword = async () => {
    const block = document.querySelector('.page-section__admin__information__security-info');
    const inputs = block?.querySelectorAll('input') as NodeListOf<HTMLInputElement>;

    const required: boolean[] = [];

    if (inputs[0].value.trim() === '') {
      inputs[0].style.border = '1px solid red';
      required.push(false);
    }

    if (inputs[1].value.trim() === '') {
      inputs[1].style.border = '1px solid red';
      required.push(false);
    }

    if (admin) {
      const result = {
        authorizedAdminId: admin.id,
        oldPassword: inputs[0].value.trim(),
        newPassword: inputs[1].value.trim(),
      };

      if (result.oldPassword.trim() === '' || result.newPassword.trim() === '') {
        dispatch(setType(types.ERROR));
        dispatch(
          setInfo({
            n: types.ERROR,
            r: { s: 'Old and new passwords cannot be empty' },
          }),
        );
        return;
      }

      updatePassword(result);

      inputs[0].value = '';
      inputs[1].value = '';
    }
  };

  return (
    <div className="page-section">
      <div className="page-section__top">
        <div className="page-section__top__title">PROFILE</div>
      </div>
      <div className="page-section__content">
        {(updatingPassword || updating) && (
          <div className="page-update-overlay">
            <Preloader />
          </div>
        )}
        <div className="page-section__admin">
          <div className="page-section__admin__information">
            <div className="page-section__admin__information__main-info">
              <div className="page-section__admin__block__top">Information</div>
              <div className="page-section__admin__block__info">
                <div className="page-section__admin__block__info__inputs">
                  <div className="page-section__admin__block__info__input">
                    <label> Username </label>
                    <input type="text" defaultValue={admin?.userName} />
                  </div>
                  <div className="page-section__admin__block__info__input">
                    <label> Name </label>
                    <input type="text" defaultValue={admin?.name} />
                  </div>
                  <div className="page-section__admin__block__info__input">
                    <label> Surname </label>
                    <input type="text" defaultValue={admin?.surname} />
                  </div>
                  <div className="page-section__admin__block__info__input">
                    <label> Email </label>
                    <input type="text" defaultValue={admin?.email} />
                  </div>
                </div>
                <div className="page-section__admin__block__info__button">
                  <button className="section-btn-gradient" onClick={updateAdminInfo}>
                    Update
                  </button>
                </div>
              </div>
            </div>
            <div className="page-section__admin__information__security-info">
              <div className="page-section__admin__block__top">Security</div>
              <div className="page-section__admin__block__info">
                <div className="page-section__admin__block__info__inputs">
                  <div className="page-section__admin__block__info__inputs">
                    <div className="page-section__admin__block__info__input">
                      <label> Old password </label>
                      <input type="password" />
                    </div>
                    <div className="page-section__admin__block__info__input">
                      <label> New password </label>
                      <input type="password" />
                    </div>
                  </div>
                  <div className="page-section__admin__block__info__button">
                    <button className="section-btn-gradient" onClick={updateAdminPassword}>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-section__admin__static-information">
            <div className="page-section__admin__static-information__roles">
              <div className="page-section__admin__block__top">Roles</div>
              <div className="page-section__admin__block__info">
                <ul>
                  {adminRoles && adminRoles?.map((role, index) => <li key={index}>{role}</li>)}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProfile;
