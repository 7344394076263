import './Shipping.scss';

import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import LocationProdStoresIcon from '../../../../../general/assets/svg/LocationProdStoresIcon';
import useScrollTo from '../../../../../general/hooks/useScrollTo';
import {
  billingState,
  deliveryMethodsEnum,
  setChoosenRateId,
  setContMethod,
  setIsBillingTheSame,
} from '../../../../../general/redux/reducers/ClientCartProcessing-Reducer';
import { RootState } from '../../../../../general/redux/store';

interface AddressField {
  field: string;
  value: string;
}

interface Contact {
  id: string;
  placeholder: string;
  value: string;
}

const Shipping = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollTo = useScrollTo();

  const storesLocationRedux = useSelector(
    (state: RootState) => state.clientCartProcessing.shippingStoresLocationAddress,
  );
  const deliveryMethodRedux = useSelector(
    (state: RootState) => state.clientCartProcessing.deliveryMethod,
  );

  const valuesShipping: AddressField[] = JSON.parse(
    sessionStorage.getItem('shipping-fields') || '[]',
  );
  const valuesBilling: AddressField[] = JSON.parse(
    sessionStorage.getItem('billing-fields') || '[]',
  );
  const savedContact: Contact | null = JSON.parse(
    sessionStorage.getItem('selectedContactMethod') || 'null',
  );
  const user = useSelector((state: RootState) => state.clientCartProcessing.user);
  const [currentFrame, setCurrentFrame] = useState<string>('');

  const addresses = useMemo(() => {
    if (deliveryMethodRedux === deliveryMethodsEnum.SHIPPING) {
      const defaultAddress = valuesShipping;
      const billingAddress = valuesBilling === null ? valuesShipping : valuesBilling;

      return [defaultAddress, billingAddress];
    } else {
      return [valuesBilling];
    }
  }, [valuesShipping, valuesBilling, deliveryMethodRedux]);

  const loadContactsFromSessionStorage = () => {
    const savedContacts: Contact[] = JSON.parse(sessionStorage.getItem('contactsMethod') || 'null');

    if (savedContacts) {
      return savedContacts;
    } else {
      return [
        {
          id: 'email',
          placeholder: 'E-mail',
          value: addresses[0].find((address) => address.field === 'email')?.value || '',
        },
        {
          id: 'phone',
          placeholder: 'Phone',
          value: addresses[0].find((address) => address.field === 'phone')?.value || '',
        },
        {
          id: 'whatsapp',
          placeholder: 'WhatsApp',
          value: user?.whatsApp || '',
        },
        {
          id: 'telegram',
          placeholder: 'Telegram',
          value: user?.telegram || '',
        },
      ];
    }
  };

  const [contacts, setContacts] = useState<Contact[]>(loadContactsFromSessionStorage);

  // Начально выбираем email, если существует, иначе выбираем первый элемент
  const initialContactMethod = savedContact ? savedContact.id : contacts[0].id;
  const [contactMethod, setContactMethod] = useState(initialContactMethod);

  const updateContactMethod = useCallback(
    (value: string) => {
      sessionStorage.setItem(
        'selectedContactMethod',
        JSON.stringify({
          id: contactMethod,
          placeholder: contacts.find((contact) => contact.id === contactMethod)?.placeholder,
          value: value,
        }),
      );

      setContacts((prevContacts) =>
        prevContacts.map((contact) =>
          contact.id === contactMethod ? { ...contact, value } : contact,
        ),
      );
      dispatch(setContMethod({ name: contactMethod, value }));
    },
    [contactMethod, contacts, dispatch],
  );

  const handleChangeContactMethod = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newMethod = e.target.value;
      setContactMethod(newMethod);
      const selectedContact = contacts.find((contact) => contact.id === newMethod);
      if (selectedContact) {
        sessionStorage.setItem(
          'selectedContactMethod',
          JSON.stringify({
            id: selectedContact.id,
            placeholder: selectedContact.placeholder,
            value: selectedContact.value,
          }),
        );
        dispatch(setContMethod({ name: newMethod, value: selectedContact.value }));
      }
    },
    [contacts, dispatch],
  );

  const handleChangeContact = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      updateContactMethod(e.target.value);
    },
    [updateContactMethod],
  );

  useEffect(() => {
    // Сохраняем начальный метод связи
    const initialContact = contacts.find((contact) => contact.id === contactMethod);
    if (initialContact) {
      dispatch(setContMethod({ name: contactMethod, value: initialContact.value }));
    }
  }, [contactMethod, contacts, dispatch]);

  useEffect(() => {
    const saveContactsToSessionStorage = () => {
      sessionStorage.setItem('contactsMethod', JSON.stringify(contacts));
    };

    window.addEventListener('beforeunload', saveContactsToSessionStorage);

    return () => {
      window.removeEventListener('beforeunload', saveContactsToSessionStorage);
      saveContactsToSessionStorage();
    };
  }, [contacts]);

  useEffect(() => {
    // TODO use id
    const anchor = sessionStorage.getItem('scrollToAnchor');

    if (anchor) {
      setTimeout(() => {
        scrollTo(anchor);
        sessionStorage.removeItem('scrollToAnchor');
      }, 1000);
    }
  }, [scrollTo]);

  return (
    <>
      <h2>Check information</h2>
      {deliveryMethodRedux === deliveryMethodsEnum.SELF_PICKUP && (
        <div className="shipping-stores_locations">
          <div className="available_stores">
            {storesLocationRedux.map((store, storeIndex) => (
              <div
                key={storeIndex}
                className="store-item"
                style={{ position: 'relative' }}
                onClick={() => {
                  setCurrentFrame(store.mapFrame);
                }}
              >
                <LocationProdStoresIcon />
                <div className="info-block">
                  <span className="country">{store.country}</span>
                  <span className="address">{store.address}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="map" dangerouslySetInnerHTML={{ __html: currentFrame }}></div>
        </div>
      )}
      <div className="addresses">
        {addresses?.map((address, index) => (
          <div style={{ flex: 1 }} key={index}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span className="header">
                {index === 0
                  ? deliveryMethodRedux === deliveryMethodsEnum.SHIPPING
                    ? 'Shipping address'
                    : deliveryMethodRedux === deliveryMethodsEnum.SELF_PICKUP
                      ? 'Billing address'
                      : ''
                  : index === 1 && deliveryMethodRedux === deliveryMethodsEnum.SHIPPING
                    ? 'Billing address'
                    : ''}
              </span>
              <span
                onClick={() => {
                  const anchor = index === 0 ? 'shipping-fields' : 'billing-fields';

                  if (index === 1) {
                    dispatch(setIsBillingTheSame(billingState.NOT_SAME));
                  }

                  dispatch(setChoosenRateId(null));
                  sessionStorage.setItem('scrollToAnchor', anchor);
                  navigate(-1);
                }}
                className="link"
              >
                Change
              </span>
            </div>
            <div
              className={`address-block  ${index === 1 ? 'billing' : ''}`}
              style={{ background: index === 0 ? '#25A8CF1A' : '' }}
            >
              {address?.map(
                ({ field, value }, innerIndex) =>
                  value &&
                  value !== '' && (
                    <div key={innerIndex} className="field-block">
                      <span>{field}:</span>
                      <span className="dots"></span>
                      <span className={`large-text block${innerIndex}`}>{value}</span>
                      <Tooltip anchorSelect={`.block${innerIndex}`} place="top">
                        {value}
                      </Tooltip>
                    </div>
                  ),
              )}
            </div>
          </div>
        ))}
      </div>
      <div id="contacts" className="contacts">
        <span>Contact method</span>
        {contacts.map((contact, index) => (
          <div className="contact-ellement" key={index}>
            <label className="custom-radio">
              <input
                type="radio"
                name="contact"
                value={contact.id}
                checked={contactMethod === contact.id}
                onChange={handleChangeContactMethod}
              />
              <span className="radio-btn"></span>
            </label>
            <input
              id={contact.id}
              className={`input_symbols_panel ${contactMethod === contact.id ? 'active' : ''}`}
              type="text"
              onChange={handleChangeContact}
              value={contact.value}
              placeholder={contact.placeholder}
              disabled={contactMethod !== contact.id}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default Shipping;
