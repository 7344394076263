import './BlogPost.scss';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import InstagranFooterIcon from '../../../general/assets/svg/InstagranFooterIcon';
import TelegramLinkFooterIcon from '../../../general/assets/svg/TelegramLinkFooterIcon';
import TwitterFooterIcon from '../../../general/assets/svg/TwitterFooterIcon';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../general/queryKeys';
import createContactsService from '../../../general/services/contacts';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import { Article, Contacts } from '../../../general/types/types';
import { getTimeInterval } from '../../pages/blog-post-page/PostPage';

interface BlogPostProps {
  article: Article;
  box: number;
}

const BlogPost: React.FC<BlogPostProps> = ({ article, box }) => {
  const [interval, setInterval] = useState<string>('');

  const { data: contacts } = useCustomQuery<Contacts>(queryKeys.COMPANY_CONTACTS, () =>
    createContactsService(clientInstance).getContacts(),
  );

  const backgroundImage = {
    backgroundImage: `url(${article?.imageUrl})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  useEffect(() => {
    const timeInterval = `${getTimeInterval(article?.createdAt)}`;
    setInterval(timeInterval);
  }, [article?.createdAt]);

  const handleExternalLinkClick = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Link
      to={`/blog/${article.url}`}
      className={`template-blog-post post-box-${box}`}
      style={backgroundImage}
    >
      <div className="template-blog-post__container">
        <div className="template-blog-post__blur"></div>
        <div className="template-blog-post__info">
          <div className="template-blog-post__icons">
            {contacts && (
              <>
                <button
                  onClick={() => handleExternalLinkClick(contacts?.instagramLink)}
                  aria-label="Instagram"
                >
                  <InstagranFooterIcon color="black" />
                </button>
                <button
                  onClick={() => handleExternalLinkClick(contacts?.xLink)}
                  aria-label="Twitter"
                >
                  <TwitterFooterIcon color="black" />
                </button>
                <button
                  onClick={() => handleExternalLinkClick(contacts?.telegramLink)}
                  aria-label="Telegram"
                >
                  <TelegramLinkFooterIcon color="black" />
                </button>
              </>
            )}
          </div>
          <div className="template-blog-post__title">{article?.title}</div>
          <div className="template-blog-post__creator">{interval}</div>
        </div>
      </div>
    </Link>
  );
};

export default BlogPost;
