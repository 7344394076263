import React from 'react';
import { images } from '../../../general/StaticData';
import { Contacts } from '../../../general/types/types';

interface OrganizationSchemaProps {
  constacts: Contacts;
}

const OrganizationSchema: React.FC<OrganizationSchemaProps> = ({ constacts }) => {
  const organizationSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'AsicXchange',
    url: window.location.origin,
    logo: images.asicxChangeLogo,
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: constacts.phoneWeb,
      contactType: 'customer service',
      areaServed: 'US',
      availableLanguage: ['English', 'Russian'],
    },
    sameAs: [
      constacts.facebookLink,
      constacts.xLink,
      constacts.linkedinLink,
      constacts.instagramLink,
    ],
  };

  return <script type="application/ld+json">{JSON.stringify(organizationSchema)}</script>;
};

export default OrganizationSchema;
