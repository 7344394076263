import { useEffect, useRef, useState } from 'react';

interface UseDynamicHeightReturn {
  ref: React.RefObject<HTMLDivElement>;
  style: React.CSSProperties;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
}

const useDynamicHeight = (): UseDynamicHeightReturn => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState('0px');

  useEffect(() => {
    if (ref.current) {
      if (isOpen) {
        setHeight(`${ref.current.scrollHeight}px`); // Устанавливаем высоту содержимого при открытии
      } else {
        setHeight('0px'); // Сбрасываем высоту до 0px при закрытии
      }
    }
  }, [isOpen]);

  return {
    ref,
    style: {
      height,
      overflow: 'hidden',
      transition: 'height 0.5s ease',
    },
    setIsOpen,
    isOpen,
  };
};

export default useDynamicHeight;
