import React from 'react';
import Marquee from 'react-fast-marquee';

import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import createHomePageService from '../../../../general/services/homePage';
import { clientInstance } from '../../../../general/services/main/axiosInstances';

interface Partner {
  url: string;
  name: string;
  imageUrl: string;
}

const SponsorsLine: React.FC = () => {
  const { data: partners, isLoading: isLoadingPartners } = useCustomQuery<Partner[]>(
    queryKeys.COMPANY_PARTNERS,
    () => createHomePageService(clientInstance).getPartners(),
  );

  return (
    <>
      {isLoadingPartners ? (
        <div>
          <Preloader />
        </div>
      ) : (
        <>
          {partners?.length !== 0 && (
            <Marquee play={true} autoFill={true} speed={60} pauseOnHover={true}>
              {partners?.map((partner, index) => (
                <article className="sponsors-line__element" key={index}>
                  <div className="sponsors-section__img">
                    <img src={partner.imageUrl} alt={partner.name} />
                  </div>
                </article>
              ))}
            </Marquee>
          )}
        </>
      )}
    </>
  );
};

export default SponsorsLine;
