import React from 'react';

interface TwitterFooterIconProps {
  color: string;
}

const TwitterFooterIcon: React.FC<TwitterFooterIconProps> = ({ color }) => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 3L11.2408 16.5L2.19572 27H4.19972L12.1259 17.7928L18.4284 27H26L16.3456 12.8968L24.8651 3H22.8672L15.4593 11.6003L9.57163 3H2ZM4.39298 4.26316H8.90814L23.607 25.7368H19.0919L4.39298 4.26316Z"
      fill={color}
    />
  </svg>
);

export default TwitterFooterIcon;
