interface FreeImages {
  defaultImg: string;
  noGoods: string;
  poolPosibility1: string;
  poolPosibility2: string;
  poolPosibility3: string;
  asicxChangeLogo: string;
}

interface Google {
  authClientId: string;
  mapPb: string;
}

interface PartnerLinks {
  ecmdIo: string;
}

export type ENVIRONMENT = 'DEV_LOCAL' | 'DEV_SITE' | 'PROD';

export const environments = {
  DEV_LOCAL: 'DEV_LOCAL' as ENVIRONMENT,
  DEV_SITE: 'DEV_SITE' as ENVIRONMENT,
  PROD: 'PROD' as ENVIRONMENT,
};

// Determine the environment
export const environment: ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as ENVIRONMENT;

export const partnerLinks: PartnerLinks = {
  ecmdIo: 'https://emcd.io/pool/dashboard/registration?promo=OtJ20VMw',
};

export const images: FreeImages = {
  asicxChangeLogo: 'https://s3.tebi.io/asicxchange-media/49744fb0-aeaa-4877-b846-8b16601d55a5',
  defaultImg: 'https://s3.tebi.io/media-folder/b25df7ad-ae22-4dac-a639-66f6503724ee',
  noGoods: 'https://s3.tebi.io/media-folder/3472a84f-aa53-47d3-9d36-c5d339526fc6',
  poolPosibility1: 'https://s3.tebi.io/asicxchange-media/96f55dc7-3692-44bd-9090-2747d7cbf800',
  poolPosibility2: 'https://s3.tebi.io/asicxchange-media/14717b71-078b-4106-9139-0c5353bbf4a5',
  poolPosibility3: 'https://s3.tebi.io/asicxchange-media/408d28ae-5813-4e9d-b21a-0c4eb9aa84ab',
};

export const googleFeatures: Google = {
  authClientId: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID as string,
  mapPb: process.env.REACT_APP_GOOGLE_MAP_PB as string,
};

export const hostingMapLibreKey = process.env.REACT_APP_HOSTING_MAP_LIBRE_KEY as string;
