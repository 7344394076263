import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import { RootState } from '../../../../general/redux/store';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createShippingService from '../../../../general/services/shipping';
import { ProductsStoreAddress } from '../../../../general/types/types';
import AdminModalWindow from '../../../components/modal-window/ModalWindow';

interface StoreEditorProps {
  isOpen: (isOpen: boolean) => void;
  refresh: () => void;
}

const StoreEditor: React.FC<StoreEditorProps> = ({ isOpen, refresh }) => {
  const storeToEdit = useSelector((state: RootState) => state.store.storeToEdit);

  const [formData, setFormData] = useState<
    ProductsStoreAddress | { country: string; address: string; status: boolean; mapFrame: string }
  >({
    country: storeToEdit ? storeToEdit.country : '',
    address: storeToEdit ? storeToEdit.address : '',
    status: storeToEdit ? storeToEdit.status : false,
    mapFrame: storeToEdit ? storeToEdit.mapFrame : '',
  });

  const { mutate: createNewStore, isLoading: creatingStore } = useCustomMutation(
    createShippingService(adminInstance).createProductsStore,
    undefined,
    {
      onSuccess: () => {
        refresh();
      },
    },
  );

  const { mutate: updateStore, isLoading: updatingStore } = useCustomMutation(
    createShippingService(adminInstance).updateProductsStore,
    undefined,
    {
      onSuccess: () => {
        refresh();
      },
    },
  );

  useEffect(() => {
    if (storeToEdit) {
      setFormData(storeToEdit);
    }
  }, [storeToEdit]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      status: e.target.value === 'true',
    });
  };

  const handleSave = () => {
    if (!storeToEdit) {
      createNewStore({ ...formData });
    } else {
      updateStore({ id: storeToEdit.id, ...formData });
    }
    isOpen(false);
  };

  return (
    <AdminModalWindow isOpen={isOpen} title={'Store'} saveCallback={refresh}>
      {(creatingStore || updatingStore) && (
        <div className="modal-window-action__modal__home__loading">
          <Preloader />
        </div>
      )}
      <div className="modal-window-action__modal__store">
        <div className="modal-window-action__modal__store__inputs">
          <input
            className="input_symbols_panel"
            type="text"
            placeholder="Country"
            name="country"
            value={formData.country}
            onChange={handleInputChange}
          />
          <input
            className="input_symbols_panel"
            type="text"
            placeholder="Address"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
          />
          <input
            className="input_symbols_panel"
            type="text"
            placeholder="iframe"
            name="mapFrame"
            value={formData.mapFrame}
            onChange={handleInputChange}
          />
          <div className="status_radio">
            <div className="radio">
              <input
                type="radio"
                name="status"
                value="true"
                checked={formData.status === true}
                onChange={handleRadioChange}
              />
              <label className="status">Active</label>
            </div>
            <div className="radio">
              <input
                type="radio"
                name="status"
                value="false"
                checked={formData.status === false}
                onChange={handleRadioChange}
              />
              <label className="status">Inactive</label>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-window-action__modal__button">
        <button className="section-btn-gradient" onClick={handleSave}>
          Save
        </button>
      </div>
    </AdminModalWindow>
  );
};

export default StoreEditor;
