import React from 'react';

const TableHeadArrowButtomIcon: React.FC<{ className: string }> = ({ className }) => (
  <svg
    className={className}
    width="7"
    height="6"
    viewBox="0 0 7 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.93301 5.44995C3.74056 5.78328 3.25944 5.78328 3.06699 5.44995L0.468912 0.949951C0.276462 0.616618 0.517025 0.199952 0.901925 0.199952L6.09808 0.199952C6.48298 0.199952 6.72354 0.616619 6.53109 0.949952L3.93301 5.44995Z"
      fill="#19151E"
    />
  </svg>
);

export default TableHeadArrowButtomIcon;
