import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const reasons = [
  'Product Inquiry',
  'Purchase Inquiry',
  'Consultation',
  'Technical Support',
  'Repair and Maintenance',
  'Hosting Services',
  'Mining Pool Information',
  'Partnership Opportunities',
  'General Inquiries',
] as const;

export type ReasonType = (typeof reasons)[number];

export interface GetInTouchState {
  reason: ReasonType | '';
  isOpened: boolean;
  isModal: boolean;
}

const initialState: GetInTouchState = {
  reason: '',
  isOpened: false,
  isModal: false,
};

const GetInTouchSlicer = createSlice({
  name: 'getInTouch',
  initialState,
  reducers: {
    setReason: (state, action: PayloadAction<ReasonType | ''>) => {
      state.reason = action.payload;
    },
    setIsOpened: (state, action: PayloadAction<boolean>) => {
      state.isOpened = action.payload;
    },
    setIsModal: (state, action: PayloadAction<boolean>) => {
      state.isModal = action.payload;
    },
  },
});

export const { setReason, setIsOpened, setIsModal } = GetInTouchSlicer.actions;
export default GetInTouchSlicer.reducer;
