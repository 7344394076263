import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AdminPanel {
  isOpenedNav: boolean;
}

const initialState: AdminPanel = {
  isOpenedNav: false,
};

const AdminPanelSlice = createSlice({
  name: 'a4',
  initialState,
  reducers: {
    setNav: (state, action: PayloadAction<boolean>) => {
      state.isOpenedNav = action.payload;
    },
  },
});

export const { setNav } = AdminPanelSlice.actions;
export default AdminPanelSlice.reducer;
