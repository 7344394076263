import './AdminEmail.scss';

import React, { useState } from 'react';

import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import createAdminsService from '../../../../general/services/admins';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import { regexp } from '../../../../general/utils/Validations';
import AdminModalWindow from '../../../components/modal-window/ModalWindow';

interface ModalAdminEmailProps {
  isEdit?: boolean;
  isActive: (isActive: boolean) => void;
  email: any;
  refreshMethod: () => void;
}

const ModalAdminEmail: React.FC<ModalAdminEmailProps> = ({
  isEdit = false,
  isActive,
  email,
  refreshMethod,
}) => {
  const [isPreloader, setIsPreloader] = useState(false);

  const { mutate: putAdminEmail } = useCustomMutation(
    createAdminsService(adminInstance).putAdminEmail,
  );

  const { mutate: postAdminEmail } = useCustomMutation(
    createAdminsService(adminInstance).postAdminEmail,
  );

  const updateEmail = async () => {
    setIsPreloader(true);

    const inputBlock = document.querySelector(
      '.modal-window-action__modal__admin-email__text',
    ) as HTMLElement;
    const input = inputBlock.querySelector('input') as HTMLInputElement;

    if (!regexp.email_regexp.test(input.value)) {
      input.style.border = '1px solid red';
      setIsPreloader(false);
      return;
    }

    const isCheckboxBlock = document.querySelector(
      '.modal-window-action__modal__admin-email__checkboxs',
    ) as HTMLElement;
    const isNotificationBtn = isCheckboxBlock.querySelectorAll('input[name=notification]:checked');

    const notification = Array.from(isNotificationBtn).map(
      (input) => (input as HTMLInputElement).value,
    );

    if (email && email.id) {
      putAdminEmail({
        id: email.id,
        email: input.value,
        orderNotification: notification.includes('Orders'),
        contactMessageNotification: notification.includes('Contact'),
      });
    } else {
      console.error('Email or Email ID is undefined');
    }

    refreshMethod();
    isActive(false);
    setIsPreloader(false);
  };

  const addEmail = async () => {
    setIsPreloader(true);

    const inputBlock = document.querySelector(
      '.modal-window-action__modal__admin-email__text',
    ) as HTMLElement;
    const input = inputBlock.querySelector('input') as HTMLInputElement;

    if (!regexp.email_regexp.test(input.value)) {
      input.style.border = '1px solid red';
      setIsPreloader(false);
      return;
    }

    const isCheckboxBlock = document.querySelector(
      '.modal-window-action__modal__admin-email__checkboxs',
    ) as HTMLElement;
    const isNotificationBtn = isCheckboxBlock.querySelectorAll('input[name=notification]:checked');

    const notification = Array.from(isNotificationBtn).map(
      (input) => (input as HTMLInputElement).value,
    );

    postAdminEmail({
      email: input.value,
      orderNotification: notification.includes('Orders'),
      contactMessageNotification: notification.includes('Contact'),
    });

    refreshMethod();
    isActive(false);
    setIsPreloader(false);
  };
  return (
    <AdminModalWindow isOpen={isActive} title="Email" mWidth="600">
      {isPreloader && (
        <div className="modal-window-action__modal__home__loading">
          <Preloader />
        </div>
      )}
      <div className="modal-window-action__modal__admin-email">
        <div className="modal-window-action__modal__admin-email__text">
          <input type="email" placeholder="email" defaultValue={email?.email} />
        </div>
        <div className="modal-window-action__modal__admin-email__checkboxs">
          <div className="modal-window-action__modal__admin-email__checkbox">
            <input
              type="checkbox"
              id="orders_notification"
              name="notification"
              value="Orders"
              defaultChecked={email?.orderNotification}
            />
            <label htmlFor="orders_notification">Order notification</label>
          </div>
          <div className="modal-window-action__modal__admin-email__checkbox">
            <input
              type="checkbox"
              id="contact_notification"
              name="notification"
              value="Contact"
              defaultChecked={email?.contactMessageNotification}
            />
            <label htmlFor="contact_notification">Contact message notification</label>
          </div>
        </div>
      </div>
      <div className="modal-window-action__modal__button">
        <button className="section-btn-gradient" onClick={isEdit ? updateEmail : addEmail}>
          {isEdit ? 'Save' : 'Add'}
        </button>
      </div>
    </AdminModalWindow>
  );
};

export default ModalAdminEmail;
