import './AdminProductList.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ExportIcon from '../../../../general/assets/svg/ExportIcon';
import ImportIcon from '../../../../general/assets/svg/ImportIcon';
import PlusIcon from '../../../../general/assets/svg/PlusIcon';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import usePagination from '../../../../general/hooks/usePagination';
import { useResize } from '../../../../general/hooks/useResize';
import { queryKeys } from '../../../../general/queryKeys';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createProductsService from '../../../../general/services/products';
import { PaggingObj, ProductMax } from '../../../../general/types/types';
import AdminSearch from '../../../components/search/Search';
import CustomPagination from '../../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../../components/tables/custom-table/CustomTable';
import ActionButtons from './ActionButton';
import Export from './Export';
import Import from './Import';

interface Product {
  id: string;
  image: string;
  name: string;
  status: string;
  price: number;
  count: number;
}

function AdminProductList() {
  const navigate = useNavigate();
  const size = useResize();
  const { page, pageSize, searchQuery, setPage, setPageSize, setSearchQuery } = usePagination();

  const {
    data: products,
    isLoading: productsLoading,
    refetch: getData,
  } = useCustomQuery<PaggingObj<ProductMax[]>>(queryKeys.PRODUCTS_ADMIN, () =>
    createProductsService(adminInstance).getAllProducts(searchQuery, pageSize, page),
  );

  const { mutate: deleteProduct } = useCustomMutation(
    createProductsService(adminInstance).deleteProduct,
    undefined,
    {
      onSuccess() {
        getData();
      },
    },
  );

  const actionButtons = (item: Product, deleteMethod?: (id: string) => void) => (
    <ActionButtons item={item} deleteMethod={deleteMethod} />
  );

  useEffect(() => {
    getData();
  }, [searchQuery, page, pageSize, getData]);

  const goCreateProduct = () => {
    navigate('/admin/products/product');
  };

  const deleteItem = async (id: string) => {
    deleteProduct(id);
  };

  const [isImport, setIsImport] = useState<boolean>(false);
  const [isExport, setIsExport] = useState<boolean>(false);

  return (
    <>
      {isImport && <Import isActive={setIsImport} getData={getData} />}
      {isExport && <Export isActive={setIsExport} />}
      <div className="page-section__content">
        <div className="page-section__buttons">
          <button className="import-export-button" onClick={() => setIsImport(true)}>
            <ImportIcon />
            <span>Import</span>
          </button>
          <button className="import-export-button" onClick={() => setIsExport(true)}>
            <ExportIcon />
            <span>Export</span>
          </button>
          <button onClick={goCreateProduct}>
            <PlusIcon stroke="white" />
            <span>Add product</span>
          </button>
        </div>
        <div>
          <AdminSearch
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            placeholder={'Search by name'}
          />
        </div>
        <div className="page-section__content__list-section">
          <div className="list-section__content">
            {productsLoading && (
              <div className="page-update-overlay">
                <Preloader />
              </div>
            )}
            <CustomTable
              data={products ? products.items : []}
              headers={['image', 'name', 'status', 'price', 'count']}
              deleteMethod={deleteItem}
              isAction={true}
              actionComponent={actionButtons}
              isMobile={size.width < 1100}
            />
          </div>
        </div>
        {products && products?.pageSize < products?.totalCount && (
          <CustomPagination
            currentPage={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            isNextPage={products.hasNextPage}
            isPrevPage={products.hasPreviousPage}
            total={products.totalCount}
          />
        )}
      </div>
    </>
  );
}

export default AdminProductList;
