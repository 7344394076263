import React from 'react';

const CloseSmall: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_566_578)">
      <path
        d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75Z"
        stroke="#315486"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M6.62354 6.62378L13.3764 13.3766"
        stroke="#315486"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M13.3765 6.62378L6.6236 13.3766"
        stroke="#315486"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_566_578">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CloseSmall;
