import React from 'react';

const SadSmileIcon: React.FC = () => (
  <svg
    width="128"
    height="129"
    viewBox="0 0 128 129"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M85.3327 85.9333C85.3327 85.9333 77.3327 75.2666 63.9993 75.2666C50.666 75.2666 42.666 85.9333 42.666 85.9333"
      stroke="#25A8CF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64 112.6C90.5099 112.6 112 91.11 112 64.6001C112 38.0904 90.5099 16.6001 64 16.6001C37.4903 16.6001 16 38.0904 16 64.6001C16 91.11 37.4903 112.6 64 112.6Z"
      stroke="#25A8CF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M80 48.6001H80.0533"
      stroke="#25A8CF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48 48.6001H48.0533"
      stroke="#25A8CF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SadSmileIcon;
