import './AddToCart.scss';

import { CustomContentProps, SnackbarContent } from 'notistack';
import React from 'react';

import { routesClient } from '../../../../general/routes';

const AddItemToCart = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
  return (
    <SnackbarContent ref={ref} className="modal-notification">
      <svg
        width="22"
        height="23"
        viewBox="0 0 22 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 21.8472C12.3135 21.8489 13.6143 21.5911 14.8278 21.0884C16.0413 20.5857 17.1435 19.8482 18.071 18.9182C19.001 17.9907 19.7385 16.8885 20.2412 15.675C20.7438 14.4616 21.0017 13.1607 21 11.8472C21.0017 10.5338 20.7438 9.23293 20.2411 8.01945C19.7385 6.80597 19.001 5.70379 18.071 4.77624C17.1435 3.84625 16.0413 3.10874 14.8278 2.60608C13.6143 2.10342 12.3135 1.84554 11 1.84724C9.68655 1.84556 8.3857 2.10346 7.17222 2.60612C5.95875 3.10877 4.85656 3.84627 3.92901 4.77624C2.99904 5.70379 2.26154 6.80597 1.75889 8.01945C1.25623 9.23293 0.998334 10.5338 1.00001 11.8472C0.998307 13.1607 1.25619 14.4616 1.75885 15.675C2.26151 16.8885 2.99902 17.9907 3.92901 18.9182C4.85656 19.8482 5.95875 20.5857 7.17222 21.0884C8.3857 21.591 9.68655 21.8489 11 21.8472Z"
          stroke="#25A8CF"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M7 11.8472L10 14.8472L16 8.84723"
          stroke="#25A8CF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span>
        Item added to{' '}
        <a href={routesClient.cart.path} className="rainbow-link" target="blank">
          cart
        </a>
      </span>
    </SnackbarContent>
  );
});

export default AddItemToCart;
