import React from 'react';

const DeleteIconRound: React.FC = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#746A81" />
    <path
      d="M16.8465 20.1249H11.1532C10.8691 20.1253 10.5957 20.0166 10.3894 19.8212C10.1832 19.6258 10.0598 19.3587 10.0449 19.0749L9.54321 10.1033H18.4565L17.9549 19.0749C17.9399 19.3587 17.8166 19.6258 17.6103 19.8212C17.4041 20.0166 17.1307 20.1253 16.8465 20.1249Z"
      stroke="#746A81"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path d="M8.4292 10.1033H19.5709" stroke="#746A81" strokeWidth="1.2" strokeMiterlimit="10" />
    <path
      d="M12.8859 7.875H15.1142C15.4097 7.875 15.6931 7.99239 15.9021 8.20133C16.111 8.41028 16.2284 8.69367 16.2284 8.98917V10.1033H11.7717V8.98917C11.7717 8.69367 11.8891 8.41028 12.0981 8.20133C12.307 7.99239 12.5904 7.875 12.8859 7.875Z"
      stroke="#746A81"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path d="M14 11.7717V18.4567" stroke="#746A81" strokeWidth="1.2" strokeMiterlimit="10" />
    <path d="M16.2283 11.7717V18.4567" stroke="#746A81" strokeWidth="1.2" strokeMiterlimit="10" />
    <path d="M11.7717 11.7717V18.4567" stroke="#746A81" strokeWidth="1.2" strokeMiterlimit="10" />
  </svg>
);

export default DeleteIconRound;
