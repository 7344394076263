import React from 'react';

const PrevIcon: React.FC = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.25 21H29.75"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.25 14L12.25 21L19.25 28"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 36.75C29.6986 36.75 36.75 29.6986 36.75 21C36.75 12.3015 29.6986 5.25 21 5.25C12.3015 5.25 5.25 12.3015 5.25 21C5.25 29.6986 12.3015 36.75 21 36.75Z"
      stroke="#6E7EA8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PrevIcon;
