import React from 'react';

const ErrorIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.13 3.40991H11.87C11.6177 3.40953 11.3695 3.47365 11.149 3.59616C10.9285 3.71867 10.743 3.89552 10.61 4.10991L1.73 18.3199C1.5802 18.5562 1.50045 18.8301 1.5 19.1099C1.49998 19.306 1.53892 19.5001 1.61454 19.6809C1.69016 19.8618 1.80097 20.0259 1.94053 20.1636C2.08009 20.3013 2.24561 20.4099 2.42748 20.483C2.60936 20.5562 2.80397 20.5926 3 20.5899H21C21.3925 20.5899 21.769 20.434 22.0465 20.1564C22.3241 19.8789 22.48 19.5024 22.48 19.1099C22.4795 18.8301 22.3998 18.5562 22.25 18.3199L13.39 4.10991C13.257 3.89552 13.0715 3.71867 12.851 3.59616C12.6305 3.47365 12.3823 3.40953 12.13 3.40991Z"
      stroke="#DB6484"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M12 8.17993V13.9099" stroke="#DB6484" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M12 15.8201V17.7301" stroke="#DB6484" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);

export default ErrorIcon;
