import React from 'react';

import { Theme, themes } from '../../contexts/ThemeContext';

interface CompareIconProps {
  isUser: string;
  theme: Theme;
}

const CompareIcon: React.FC<CompareIconProps> = ({ isUser, theme }) => (
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.5"
      y="0.693848"
      width="39"
      height="39"
      rx="19.5"
      fill={isUser !== '' ? (theme === themes.light ? 'black' : '#25A8CF') : ''}
      stroke={
        isUser !== ''
          ? theme === themes.light
            ? 'black'
            : '#25A8CF'
          : theme === themes.light
            ? 'black'
            : 'white'
      }
    />
    <g clipPath="url(#clip0_1790_3657)">
      <path
        stroke={
          isUser !== ''
            ? theme === themes.light
              ? 'white'
              : 'black'
            : theme === themes.light
              ? 'black'
              : 'white'
        }
        d="M9.4602 21.1338H16.1702V21.8638C16.1702 22.6886 15.8425 23.4797 15.2593 24.0629C14.6761 24.6461 13.885 24.9738 13.0602 24.9738H12.5602C11.7354 24.9738 10.9443 24.6461 10.3611 24.0629C9.77786 23.4797 9.4502 22.6886 9.4502 21.8638V21.1338H9.4602Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        stroke={
          isUser !== ''
            ? theme === themes.light
              ? 'white'
              : 'black'
            : theme === themes.light
              ? 'black'
              : 'white'
        }
        d="M8.5 13.4639H13.92C14.3495 13.4605 14.7738 13.3701 15.1673 13.1982C15.5609 13.0264 15.9156 12.7765 16.21 12.4639C16.5065 12.1539 16.8616 11.9059 17.2547 11.7342C17.6478 11.5626 18.0711 11.4707 18.5 11.4639H21.5C21.932 11.4697 22.3586 11.561 22.7551 11.7327C23.1516 11.9043 23.5101 12.1528 23.81 12.4639C24.1111 12.7761 24.4712 13.0253 24.8695 13.197C25.2678 13.3686 25.6963 13.4594 26.13 13.4639H31.5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        stroke={
          isUser !== ''
            ? theme === themes.light
              ? 'white'
              : 'black'
            : theme === themes.light
              ? 'black'
              : 'white'
        }
        d="M20 8.67383V30.7138"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        stroke={
          isUser !== ''
            ? theme === themes.light
              ? 'white'
              : 'black'
            : theme === themes.light
              ? 'black'
              : 'white'
        }
        d="M25.75 30.7139H14.25"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        stroke={
          isUser !== ''
            ? theme === themes.light
              ? 'white'
              : 'black'
            : theme === themes.light
              ? 'black'
              : 'white'
        }
        d="M13.29 13.4639H12.33L9.45996 20.1739V21.1339H16.17V20.1739L13.29 13.4639Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        stroke={
          isUser !== ''
            ? theme === themes.light
              ? 'white'
              : 'black'
            : theme === themes.light
              ? 'black'
              : 'white'
        }
        d="M23.8303 21.1338H30.5403V21.8638C30.5403 22.6886 30.2127 23.4797 29.6294 24.0629C29.0462 24.6461 28.2551 24.9738 27.4303 24.9738H26.9303C26.1055 24.9738 25.3144 24.6461 24.7312 24.0629C24.148 23.4797 23.8203 22.6886 23.8203 21.8638V21.1338H23.8303Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        stroke={
          isUser !== ''
            ? theme === themes.light
              ? 'white'
              : 'black'
            : theme === themes.light
              ? 'black'
              : 'white'
        }
        d="M27.6701 13.4639H26.7101L23.8301 20.1739V21.1339H30.5401V20.1739L27.6701 13.4639Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_1790_3657">
        <rect width="24" height="24" fill="white" transform="translate(8 8.19385)" />
      </clipPath>
    </defs>
  </svg>
);

export default CompareIcon;
