import React, { ReactNode } from 'react';
import './TemplateModalWindow.scss';

interface TemplateModalWindowProps {
  isOpen: (isOpen: boolean) => void;
  messageClass: string;
  children: ReactNode;
}

const TemplateModalWindow: React.FC<TemplateModalWindowProps> = ({
  isOpen,
  messageClass,
  children,
}) => {
  const closeModal = () => {
    isOpen(false);
  };

  return (
    <>
      <div className="overlay" onClick={closeModal}></div>
      <div className="message-modal-window ">
        <div className={messageClass || 'message-modal-window__content'}>{children}</div>
      </div>
    </>
  );
};

export default TemplateModalWindow;
