import './AdminSubscriberList.scss';

import React, { useEffect } from 'react';

import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import usePagination from '../../../../general/hooks/usePagination';
import { queryKeys } from '../../../../general/queryKeys';
import createClientService from '../../../../general/services/client';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import { PaggingObj, Subscriber } from '../../../../general/types/types';
import CustomPagination from '../../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../../components/tables/custom-table/CustomTable';

const AdminSubscriberList: React.FC = () => {
  const { page, pageSize, setPage, setPageSize } = usePagination();

  const {
    data: subscribers,
    isLoading: subscribersLoading,
    refetch: getData,
  } = useCustomQuery<PaggingObj<Subscriber>>(queryKeys.ADMIN_SUBSCRIBERS, () =>
    createClientService(adminInstance).getSubscribers(pageSize, page),
  );

  useEffect(() => {
    getData();
  }, [page, pageSize, getData]);

  return (
    <div className="page-section__content">
      <div className="page-section__content__list-section">
        <div className="page-section__buttons"></div>
        <div className="list-section__content">
          {subscribersLoading && (
            <div className="page-update-overlay">
              <Preloader />
            </div>
          )}
          <CustomTable
            data={subscribers ? subscribers?.items : []}
            headers={['email']}
            isAction={false}
          />
        </div>
      </div>
      {subscribers && subscribers?.pageSize < subscribers?.totalCount && (
        <CustomPagination
          currentPage={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          isNextPage={subscribers.hasNextPage}
          isPrevPage={subscribers.hasPreviousPage}
          total={subscribers.totalCount}
        />
      )}
    </div>
  );
};

export default AdminSubscriberList;
