import React from 'react';

const WhatsUpFooterIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 1.50001C10.7743 1.50116 9.07822 1.94889 7.57678 2.79965C6.07533 3.65041 4.81961 4.87525 3.93173 6.35505C3.04386 7.83484 2.55404 9.51923 2.50993 11.2444C2.46582 12.9696 2.86891 14.6768 3.68 16.2L2.5 21.5L7.8 20.32C9.1411 21.0365 10.6279 21.4378 12.1474 21.4933C13.6669 21.5488 15.1791 21.2571 16.5689 20.6403C17.9587 20.0235 19.1896 19.0979 20.1678 17.9339C21.1461 16.7699 21.8461 15.3981 22.2145 13.9229C22.5829 12.4477 22.6101 10.9079 22.2939 9.42064C21.9777 7.93335 21.3266 6.53776 20.3899 5.33998C19.4533 4.1422 18.2558 3.17378 16.8886 2.50838C15.5214 1.84298 14.0205 1.49811 12.5 1.50001Z"
      stroke="white"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M14.88 12.93L17.74 15.79C17.74 15.79 13.93 19.6 9.17003 14.79C4.41003 9.97997 8.17003 6.21997 8.17003 6.21997L11.03 9.07997L10.98 9.17997C10.7153 9.7052 10.624 10.3008 10.7193 10.8812C10.8145 11.4616 11.0914 11.9969 11.51 12.41C11.9231 12.8286 12.4584 13.1055 13.0388 13.2007C13.6192 13.296 14.2148 13.2047 14.74 12.94L14.88 12.93Z"
      stroke="white"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
  </svg>
);

export default WhatsUpFooterIcon;
