import './Erorr.scss';

import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CloseModalCrossIconWhite from '../../general/assets/svg/CloseModalCrossIconWhite';
import ErrorIcon from '../../general/assets/svg/ErrorIcon';
import SuccessIcon from '../../general/assets/svg/SuccessIcon';
import WarnigIcon from '../../general/assets/svg/WarnigIcon';
import useKey, { keys } from '../../general/hooks/useKey';
import { logOutAdmin } from '../../general/redux/reducers/Admin-Reducer';
import { clearInfo, types } from '../../general/redux/reducers/Error-Reducer';
import { RootState } from '../../general/redux/store';

const errors = {
  NETWORK_ERROR: 'Network Error',
  TOKEN_ERROR: 'Ошибка обновления токена',
};

export const ifServerError = (response: any, dispatchCallback: (funk: any) => void): boolean => {
  if (!response) {
    return false;
  }

  // Проверка, является ли объектом ошибки по наличию свойства message
  const isErrorInstance = response && typeof response === 'object' && 'message' in response;

  // Проверка успешности ответа
  const isNotSuccess =
    (isErrorInstance &&
      response.message &&
      typeof response.message === 'string' &&
      (response.message !== errors.NETWORK_ERROR || response.message !== errors.TOKEN_ERROR)) ||
    (!isErrorInstance &&
      response.status &&
      typeof response.status === 'number' &&
      response.status !== 200 &&
      response.status !== 201);

  return isNotSuccess;
};

const CustomErorr: React.FC<{ root: string }> = ({ root }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const error = useSelector((state: RootState) => state.e6.e);
  const errorType = useSelector((state: RootState) => state.e6.type);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(error.n && error.n !== '' ? true : false);
  }, [error]);

  useKey((event) => {
    if (event.key === keys.ESCAPE) {
      isOpen && setIsOpen(false);
      closeModal();
    }
  });

  const closeModal = () => {
    if (typeof error.r !== 'string' && error.r.s[0] === 'Unauthorized') {
      dispatch(logOutAdmin());
      navigate(root);
    }
    dispatch(clearInfo());
    setIsOpen(false);
  };

  const highlightText = (text: string) => {
    const regex =
      /(\d+\.)|(\bZIP\b)|(\bPhone\b)|(\bEmail\b)|(\bStreet 1\b)|(\bStreet 2\b)|(\bStreet 3\b)|(\bStreet number\b)|(\bCity\b)|(\bState\b)|(\bCountry\b)|(\bCompany\b)|(\bLast name\b)|(\bName\b)/gi;
    const parts = text.split(regex);

    const highlightColor =
      errorType === types.ERROR ? '#DB6484' : errorType === types.WARNING ? '#DB8F64' : '#25A8CF';

    return parts.map((part, index) => {
      if (regex.test(part)) {
        return (
          <span key={index} style={{ color: highlightColor }}>
            {part}
          </span>
        );
      }
      return part;
    });
  };

  return (
    <>
      {error.n && isOpen && (
        <div className="error-modal-window">
          <div className="error-modal-window__overlay" onClick={closeModal} />
          <div className="error-modal-window__content">
            <button className="error-modal-window__content__close-btn" onClick={closeModal}>
              <CloseModalCrossIconWhite />
            </button>
            <div className="error-modal-window__content__icon">
              {errorType === types.ERROR ? (
                <ErrorIcon />
              ) : errorType === types.WARNING ? (
                <WarnigIcon />
              ) : (
                <SuccessIcon />
              )}
            </div>
            <div
              style={{
                color:
                  errorType === types.ERROR
                    ? '#DB6484'
                    : errorType === types.WARNING
                      ? '#DB8F64'
                      : '#25A8CF',
              }}
              className="error-modal-window__content__title"
            >
              {errorType === types.ERROR
                ? types.ERROR.toUpperCase()
                : errorType === types.WARNING
                  ? types.WARNING.toUpperCase()
                  : types.SUCCESS.toUpperCase()}
            </div>
            <div id="text_error" className="error-modal-window__content__text">
              {typeof error.r === 'string' && error.r !== '' ? (
                'No connection to server'
              ) : (
                <>
                  {error.r && typeof error.r !== 'string' && error.r.d && error.r.d.detail ? (
                    error.r.d.detail
                  ) : (
                    <>
                      {error.r &&
                        typeof error.r !== 'string' &&
                        error.r.s.map((line: string, index: number) => (
                          <Fragment key={index}>
                            {highlightText(line)}
                            <br />
                          </Fragment>
                        ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomErorr;
