import './WishlistBtn.scss';

import React, { MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WishListProdIcon from '../../../../general/assets/svg/WishListProdIcon';
import { clientWishlist } from '../../../../general/redux/reducers/ClientProfile-Reducer';
import { RootState } from '../../../../general/redux/store';
import createClientService from '../../../../general/services/client';
import { clientInstance } from '../../../../general/services/main/axiosInstances';

interface WishlistBtnProps {
  setIsAuthModalOpen: (isOpen: boolean) => void;
  productId: string;
}

const WishlistBtn: React.FC<WishlistBtnProps> = ({ setIsAuthModalOpen, productId }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.clps.cp2);

  const userWishList = useSelector((state: RootState) => state.clps.wl);

  const [isWishList, setIsWishList] = useState(
    userWishList === undefined
      ? false
      : Array.from(userWishList)?.findIndex((item: any) => item.id === productId) !== -1,
  );

  const updateClientWishList = async () => {
    const response = await createClientService(clientInstance).getClienWishList(userId);
    dispatch(clientWishlist(response.data));
  };

  const addToWishList = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (userId === '') {
      setIsAuthModalOpen(true);
      return;
    } else {
      await createClientService(clientInstance).postClienWishList({
        userId: userId,
        productId: productId,
      });
      updateClientWishList();
      setIsWishList(true);
    }
  };

  const removeFromWishlist = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (userId === '') {
      setIsAuthModalOpen(true);
      return;
    } else {
      await createClientService(clientInstance).removeFromClientWishlist({
        userId: userId,
        productId: productId,
      });
      updateClientWishList();
      setIsWishList(false);
    }
  };

  return (
    <div className="action-favorite">
      <button onClick={!isWishList ? addToWishList : removeFromWishlist}>
        <WishListProdIcon isWishList={isWishList} />
      </button>
    </div>
  );
};

export default WishlistBtn;
