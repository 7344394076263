import './AboutUsCarousel.scss';

import React, { ChangeEvent, useRef, useState } from 'react';

import AddIcon from '../../../../general/assets/svg/AddIcon';
import ThumbsUpIcon from '../../../../general/assets/svg/ThumbsUpIcon';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import createAboutUsService from '../../../../general/services/aboutUs';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import { AboutUsImg } from '../../../../general/types/types';
import Confirm from '../../../components/confirm/Confirm';

const AboutUsCarousel: React.FC = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [idToDelete, setIDToDelete] = useState<string | null>(null);
  const [deleteArticleAsk, setDeleteArticleAsk] = useState(false);

  const handleClickBrowser = () => {
    fileInputRef.current?.click();
  };

  const { mutate: createImg } = useCustomMutation(
    createAboutUsService(adminInstance).createImage,
    undefined,
    {
      onSuccess: () => {
        getImages();
      },
    },
  );

  const { mutate: deleteImg } = useCustomMutation(
    createAboutUsService(adminInstance).deleteImage,
    undefined,
    {
      onSuccess: () => {
        getImages();
      },
    },
  );

  const {
    data: images,
    isLoading: imagesLoading,
    refetch: getImages,
  } = useCustomQuery<AboutUsImg[]>(queryKeys.ABOUT_US_PICTURES, () =>
    createAboutUsService(adminInstance).getAllSliderImages(),
  );

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        createImg(file);
      };
      reader.readAsDataURL(file);
    }
    event.target.value = '';
  };

  const deleteImage = () => {
    if (idToDelete) {
      deleteImg(idToDelete);
    }
  };
  return (
    <>
      <div className="home-container__header">
        <div className="home-container__header__icon-name">
          <ThumbsUpIcon />
          <span>About us pictures</span>
        </div>
        <button className="home-container__header__button" onClick={handleClickBrowser}>
          <AddIcon color="#25A8CF" />
        </button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleImageChange}
        />
      </div>
      <div className="home-container__about-us-images">
        {imagesLoading ? (
          <div className="home-container__about-us-images__preloader">
            <Preloader />
          </div>
        ) : (
          <>
            {!images || images?.length === 0 ? (
              <div className="home-container__about-us-images__empty">No images</div>
            ) : (
              <div className="pictures-container">
                {images?.map((img, index) => (
                  <div
                    onClick={() => {
                      setIDToDelete(images[index]?.id);
                      setDeleteArticleAsk(true);
                    }}
                    key={index}
                    style={{
                      backgroundImage: `url(${img?.url})`,
                    }}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
      {deleteArticleAsk && (
        <Confirm
          title="Delete confirm"
          text={'Are you shure want to delete this picture?'}
          handler={setDeleteArticleAsk}
          clickEvent={() => {
            deleteImage();
          }}
        />
      )}
    </>
  );
};

export default AboutUsCarousel;
