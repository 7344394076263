import React from 'react';

const AddIcon: React.FC<{ color: string }> = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 12H24" stroke={color} strokeWidth="2" strokeMiterlimit="10" />
    <path d="M12 0V24" stroke={color} strokeWidth="2" strokeMiterlimit="10" />
  </svg>
);

export default AddIcon;
