import './ContactForm.scss';

import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Preloader, { preloaderStyles } from '../../../general/components/preloader/Preloader';
import { reasons, ReasonType, setReason } from '../../../general/redux/reducers/GetInTouch-Reducer';
import InputComponent from './InputComponent';

interface FormSectionProps {
  sendMessage: () => void;
  setDefaultInput: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  preloader: boolean;
}

const FormSection: React.FC<FormSectionProps> = ({ sendMessage, setDefaultInput, preloader }) => {
  const dispatch = useDispatch();
  const [reasonOpened, setReasonOpened] = useState(false);
  const [choosedReason, setChoosedReason] = useState<ReasonType | 'Reason for Contact'>(
    'Reason for Contact',
  );
  const isOpened = useSelector(
    (state: { getInTouch: { isOpened: boolean } }) => state.getInTouch.isOpened,
  );

  useEffect(() => {
    if (choosedReason !== 'Reason for Contact') {
      dispatch(setReason(choosedReason));
    }
  }, [choosedReason, dispatch]);

  const toggleReasonDropdown = () => setReasonOpened(!reasonOpened);

  const handleReasonSelect = (reason: ReasonType) => {
    setChoosedReason(reason);
    setReasonOpened(false);
  };
  return (
    <div
      id="contact-form"
      className={`send-message-section__content-form ${
        isOpened ? 'form-is-open' : 'form-is-close'
      }`}
    >
      <div className="content-form__inputs">
        <InputComponent placeholder="Name" onChange={setDefaultInput} />
        <InputComponent placeholder="Email" onChange={setDefaultInput} />
        <div className="reason-block">
          <InputComponent placeholder="Company Name" onChange={setDefaultInput} />
          <div className="drop-down_section">
            <button onClick={toggleReasonDropdown}>
              {choosedReason}
              <svg
                style={{
                  transform: reasonOpened ? 'rotateZ(0deg)' : 'rotateZ(180deg)',
                }}
                width="17"
                height="11"
                viewBox="0 0 17 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.99999 9.62L8.61999 2L16.24 9.62"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                ></path>
              </svg>
            </button>
            <div className={`drop-down__content ${reasonOpened ? 'show' : ''}`}>
              {reasons.map((reason, index) => (
                <div
                  key={index}
                  className={`drop-down__ellement ${reason === choosedReason ? 'selected' : ''}`}
                  onClick={() => handleReasonSelect(reason)}
                >
                  {reason}
                </div>
              ))}
            </div>
          </div>
        </div>
        <InputComponent type="number" placeholder="Contact Number" onChange={setDefaultInput} />
        <textarea placeholder="Message" onChange={setDefaultInput} />
      </div>
      <button className="content-form__btn section-btn-gradient" onClick={sendMessage}>
        {preloader ? <Preloader style={preloaderStyles.DARK} /> : 'Submit'}
      </button>
    </div>
  );
};

export default FormSection;
