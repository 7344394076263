import './ProductsSorting.scss';

import React, { useEffect, useRef, useState } from 'react';

import DropDownIcon from '../../../../general/assets/svg/DropDownIcon';

interface Option {
  name: string;
  sortColumn: string;
  sortOrder: string;
}

interface ProductsSortingProps {
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
}

const options: Option[] = [
  {
    name: 'Price: High to Low',
    sortColumn: 'price',
    sortOrder: 'desc',
  },
  {
    name: 'Price: Low to High',
    sortColumn: 'price',
    sortOrder: '',
  },
];

const ProductsSorting: React.FC<ProductsSortingProps> = ({ searchParams, setSearchParams }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [selectedOption, setSelectedOption] = useState<Option | null>(
    options.find(
      (item) =>
        item.sortColumn === searchParams?.get('sortColumn')?.split('-')[0] &&
        item.sortOrder === (searchParams?.get('sortColumn')?.split('-')[1] || ''),
    ) || null,
  );

  const handleOptionClick = (option: Option) => {
    const newParams = new URLSearchParams(searchParams.toString());
    const query = 'sortColumn';
    let params = searchParams.get(query);

    if (params) {
      if (params !== option.sortColumn || params !== `${option.sortColumn}-${option.sortOrder}`) {
        if (option.sortOrder !== '') {
          newParams.set(query, `${option.sortColumn}-${option.sortOrder}`);
        } else {
          newParams.set(query, option.sortColumn);
        }
      }

      if (option.sortColumn === undefined) {
        newParams.delete(query);
      }
    } else {
      if (option.sortOrder !== '') {
        newParams.set(query, `${option.sortColumn}-${option.sortOrder}`);
      } else {
        newParams.set(query, option.sortColumn);
      }
    }

    setSearchParams(newParams);
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    if (searchParams.size === 0) {
      const currentOptionsElement = document.querySelector(
        '.products-sorting__current-options__name',
      );
      if (currentOptionsElement) {
        currentOptionsElement.innerHTML = 'Select an option';
      }
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, searchParams]);

  return (
    <div className="products-sorting" ref={dropdownRef}>
      <div className="products-sorting-select" onClick={() => setIsOpen(!isOpen)}>
        <div className="products-sorting__current-options">
          <div className="products-sorting__current-options__name">
            {selectedOption?.name || 'Select an option'}
          </div>
          <DropDownIcon />
        </div>
      </div>
      {isOpen && (
        <div className="products-sorting__list-options">
          <ul>
            {options.map((option, index) => (
              <li
                key={index}
                className="products-sorting__list-options__option"
                onClick={() => handleOptionClick(option)}
                style={{
                  backgroundColor:
                    selectedOption === option
                      ? document.documentElement.dataset.theme === 'light'
                        ? '#D5DCFE'
                        : '#241C2D'
                      : '',
                }}
              >
                {option.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProductsSorting;
