import React from 'react';

const PhoneIcon: React.FC = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1254_984)">
      <path
        d="M20.5468 21.5699C18.0068 24.1199 12.1668 22.3999 7.54677 17.7299C2.92677 13.0599 1.10677 7.21989 3.65677 4.67989L6.43677 1.88989L11.8068 7.25989L9.80677 9.25989C9.40492 9.66775 9.17967 10.2173 9.17967 10.7899C9.17967 11.3625 9.40492 11.912 9.80677 12.3199L12.9068 15.4199C13.3151 15.8248 13.8668 16.0519 14.4418 16.0519C15.0168 16.0519 15.5685 15.8248 15.9768 15.4199L17.9768 13.4199L23.3468 18.7899L20.5468 21.5699Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_1254_984">
        <rect width="24" height="24" fill="white" transform="translate(0.906738 0.319824)" />
      </clipPath>
    </defs>
  </svg>
);

export default PhoneIcon;
