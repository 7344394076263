import './EmailAuthorization.scss';

import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';

import ErrorFieldAttentionIcon from '../../../../general/assets/svg/ErrorFieldAttentionIcon';
import PasswordVisibilityIcon from '../../../../general/assets/svg/PasswordVisibilityIcon';

interface RegistrationProps {
  timeLeft: number;
  setTimeLeft: React.Dispatch<React.SetStateAction<number>>;
  isRunning: boolean;
  setIsRunning: React.Dispatch<React.SetStateAction<boolean>>;
  isGetCode: boolean;
  getVerificationCode: () => void;
  emailError: { status: boolean; message: string };
  codeError: { status: boolean; message: string };
  passwordError: { status: boolean; message: string };
  defaultEmailInput: (e: ChangeEvent<HTMLInputElement>) => void;
  defaultCodeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  defaultPasswordInput: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Registration: React.FC<RegistrationProps> = ({
  timeLeft,
  setTimeLeft,
  isRunning,
  setIsRunning,
  isGetCode,
  getVerificationCode,
  emailError,
  codeError,
  passwordError,
  defaultEmailInput,
  defaultCodeInput,
  defaultPasswordInput,
}) => {
  useEffect(() => {
    if (isRunning && timeLeft > 0) {
      const interval = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else if (timeLeft === 0) {
      setIsRunning(false);
    }
  }, [setIsRunning, setTimeLeft, isRunning, timeLeft]);

  const [disabledBtn] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = (e: MouseEvent<HTMLButtonElement>) => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="email-authorization-section__sing-up">
      <div>
        <input type="email" placeholder="Please enter email" onChange={defaultEmailInput} />
        {emailError.status && (
          <span className="inputs-block__email-number__error">
            <ErrorFieldAttentionIcon />
            {emailError.message}
          </span>
        )}
      </div>
      {isGetCode && (
        <>
          <div>
            <div className="email-authorization__inputs-block__email-code">
              <input
                type="number"
                className="input-email-verification-code"
                placeholder="Enter auth code"
                onChange={defaultCodeInput}
              />
              {isRunning ? (
                <span className="send-verification-code__timer">
                  {Math.floor(timeLeft / 60)}:{('0' + (timeLeft % 60)).slice(-2)}
                </span>
              ) : (
                <button
                  className={
                    disabledBtn ? 'send-verification-code-disabled' : 'send-verification-code'
                  }
                  onClick={getVerificationCode}
                  disabled={disabledBtn}
                >
                  Get auth code
                </button>
              )}
            </div>
            {codeError.status && (
              <span className="inputs-block__email-number__error">
                <ErrorFieldAttentionIcon />
                {codeError.message}.
              </span>
            )}
          </div>
          <div style={{ position: 'relative' }}>
            <input
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder="Please enter password"
              onChange={defaultPasswordInput}
            />
            <button
              className={`show-hide-password ${isPasswordVisible ? 'active-password' : ''}`}
              onClick={togglePasswordVisibility}
              type="button"
            >
              <PasswordVisibilityIcon />
            </button>
            {passwordError.status && (
              <span className="inputs-block__email-number__error">
                <ErrorFieldAttentionIcon />
                {passwordError.message}
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Registration;
