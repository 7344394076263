import { AxiosInstance } from 'axios';

interface LogsService {
  getAllLogs: (query: string, pageSize: number, page: number) => Promise<any>;
}

const createLogsService = (instance: AxiosInstance): LogsService => ({
  async getAllLogs(query: string, pageSize: number, page: number) {
    try {
      const response = await instance.get(
        `/Logging/adminAll?SearchQuery=${encodeURIComponent(
          query,
        )}&PageSize=${pageSize}&Page=${page}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createLogsService;
