import React from 'react';

const LockIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.40991 7.22998H20.5899V19.23C20.5899 20.0866 20.2496 20.9082 19.6439 21.5139C19.0381 22.1197 18.2166 22.46 17.3599 22.46H6.63991C5.78326 22.46 4.9617 22.1197 4.35596 21.5139C3.75021 20.9082 3.40991 20.0866 3.40991 19.23V7.22998Z"
      fill="#25A8CF"
    />
    <path
      d="M8.17993 10.09V5.32C8.17993 4.30687 8.58239 3.33524 9.29878 2.61885C10.0152 1.90246 10.9868 1.5 11.9999 1.5C13.0131 1.5 13.9847 1.90246 14.7011 2.61885C15.4175 3.33524 15.8199 4.30687 15.8199 5.32V10.09"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default LockIcon;
