import { AxiosInstance } from 'axios';

interface contactMessagesService {
  postMessage: (body: {
    companyName: string;
    email: string;
    message: string;
    name: string;
    phoneNumber: string;
    reason: string;
  }) => Promise<any>;
  postSimpleMessage: (data: any) => Promise<any>;
}

const createContactMessagesService = (instance: AxiosInstance): contactMessagesService => ({
  async postMessage(body: {
    companyName: string;
    email: string;
    message: string;
    name: string;
    phoneNumber: string;
    reason: string;
  }) {
    try {
      const response = await instance.post(`/ContactMessage`, body);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async postSimpleMessage(body: {
    companyName: string;
    email: string;
    message: string;
    name: string;
    phoneNumber: string;
  }) {
    try {
      const response = await instance.post(`/ContactMessage/simple`, body);
      return response.data;
    } catch (error) {
      return error;
    }
  },
});

export default createContactMessagesService;
