import './ReferralCode.scss';

import React, { useEffect } from 'react';

import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import usePagination from '../../../../general/hooks/usePagination';
import { useResize } from '../../../../general/hooks/useResize';
import { queryKeys } from '../../../../general/queryKeys';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createRefferalService from '../../../../general/services/refferal';
import { PaggingObj, ReferralCode } from '../../../../general/types/types';
import CustomPagination from '../../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../../components/tables/custom-table/CustomTable';
import Share from './Share';

const ReferralCodes: React.FC = () => {
  const size = useResize();
  const headers = ['referral code', 'affiliate', 'generation date'];
  const { page, pageSize, setPage, setPageSize } = usePagination(10);

  const {
    data: referralCodes,
    isLoading: referralCodesLoading,
    refetch: getData,
  } = useCustomQuery<PaggingObj<ReferralCode>>(queryKeys.REFFERAL_CODES, () =>
    createRefferalService(adminInstance).getRefferalPrograms(page, pageSize, ''),
  );

  useEffect(() => {
    getData();
  }, [page, pageSize, getData]);

  const actionButtons = (item: ReferralCode) => <Share item={item} />;

  return (
    <>
      {referralCodesLoading && (
        <div className="page-update-overlay">
          <Preloader />
        </div>
      )}
      <div className="referral-codes">
        <CustomTable
          data={referralCodes ? referralCodes.items : []}
          headers={headers}
          isAction={true}
          nameOfAction={'share'}
          actionComponent={actionButtons}
          color={'#3E5960'}
          isMobile={size.width < 700}
        />
      </div>
      {referralCodes && referralCodes.pageSize < referralCodes.totalCount && (
        <CustomPagination
          currentPage={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          isNextPage={referralCodes.hasNextPage}
          isPrevPage={referralCodes.hasPreviousPage}
          total={referralCodes.totalCount}
        />
      )}
    </>
  );
};

export default ReferralCodes;
