import './Compare.scss';

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import BucketIcon from '../../../general/assets/svg/BucketIcon';
import NextIcon from '../../../general/assets/svg/NextIcon';
import PrevIcon from '../../../general/assets/svg/PrevIcon';
import Preloader from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import { useResize } from '../../../general/hooks/useResize';
import useScrollTo from '../../../general/hooks/useScrollTo';
import { queryKeys } from '../../../general/queryKeys';
import { deleteCompareItem } from '../../../general/redux/reducers/ClientCompare-Reducer';
import { RootState } from '../../../general/redux/store';
import { routesClient } from '../../../general/routes';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import createProductsService from '../../../general/services/products';
import { images } from '../../../general/StaticData';
import { ICategory, ProductMax, ShopLink } from '../../../general/types/types';
import CompareSelectinoList from './CompareSectionList';
import ProductCard from './ProductCard';

const miningCharacteristics = [
  {
    name: 'Condition',
    key: 'condition',
  },
  {
    name: 'Manufacturer',
    key: 'manufacturer',
  },
  {
    name: 'Country',
    key: 'countryLocation',
  },
  {
    name: 'Algorithm',
    key: 'algorithm',
  },
  {
    name: 'Hashrate',
    key: 'hashrate',
  },
  {
    name: 'Energy efficiency',
    key: 'energyEfficiency',
  },
  {
    name: 'Power Consumption',
    key: 'energyConsumption',
  },
];

const Compare: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollTo = useScrollTo();
  const resize = useResize();
  const compareList = useSelector((state: RootState) => state.compare);
  const [products, setProducts] = useState<ProductMax[]>([]);
  const [position, setPosition] = useState<number>(0);
  const [currentCategory, setCurrentCategory] = useState<string>(Object.keys(compareList.i)[0]);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [noMiningProducts, setNoMiningProducts] = useState<any[]>([]);
  const [mainCharacteristics, setMainCharacteristics] = useState<string[]>([]);
  const [isMiningCategory, setIsMiningCategory] = useState<boolean>(false);
  const [isPreloader, setIsPreloader] = useState<boolean>(false);
  const [prevDisabled, setPrevDisabled] = useState<boolean>(false);
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);

  const { data: retailCount } = useCustomQuery<ShopLink>(
    queryKeys.PRODUCT_CATEGORIES_RETAIL,
    () => createProductsService(clientInstance).getProductsCategories(),
    (data: any) => {
      const obj = {
        priceCategoryCount: data?.reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue?.retailProductsCount,
          0,
        ),
        machineCategoryCount: data.length === 1 ? data[0].url : null,
      };

      return obj;
    },
  );

  const { data: wholesaleCount } = useCustomQuery<ShopLink>(
    queryKeys.PRODUCT_CATEGORIES_WHOLESALE,
    () => createProductsService(clientInstance).getProductsCategories(),
    (data: any) => {
      const obj = {
        priceCategoryCount: data?.reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + currentValue?.wholesaleProductsCount,
          0,
        ),
        machineCategoryCount: data.length === 1 ? data[0].url : null,
      };

      return obj;
    },
  );

  const { data: categories } = useCustomQuery<ICategory[]>(queryKeys.PRODUCT_CATEGORIES, () =>
    createProductsService(clientInstance).getProductsCategories(),
  );

  const checkButtons = useCallback(() => {
    if (position === 0) {
      setPrevDisabled(true);
    } else {
      setPrevDisabled(false);
    }

    if (4 + position === compareList.i[currentCategory].length) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [compareList.i, currentCategory, position]);

  const getData = useCallback(
    async (categories: ICategory[]) => {
      setIsPreloader(true);
      const category = categories.find((item: any) => item.id === currentCategory);
      setIsMiningCategory(category?.areMiningCharacteristics ?? false);

      setCategoryList(
        categories.filter((item: any) => Object.keys(compareList.i).includes(item.id)),
      );

      if (category?.areMiningCharacteristics) {
        setProducts(compareList.i[currentCategory].slice(position, position + 4));
        checkButtons();
      } else {
        const ids = compareList.i[currentCategory]?.map(({ id }: any) => id);
        if (ids) {
          const responses = await Promise.all(
            ids.map((id: any) =>
              createProductsService(clientInstance).getCharacteristicsMainGeneralOfProduct(id),
            ),
          );
          setMainCharacteristics(responses[0]?.flatMap(({ name }: any) => name) || []);
          setNoMiningProducts(responses.slice(position, position + 4));
          setProducts(compareList.i[currentCategory].slice(position, position + 4));
          checkButtons();
        }
      }

      setIsPreloader(false);
    },
    [compareList.i, currentCategory, position, checkButtons],
  );

  useEffect(() => {
    if (categories) {
      getData(categories);
    }
  }, [categories, getData]);

  const redirectProducts = (e: any) => {
    e.preventDefault();
    if (
      retailCount &&
      wholesaleCount &&
      retailCount.priceCategoryCount > 0 &&
      wholesaleCount.priceCategoryCount <= 0
    ) {
      if (retailCount.machineCategoryCount) {
        navigate(routesClient.productsRetail.path + `/${retailCount.machineCategoryCount}`);
        return;
      }

      navigate(routesClient.productsRetail.path);
    } else if (
      retailCount &&
      wholesaleCount &&
      wholesaleCount.priceCategoryCount > 0 &&
      retailCount.priceCategoryCount <= 0
    ) {
      if (wholesaleCount.machineCategoryCount) {
        navigate(routesClient.productsRetail.path + `/${wholesaleCount.machineCategoryCount}`);
        return;
      }

      navigate(routesClient.productsWholesale.path);
    } else {
      navigate(routesClient.productsRetail.path);
    }
  };

  const removeItem = (e: any) => {
    const itemId = typeof e === 'string' ? e : e.target.dataset.id;
    dispatch(deleteCompareItem({ c: currentCategory, p: itemId }));

    if (compareList.i[currentCategory].length === 1) {
      setCurrentCategory(Object.keys(compareList.i).filter((item) => item !== currentCategory)[0]);
    }
  };

  const buttonNext = () => {
    if (position + 4 < compareList.i[currentCategory].length) {
      setPosition(position + 1);
    }
  };

  const buttonPrev = () => {
    if (position > 0) {
      setPosition(position - 1);
    }
  };

  const scrollToTop = () => {
    scrollTo('topCompare');
  };

  return (
    <div id="topCompare" className="compare-section page-container">
      <div className="compare-section__top">
        <div className="compare-section-top__title">Comparison List</div>
        <div>
          {Object.keys(compareList.i)?.length > 1 && categoryList.length > 1 && (
            <CompareSelectinoList
              categories={categoryList}
              currentCategory={currentCategory}
              setCurrentCategory={setCurrentCategory}
            />
          )}
        </div>
      </div>
      <div className="compare-section__content">
        {compareList.c > 0 ? (
          resize.width > 1750 ? (
            <>
              {isPreloader && (
                <div className="product-table-preloader">
                  <Preloader />
                </div>
              )}
              <table className="compare-table">
                <thead className="compare-table__thead">
                  <tr>
                    <th>
                      Products
                      <br />
                      to Compare
                    </th>
                    {products?.map((product, index) => (
                      <th key={index}>
                        <Link to={`/${product?.url}`} className="compare-table__thead__product">
                          <img
                            alt="product img"
                            src={
                              Object.prototype.hasOwnProperty.call(product, 'image')
                                ? product?.image?.url || images.defaultImg
                                : product?.images[0]?.url || images.defaultImg
                            }
                          />
                          <div className="compare-table__thead__product__info">
                            <span className={`name${index}`}>{product.name}</span>
                            <Tooltip anchorSelect={`.name${index}`} place="top">
                              {product.name}
                            </Tooltip>
                            <span>Seller: AsicXchange</span>
                          </div>
                        </Link>
                        <button
                          onClick={removeItem}
                          data-id={product.id}
                          className="compare-table__thead__delete-item"
                        >
                          <BucketIcon />
                        </button>
                      </th>
                    ))}
                  </tr>
                  <tr>
                    <th className="compare-table__thead__row-price">Price</th>
                    {products?.map((product, index) => (
                      <th key={index} className="compare-table__thead__row-price">
                        <div className="compare-table__thead__row-price-block">
                          {product?.percentageDiscount === 0 ? (
                            `${product?.price}$`
                          ) : (
                            <>
                              <span className="compare-table__thead__row-price-without-discount">{`${product?.price}$`}</span>
                              <span className="compare-table__thead__row-price-with-discount">{`${
                                product?.price -
                                product?.price * (product?.percentageDiscount / 100)
                              }$`}</span>
                            </>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="compare-table__tbody">
                  {isMiningCategory ? (
                    <>
                      {miningCharacteristics.map((items, index) => (
                        <tr key={index}>
                          <td>{items.name}</td>
                          {products?.map((product, index) => (
                            <td key={index} className="compare-table__thead__row-price">
                              {product[items.key]}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      {mainCharacteristics.map((items, index) => (
                        <tr key={index}>
                          <td>{items}</td>
                          {noMiningProducts?.map((product, index) => (
                            <td key={index} className="compare-table__thead__row-price">
                              {product.map((characteristic: any, index: number) => (
                                <Fragment key={index}>
                                  {items === characteristic.name && (
                                    <>{characteristic?.value || '-'}</>
                                  )}
                                </Fragment>
                              ))}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
              {compareList.i[currentCategory].length > 3 && (
                <>
                  <button
                    onClick={buttonPrev}
                    className={`compare-table__prev ${
                      prevDisabled && 'compare-table__button-disable'
                    } compare-table__button`}
                  >
                    <PrevIcon />
                  </button>
                  <button
                    onClick={buttonNext}
                    className={`compare-table__next ${
                      nextDisabled && 'compare-table__button-disable'
                    } compare-table__button`}
                  >
                    <NextIcon />
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              {resize.width > 843 ? (
                <>
                  {compareList.i[currentCategory].length > 4 && (
                    <button
                      style={{
                        cursor: `${position === 0 ? 'not-allowed' : 'pointer'}`,
                      }}
                      disabled={position === 0}
                      onClick={() => {
                        buttonPrev();
                        scrollToTop();
                      }}
                      className="compare-table__next__not-table"
                    >
                      <PrevIcon />
                    </button>
                  )}
                  {isMiningCategory ? (
                    <>
                      {products?.map((product, index) => (
                        <ProductCard
                          key={index}
                          index={index}
                          product={product}
                          generalInfo={products[index]}
                          remove={removeItem}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      {noMiningProducts?.map((product, prodIndex) => (
                        <ProductCard
                          key={prodIndex}
                          index={product.id}
                          product={product}
                          generalInfo={products[prodIndex]}
                          remove={removeItem}
                          isMiningCategory={isMiningCategory}
                        />
                      ))}
                    </>
                  )}
                  {compareList.i[currentCategory].length > 4 && (
                    <button
                      style={{
                        cursor: `${
                          position + 4 >= compareList.i[currentCategory].length
                            ? 'not-allowed'
                            : 'pointer'
                        }`,
                      }}
                      disabled={position + 4 >= compareList.i[currentCategory].length}
                      onClick={() => {
                        buttonNext();
                        scrollToTop();
                      }}
                      className="compare-table__next__not-table"
                    >
                      <NextIcon />
                    </button>
                  )}
                </>
              ) : (
                <>
                  {isMiningCategory ? (
                    <>
                      {products?.map((product, index) => (
                        <ProductCard
                          key={index}
                          index={index}
                          product={product}
                          generalInfo={products[index]}
                          remove={removeItem}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      {noMiningProducts?.map((product, prodIndex) => (
                        <ProductCard
                          key={prodIndex}
                          index={product.id}
                          product={product}
                          generalInfo={products[prodIndex]}
                          remove={removeItem}
                          isMiningCategory={isMiningCategory}
                        />
                      ))}
                    </>
                  )}
                  {compareList.i[currentCategory].length > 4 && (
                    <div style={{ display: 'flex', width: '100%' }}>
                      <button
                        style={{
                          flex: 1,
                          cursor: `${position === 0 ? 'not-allowed' : 'pointer'}`,
                        }}
                        disabled={position === 0}
                        onClick={() => {
                          buttonPrev();
                          scrollToTop();
                        }}
                        className="compare-table__next__not-table"
                      >
                        <PrevIcon />
                      </button>
                      <button
                        style={{
                          flex: 1,
                          cursor: `${
                            position + 4 >= compareList.i[currentCategory].length
                              ? 'not-allowed'
                              : 'pointer'
                          }`,
                        }}
                        disabled={position + 4 >= compareList.i[currentCategory].length}
                        onClick={() => {
                          buttonNext();
                          scrollToTop();
                        }}
                        className="compare-table__next__not-table"
                      >
                        <NextIcon />
                      </button>
                    </div>
                  )}
                </>
              )}
            </>
          )
        ) : (
          <>
            <div className="compare-section__empty" style={{ width: '100%' }}>
              <div className="compare-section__empty__img">
                <div className="img" style={{ backgroundImage: `url(${images.noGoods})` }} />
              </div>
              <div className="compare-section__empty__text">Oops! Comparison list is empty</div>
              <button className="compare-section__empty_btn" onClick={redirectProducts}>
                Go to products
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Compare;
