import { useMemo } from 'react';
import { routesClient } from '../routes';

interface Count {
  priceCategoryCount: number;
  machineCategoryCount?: string | null;
}

const useRedirectToProducts = (retailCount?: Count, wholesaleCount?: Count): string => {
  return useMemo(() => {
    if (
      retailCount &&
      wholesaleCount &&
      retailCount.priceCategoryCount > 0 &&
      wholesaleCount.priceCategoryCount <= 0
    ) {
      return retailCount.machineCategoryCount
        ? `${routesClient.productsRetail.path}/${retailCount.machineCategoryCount}`
        : routesClient.productsRetail.path;
    } else if (
      retailCount &&
      wholesaleCount &&
      wholesaleCount.priceCategoryCount > 0 &&
      retailCount.priceCategoryCount <= 0
    ) {
      return wholesaleCount.machineCategoryCount
        ? `${routesClient.productsWholesale.path}/${wholesaleCount.machineCategoryCount}`
        : routesClient.productsWholesale.path;
    } else {
      return routesClient.productsRetail.path;
    }
  }, [retailCount, wholesaleCount]);
};

export default useRedirectToProducts;
