import './Information.scss';

import React from 'react';

import { User, ValidationError } from '../../../../../general/types/types';
import InputBlock from './InputBlock';

interface InputsProps {
  errors: ValidationError[];
  user: User;
  setLocationVisible: (visible: boolean) => void;
  locationVisible: boolean;
  method: string;
}

const getFieldValue = (values: any, fieldName: string, userField?: string, defaultValue = '') => {
  if (Array.isArray(values)) {
    const field = values.find((obj: any) => obj.field === fieldName);
    return field?.value || userField || defaultValue;
  }
  return userField || defaultValue;
};

const getFieldError = (errors: ValidationError[], fieldName: string) => {
  return errors.find((obj) => obj.field === fieldName)?.message || '';
};

const Inputs: React.FC<InputsProps> = ({
  errors,
  user,
  setLocationVisible,
  locationVisible,
  method,
}) => {
  const storageKey = method.toLowerCase() === 'shipping' ? 'shipping-fields' : 'billing-fields';
  const values: any = JSON.parse(sessionStorage.getItem(storageKey) || '{}');

  return (
    <>
      <h2>{method} address</h2>
      <InputBlock
        id="firstName"
        label="First name*"
        placeholder="First name"
        defValue={getFieldValue(values, 'name', user?.name)}
        error={getFieldError(errors, 'name')}
      />
      <InputBlock
        id="lastName"
        label="Last name*"
        placeholder="Last name"
        defValue={getFieldValue(values, 'lastName', user?.surname)}
        error={getFieldError(errors, 'lastName')}
      />
      <InputBlock
        id="company"
        label="Company (optional)"
        placeholder="Company name"
        defValue={getFieldValue(values, 'company')}
        error={getFieldError(errors, 'company')}
      />
      <InputBlock
        id="country"
        label="Country*"
        placeholder="Country*"
        defValue={getFieldValue(values, 'country')}
        error={getFieldError(errors, 'country')}
      />
      <div className="location_info">
        <InputBlock
          id="state"
          label="State*"
          placeholder="State*"
          defValue={getFieldValue(values, 'state')}
          error={getFieldError(errors, 'state')}
        />
        <InputBlock
          id="city"
          label="City*"
          placeholder="City*"
          defValue={getFieldValue(values, 'city')}
          error={getFieldError(errors, 'city')}
        />
        <InputBlock
          id="zip"
          label="Zip code*"
          placeholder="Zip code*"
          defValue={getFieldValue(values, 'zip')}
          error={getFieldError(errors, 'zip')}
        />
      </div>
      <InputBlock
        id="street1"
        label="Street*"
        placeholder="Street 1"
        defValue={getFieldValue(values, 'street1')}
        error={getFieldError(errors, 'street1')}
        isMore={locationVisible}
        openMore={setLocationVisible}
      />
      {locationVisible && (
        <div className="location_info">
          <InputBlock
            id="street2"
            label="Street 2 (optional)"
            placeholder="Street 2"
            defValue={getFieldValue(values, 'street2')}
            error={getFieldError(errors, 'street2')}
          />
          <InputBlock
            id="street3"
            label="Street 3 (optional)"
            placeholder="Street 3"
            defValue={getFieldValue(values, 'street3')}
            error={getFieldError(errors, 'street3')}
          />
          <InputBlock
            id="streetNumber"
            label="Street # (optional)"
            placeholder="Street number"
            defValue={getFieldValue(values, 'streetNumber')}
            error={getFieldError(errors, 'streetNumber')}
          />
        </div>
      )}
      <h2>Information</h2>
      <InputBlock
        id="phone"
        label="Phone*"
        placeholder="1 075 000 00"
        defValue={getFieldValue(values, 'phone', user?.phoneNumber)}
        error={getFieldError(errors, 'phone')}
      />
      <InputBlock
        id="email"
        label="E-mail*"
        placeholder="test@gmail.com"
        defValue={getFieldValue(values, 'email', user?.email)}
        error={getFieldError(errors, 'email')}
      />
    </>
  );
};

export default Inputs;
