import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LocationProdStoresIcon from '../../../../../../general/assets/svg/LocationProdStoresIcon';
import {
  addShippingStoreLocationAddress,
  DeliveryMethod,
  deliveryMethodsEnum,
  removeShippingStoreLocationAddress,
  setDeliveryMethodRedux,
  setShippingStoreLocationAddress,
  setShippingStoresLocationTransactionId,
} from '../../../../../../general/redux/reducers/ClientCartProcessing-Reducer';
import { RootState } from '../../../../../../general/redux/store';
import { ProductMax, ProductsStoreAddress } from '../../../../../../general/types/types';

interface SelfPickUpProps {
  products: ProductMax[];
}

const SelfPickUp: React.FC<SelfPickUpProps> = ({ products }) => {
  const dispatch = useDispatch();
  const deliveryMethodRedux = useSelector(
    (state: RootState) => state.clientCartProcessing.deliveryMethod,
  );
  const storesLocationRedux: any = useSelector(
    (state: RootState) => state.clientCartProcessing.shippingStoresLocationAddress,
  );
  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>(deliveryMethodRedux);

  const [currentFrame, setCurrentFrame] = useState<string>('');

  useEffect(() => {
    setDeliveryMethod(deliveryMethodRedux);
    dispatch(setShippingStoresLocationTransactionId(null));
  }, [deliveryMethodRedux, dispatch]);

  const updateDeliveryMethod = (method: DeliveryMethod) => {
    dispatch(setDeliveryMethodRedux(method));
  };

  const handleCheckboxChange = (store: ProductsStoreAddress) => {
    if (storesLocationRedux.some((selectedStore: any) => selectedStore.id === store.id)) {
      dispatch(removeShippingStoreLocationAddress(store.id));
    } else {
      dispatch(addShippingStoreLocationAddress(store));
    }
  };

  useEffect(() => {
    // Инициализация storesLocationRedux, если он не определен
    if (!storesLocationRedux) {
      dispatch(setShippingStoreLocationAddress([]));
    }
  }, [dispatch, storesLocationRedux]);

  return (
    <>
      <>
        <div id="delivery-method_question" className="delivery-method_radio">
          <div>
            <div>
              <label className="custom-radio">
                <input
                  id="delivery-method"
                  type="radio"
                  value={deliveryMethodsEnum.SHIPPING}
                  checked={deliveryMethod === deliveryMethodsEnum.SHIPPING}
                  onChange={() => updateDeliveryMethod(deliveryMethodsEnum.SHIPPING)}
                />
                <span className="radio-btn">Shipping</span>
              </label>
            </div>
            <div>
              <label className="custom-radio">
                <input
                  type="radio"
                  value={deliveryMethodsEnum.SELF_PICKUP}
                  checked={deliveryMethod === deliveryMethodsEnum.SELF_PICKUP}
                  onChange={() => updateDeliveryMethod(deliveryMethodsEnum.SELF_PICKUP)}
                />
                <span className="radio-btn">Self pickup</span>
              </label>
            </div>
          </div>
        </div>
      </>
      {deliveryMethod === deliveryMethodsEnum.SELF_PICKUP && (
        <div className="shipping-stores_locations">
          {products &&
            products.map(
              (product, productIndex) =>
                product.stores &&
                product.stores.length > 0 && (
                  <React.Fragment key={productIndex}>
                    <span className="product_stores_header">{product.name}</span>
                    <div className="available_stores">
                      {product.stores.map((store, storeIndex) => (
                        <div
                          key={storeIndex}
                          className="store-item"
                          style={{ position: 'relative' }}
                          onClick={() => {
                            setCurrentFrame(store.mapFrame);
                          }}
                        >
                          <LocationProdStoresIcon />
                          <div className="info-block">
                            <span className="country">{store.country}</span>
                            <span className="address">{store.address}</span>
                          </div>
                          <div className="check-box__container">
                            <input
                              id={`is_checked_location${store.id}`}
                              type="checkbox"
                              onChange={() => handleCheckboxChange(store)}
                              checked={storesLocationRedux.some(
                                (selectedStore: ProductsStoreAddress) =>
                                  selectedStore.id === store.id,
                              )}
                            />
                            <label htmlFor={`is_checked_location${store.id}`} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </React.Fragment>
                ),
            )}
          <div className="map" dangerouslySetInnerHTML={{ __html: currentFrame }}></div>
        </div>
      )}
    </>
  );
};

export default SelfPickUp;
