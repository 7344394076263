import React from 'react';

const BurgerMenuIcon: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="white" />
    <path
      d="M9.4375 11.9375H22.5625M9.4375 16H22.5625M9.4375 20.0625H22.5625"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default BurgerMenuIcon;
