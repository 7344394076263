import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Affiliate } from '../../types/types';

interface AffiliateState {
  affiliates: Affiliate[];
}

const initialState: AffiliateState = {
  affiliates: [],
};

const AffiliateSlice = createSlice({
  name: 'affiliate',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<Affiliate[]>) => {
      state.affiliates = action.payload;
    },
    addAffiliate: (state, action: PayloadAction<Affiliate>) => {
      state.affiliates = [...state.affiliates, action.payload];
    },
    addIdToLastAffiliate: (state, action: PayloadAction<string>) => {
      if (state.affiliates.length > 0) {
        const lastIndex = state.affiliates.length - 1;
        state.affiliates[lastIndex] = {
          ...state.affiliates[lastIndex],
          id: action.payload,
        };
      }
    },
    removeAffiliateById: (state, action: PayloadAction<string>) => {
      const newAffiliates = state.affiliates.filter((affiliate) => affiliate.id !== action.payload);
      state.affiliates = [...newAffiliates];
    },
    updateAffiliate: (state, action: PayloadAction<Affiliate>) => {
      const newAffiliates = state.affiliates.map((affiliate) =>
        affiliate.id === action.payload.id ? action.payload : affiliate,
      );
      state.affiliates = [...newAffiliates];
    },
    // updateLinkActive: (
    //   state,
    //   action: PayloadAction<{
    //     nickname: string;
    //     socialName: string;
    //     linkUrl: string;
    //     isActive: boolean;
    //   }>
    // ) => {
    //   const { nickname, socialName, linkUrl, isActive } = action.payload;
    //   const affiliateIndex = state.affiliates.findIndex(
    //     (affiliate) => affiliate.nickname === nickname
    //   );
    //   if (affiliateIndex === -1) return;
    //   const newAffiliate = state.affiliates[affiliateIndex];
    //   const socialLinks = newAffiliate.links[socialName.toLowerCase()];
    //   if (!socialLinks) return;
    //   const linkIndex = socialLinks.findIndex((link) => link.link === linkUrl);
    //   if (linkIndex === -1) return;
    //   newAffiliate.links[socialName.toLowerCase()][linkIndex].isActive = isActive;
    //   state.affiliates[affiliateIndex] = newAffiliate;
    // },
  },
});

export const {
  init,
  addAffiliate,
  addIdToLastAffiliate,
  removeAffiliateById,
  updateAffiliate,
  //updateLinkActive,
} = AffiliateSlice.actions;
export default AffiliateSlice.reducer;
