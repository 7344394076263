import '../styles/buttonStyles.scss';
import '../styles/editorStyles.scss';

import {
  DraftJsButtonTheme,
  DraftJsStyleButtonProps,
  HeadlineOneButton,
  HeadlineThreeButton,
  HeadlineTwoButton,
} from '@draft-js-plugins/buttons';
import { ToolbarChildrenProps } from '@draft-js-plugins/inline-toolbar/lib/components/Toolbar';
import React, { Component, ComponentType } from 'react';

interface HeadlinesPickerProps {
  onOverrideContent: (content: ComponentType<ToolbarChildrenProps> | undefined) => void;
  setEditorState: DraftJsStyleButtonProps['setEditorState'];
  getEditorState: DraftJsStyleButtonProps['getEditorState'];
  theme?: DraftJsStyleButtonProps['theme'];
}

class HeadlinesPicker extends Component<HeadlinesPickerProps> {
  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('click', this.onWindowClick);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  onWindowClick = () => this.props.onOverrideContent(undefined);

  render() {
    const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton];
    const { setEditorState, getEditorState, theme } = this.props;

    return (
      <div>
        {buttons.map((Button, i) => (
          <Button
            key={i}
            setEditorState={setEditorState}
            getEditorState={getEditorState}
            theme={theme as DraftJsButtonTheme}
          />
        ))}
      </div>
    );
  }
}

interface HeadlinesButtonProps {
  onOverrideContent: (content: ComponentType<ToolbarChildrenProps> | undefined) => void;
  setEditorState: DraftJsStyleButtonProps['setEditorState'];
  getEditorState: DraftJsStyleButtonProps['getEditorState'];
  theme?: DraftJsStyleButtonProps['theme'];
}

const HeadlinesButton: React.FC<HeadlinesButtonProps> = ({
  onOverrideContent,
  setEditorState,
  getEditorState,
  theme,
}) => {
  const onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => event.preventDefault();

  const onClick = () =>
    onOverrideContent(() => (
      <HeadlinesPicker
        onOverrideContent={onOverrideContent}
        setEditorState={setEditorState}
        getEditorState={getEditorState}
        theme={theme}
      />
    ));

  return (
    <div onMouseDown={onMouseDown} className="inlineButtonWrapper">
      <button
        onClick={onClick}
        style={{ fontWeight: 'bold', fontSize: '16px' }}
        className="inlineButton"
      >
        H
      </button>
    </div>
  );
};

export default HeadlinesButton;
