import React from 'react';

const PeopleIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_622_69)">
      <path
        d="M10.09 15.8199L12 13.9099L13.91 15.8199"
        stroke="#25A8CF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M12 12V13.91"
        stroke="#25A8CF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M12 5.32007C12.7585 5.32007 13.486 5.62139 14.0223 6.15774C14.5587 6.6941 14.86 7.42155 14.86 8.18007V9.18007H9.14001V8.18007C9.14001 7.42155 9.44133 6.6941 9.97769 6.15774C10.514 5.62139 11.2415 5.32007 12 5.32007Z"
        stroke="#25A8CF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M12 5.32C13.0548 5.32 13.91 4.46486 13.91 3.41C13.91 2.35514 13.0548 1.5 12 1.5C10.9451 1.5 10.09 2.35514 10.09 3.41C10.09 4.46486 10.9451 5.32 12 5.32Z"
        stroke="#25A8CF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M19.64 18.6799C20.3985 18.6799 21.126 18.9813 21.6623 19.5176C22.1987 20.054 22.5 20.7814 22.5 21.5399V22.5399H16.77V21.5399C16.77 21.1635 16.8443 20.7908 16.9887 20.4432C17.133 20.0955 17.3446 19.7798 17.6112 19.5141C17.8779 19.2484 18.1943 19.0379 18.5425 18.8948C18.8906 18.7516 19.2636 18.6786 19.64 18.6799Z"
        stroke="#25A8CF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M19.64 18.6801C20.6948 18.6801 21.55 17.825 21.55 16.7701C21.55 15.7152 20.6948 14.8601 19.64 14.8601C18.5851 14.8601 17.73 15.7152 17.73 16.7701C17.73 17.825 18.5851 18.6801 19.64 18.6801Z"
        stroke="#25A8CF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M4.36 18.6799C4.73558 18.6799 5.10748 18.7539 5.45447 18.8976C5.80147 19.0414 6.11675 19.252 6.38233 19.5176C6.6479 19.7832 6.85857 20.0985 7.0023 20.4455C7.14602 20.7924 7.22 21.1644 7.22 21.5399V22.5399H1.5V21.5399C1.5 20.7814 1.80132 20.054 2.33767 19.5176C2.87403 18.9813 3.60148 18.6799 4.36 18.6799Z"
        stroke="#25A8CF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M4.35995 18.6801C5.41482 18.6801 6.26995 17.825 6.26995 16.7701C6.26995 15.7152 5.41482 14.8601 4.35995 14.8601C3.30509 14.8601 2.44995 15.7152 2.44995 16.7701C2.44995 17.825 3.30509 18.6801 4.35995 18.6801Z"
        stroke="#25A8CF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_622_69">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PeopleIcon;
