import React from 'react';

const ShippingAndLocationIcon: React.FC = () => (
  <svg width="35" height="35" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5233_227)">
      <path
        d="M19.6401 9.16344C19.6401 15.8434 12.0001 22.5234 12.0001 22.5234C12.0001 22.5234 4.36011 15.8434 4.36011 9.16344C4.36011 7.13718 5.16503 5.19392 6.59781 3.76114C8.03059 2.32836 9.97385 1.52344 12.0001 1.52344C14.0264 1.52344 15.9696 2.32836 17.4024 3.76114C18.8352 5.19392 19.6401 7.13718 19.6401 9.16344Z"
        stroke="#6E7EA8"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M11.9999 12.0235C13.5794 12.0235 14.8599 10.743 14.8599 9.16347C14.8599 7.58393 13.5794 6.30347 11.9999 6.30347C10.4204 6.30347 9.13989 7.58393 9.13989 9.16347C9.13989 10.743 10.4204 12.0235 11.9999 12.0235Z"
        stroke="#6E7EA8"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_5233_227">
        <rect width="24" height="24" fill="white" transform="translate(0 0.0234375)" />
      </clipPath>
    </defs>
  </svg>
);

export default ShippingAndLocationIcon;
