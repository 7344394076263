import { AxiosInstance } from 'axios';

interface OrdersService {
  getOrdersCount: (status: string) => Promise<any>;
  putOrderPaymeentStatus: (data: any) => Promise<any>;
  putOrderStatus: (data: any) => Promise<any>;
  getTransactionStatus: (body: any) => Promise<any>;
  getOrder: (id: string) => Promise<any>;
  putOrderClientInfo: (body: {
    orderId: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
  }) => Promise<any>;
  putQuickOrderClientInfo: (body: {
    orderId: string;
    name: string;
    phoneNumber: string;
    email: string;
  }) => Promise<any>;
  getAllOrders: (query: string, pageSize: number, page: number) => Promise<any>;
}

const createOrdersService = (instance: AxiosInstance): OrdersService => ({
  async getOrdersCount(status: string) {
    try {
      const response = await instance.get(`/Order/ordersWithStatusCount?Status=${status}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async putOrderPaymeentStatus(data: any) {
    try {
      const response = await instance.put(`/Order/paymentStatus`, data);
      return response;
    } catch (error) {
      return error;
    }
  },

  async putOrderStatus(data: any) {
    try {
      const response = await instance.put(`/Order/status`, data);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getTransactionStatus(body: any) {
    try {
      const response = await instance.post(`/Payment/getPaymentStatus`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getOrder(id: string) {
    try {
      const response = await instance.get(`/Order/byId?Id=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async putOrderClientInfo(body: {
    orderId: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
  }) {
    try {
      const response = await instance.put(`/Order/userInfo`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async putQuickOrderClientInfo(body: {
    orderId: string;
    name: string;
    phoneNumber: string;
    email: string;
  }) {
    try {
      const response = await instance.put(`/Order/quickOrderUserInfo`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getAllOrders(query: string, pageSize: number, page: number) {
    try {
      const response = await instance.get(
        `/Order?SearchQuery=${encodeURIComponent(query)}&PageSize=${pageSize}&Page=${page}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createOrdersService;
