import './Hosting.scss';

import React from 'react';

import Preloader from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../general/queryKeys';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import createShippingService from '../../../general/services/shipping';
import { IHostingLocation, PaggingObj } from '../../../general/types/types';
import ContactForm from '../../components/contact-form/ContactForm';
import CanonicalLink from '../../components/SEO/CanonicalLink';
import HostingLocation from './HostingLocation';

interface Benefit {
  img: string;
  header: string;
  text: string;
}

interface Step {
  img: string;
  text: string;
}

const Hosting: React.FC = () => {
  // const [hostingToMap, setHostingToMap] = useState<IHostingLocation>();
  // const resize = useResize();

  const benefits: Benefit[] = [
    {
      img: 'https://s3.tebi.io/asicxchange-media/532ce40f-ef55-4248-b616-c089278dc50c',
      header: '24/7 Technical Service',
      text: 'Support from a team fluent in English, Italian, French, and Russian, delivering top-tier service worldwide',
    },
    {
      img: 'https://s3.tebi.io/asicxchange-media/b0e950ce-60db-4d2d-8fcc-3533f03a6a2b',
      header: 'Highly Qualified Experts',
      text: 'Our skilled experts across Europe, America, and Asia provide superior service tailored to local needs',
    },
    {
      img: 'https://s3.tebi.io/asicxchange-media/80118dec-f3be-43d7-94e9-a9b0d96c3d91',
      header: 'Worldwide Operations',
      text: 'We blend local insights with global expertise through collaborations with trusted international partners',
    },

    {
      img: 'https://s3.tebi.io/asicxchange-media/33a3736b-f5f3-4889-9578-8199d75919f4',
      header: 'Commitment',
      text: 'Our unwavering commitment to customers and partners is built on a foundation of honesty and trust',
    },
    {
      img: 'https://s3.tebi.io/asicxchange-media/f1518981-0acc-4013-81ff-afd613f3a397',
      header: 'Customer Friendly Terms',
      text: 'No upfront fees, only small deposits, with service charges based solely on actual usage',
    },
    {
      img: 'https://s3.tebi.io/asicxchange-media/6c392042-4319-4a15-99ee-377f4f4b2b85',
      header: 'Sustainability',
      text: 'Aligned with global climate goals, we prioritize renewable energy sources in all our operations',
    },
  ];

  const steps: Step[] = [
    {
      img: 'https://s3.tebi.io/asicxchange-media/13f20ec9-017c-49ea-bd4e-bf8c4ca25b78',
      text: "Reach out to us, and we'll provide personalized advice based on your requirements",
    },
    {
      img: 'https://s3.tebi.io/asicxchange-media/13f20ec9-017c-49ea-bd4e-bf8c4ca25b78',
      text: 'We create a customized offer and guide you through any crypto-related questions you may have',
    },
    {
      img: 'https://s3.tebi.io/asicxchange-media/13f20ec9-017c-49ea-bd4e-bf8c4ca25b78',
      text: 'Our experts handle everything, from logistics to installation and ongoing maintenance',
    },
  ];

  const { data: locations, isLoading: locationsLoading } = useCustomQuery<
    PaggingObj<IHostingLocation>
  >(queryKeys.HOSTING_LOCATIONS, () =>
    createShippingService(clientInstance).getHostingLocations(100, 1, ''),
  );

  return (
    <>
      <CanonicalLink href={window.location.href} />
      <div className="services_hosting page-container">
        <div className="map-section">
          <h1>
            MINING <span>HOSTING</span>
          </h1>
          {/* <div className="map-block">
          <ThreeMap />
        </div> */}
        </div>
        <div className="facilities">
          {/* <h2>FACILITIES</h2> */}
          <div className="facilities-block">
            {locationsLoading ? (
              <Preloader />
            ) : locations &&
              locations?.items?.length > 0 &&
              locations.items.filter((location) => location.isActive).length > 0 ? (
              locations.items.map(
                (location, index) =>
                  location.images.length > 0 &&
                  location.isActive && (
                    <div key={index} className="location-card">
                      <HostingLocation hosting={location} />
                    </div>
                  ),
              )
            ) : (
              <p className="no-hosting">No hostings</p>
            )}
          </div>
        </div>
        <div className="how">
          <div className="header">
            <h2>
              HOW THE PROCESS <span>WORKS</span>
            </h2>
          </div>
          <div className="steps">
            <div className="steps-container">
              <hr />
              {steps.map((step, index) => (
                <div key={index} className="step-card">
                  <div className="circle"></div>
                  <div className="step-image" style={{ backgroundImage: `url(${step.img})` }}>
                    <span className="step-number">{index + 1}</span>
                  </div>
                  <span className="step-text">{step.text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="benefits">
          <div className="header">
            <h2>
              BENEFITS AND <span>CORE VALUES</span>
            </h2>
          </div>
          <div className="benefits-block">
            {benefits.map((benefit, index) => (
              <div key={index} className="benefit-card">
                <div
                  className="benefit-image"
                  style={{ backgroundImage: `url(${benefit.img})` }}
                ></div>
                <span className="benefit-header">{benefit.header}</span>
                <span className="benefit-text">{benefit.text}</span>
              </div>
            ))}
          </div>
        </div>

        <ContactForm />
      </div>
    </>
  );
};

export default Hosting;
