import './AdminTestimonials.scss';

import React, { useState } from 'react';

import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import createHomePageService from '../../../../general/services/homePage';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import { images } from '../../../../general/StaticData';
import AdminModalWindow from '../../../components/modal-window/ModalWindow';
import RatingSelector from './RatingSelector';

interface ModalTestimonialProps {
  isOpen: (isOpen: boolean) => void;
  id: string | null;
}

const ModalTestimonial: React.FC<ModalTestimonialProps> = ({ isOpen, id }) => {
  const closeModal = () => {
    isOpen(false);
  };

  const [isPreloader, setIsPreloader] = useState(false);
  const [isDeleteImg, setIsDeleteImg] = useState<string | null>('');
  const [currentIMG, setCurrentIMG] = useState<string | null>('');

  const { mutate: postTestimonial } = useCustomMutation(
    createHomePageService(adminInstance).postTestimoneal,
    undefined,
    {
      onSuccess: () => {
        end();
      },
    },
  );

  const { mutate: putTestimonial } = useCustomMutation(
    createHomePageService(adminInstance).putTestimoneal,
    undefined,
    {
      onSuccess: () => {
        end();
      },
    },
  );

  const { mutate: deleteTestimonialImage } = useCustomMutation(
    createHomePageService(adminInstance).deleteTestimonealImage,
    undefined,
    {
      onSuccess: () => {
        end();
      },
    },
  );

  const { mutate: putTestimonealImage } = useCustomMutation(
    createHomePageService(adminInstance).putTestimonealImage,
    undefined,
    {
      onSuccess: () => {
        end();
      },
    },
  );

  const { mutate: deleteTestimoneal } = useCustomMutation(
    createHomePageService(adminInstance).deleteTestimoneal,
    undefined,
    {
      onSuccess: () => {
        end();
      },
    },
  );

  const { data: testimonial } = useCustomQuery<any>(
    queryKeys.TESTIMONIAL_BY_ID,
    () => createHomePageService(adminInstance).getTestimonealById(id as string),
    undefined,
    undefined,
    {
      enabled: id !== null,
      onSuccess(data: any) {
        setCurrentIMG(data.imageUrl || '');
      },
    },
  );

  const end = () => {
    closeModal();
    setIsPreloader(false);
  };

  const addTestimonial = async () => {
    setIsPreloader(true);
    const inputBlock = document.querySelector(
      '.modal-window-action__modal__testimonials__b',
    ) as HTMLElement;
    const inputs = inputBlock.querySelectorAll('input');
    const textarea = inputBlock.querySelector('textarea') as HTMLTextAreaElement;
    const rating = (document.querySelector('.order-status__current-options__name') as HTMLElement)
      .dataset.option;
    const input_img = document.getElementById('upload-photo') as HTMLInputElement;

    const required: boolean[] = [];

    if (inputs[0].value.trim() === '') {
      inputs[0].style.border = '1px solid red';
      required.push(false);
    }
    if (inputs[1].value.trim() === '') {
      inputs[1].style.border = '1px solid red';
      required.push(false);
    }

    if (textarea.value.trim() === '') {
      textarea.style.border = '1px solid red';
      required.push(false);
    }

    if (required.includes(false)) {
      setIsPreloader(false);
      return;
    } else {
      if (input_img.files) {
        postTestimonial({
          name: inputs[0].value,
          country: inputs[1].value,
          content: textarea.value,
          rating: Number(rating),
          image: input_img.files[0],
        });
      }
    }
  };

  const updateTestimonial = async () => {
    setIsPreloader(true);
    const inputBlock = document.querySelector(
      '.modal-window-action__modal__testimonials__b',
    ) as HTMLElement;
    const inputs = inputBlock.querySelectorAll('input');
    const textarea = inputBlock.querySelector('textarea') as HTMLTextAreaElement;
    const rating = (document.querySelector('.order-status__current-options__name') as HTMLElement)
      .dataset.option;
    const input_img = document.getElementById('upload-photo') as HTMLInputElement;

    const required: boolean[] = [];

    if (inputs[0].value.trim() === '') {
      inputs[0].style.border = '1px solid red';
      required.push(false);
    }
    if (inputs[1].value.trim() === '') {
      inputs[1].style.border = '1px solid red';
      required.push(false);
    }

    if (textarea.value.trim() === '') {
      textarea.style.border = '1px solid red';
      required.push(false);
    }

    if (required.includes(false)) {
      setIsPreloader(false);
      return;
    } else {
      putTestimonial({
        id: id as string,
        username: inputs[0].value,
        country: inputs[1].value,
        content: textarea.value,
        rating: rating as string,
      });

      if (isDeleteImg !== '') {
        deleteTestimonialImage({ imageId: isDeleteImg as string });
      }

      if (input_img.files && input_img.files[0] !== undefined) {
        putTestimonealImage({
          id: id as string,
          image: input_img.files[0],
        });
      }
    }
  };

  const deletePartner = async () => {
    setIsPreloader(true);
    deleteTestimoneal(testimonial?.id);
  };

  const clearImg = (e: React.MouseEvent<HTMLImageElement>) => {
    setCurrentIMG(null);
    setIsDeleteImg(e.currentTarget.dataset.id || null);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setCurrentIMG(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <AdminModalWindow mWidth="" isOpen={isOpen} title={'Testimonials'}>
      {isPreloader && (
        <div className="modal-window-action__modal__home__loading">
          <Preloader />
        </div>
      )}
      <div className="modal-window-action__modal__testimonials">
        <div className="modal-window-action__modal__testimonials__a">
          <div className="modal-window-action__modal__testimonials__img">
            {currentIMG === '' ? (
              <label htmlFor="upload-photo" className="img-loader">
                +
              </label>
            ) : (
              <div
                className="selected-img"
                data-id={testimonial?.imageId}
                onClick={clearImg}
                style={{
                  backgroundImage: `url(${currentIMG || images.defaultImg})`,
                  margin: '0 10px',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '150px',
                }}
              />
            )}

            <input id="upload-photo" type="file" accept="image/*" onChange={handleFileChange} />
          </div>
          <RatingSelector
            currentRating={testimonial?.rating === undefined ? 5 : testimonial?.rating}
          />
        </div>
        <div className="modal-window-action__modal__testimonials__b">
          <div className="modal-window-action__modal__testimonials__inputs inputs">
            <input type="text" placeholder="name" defaultValue={testimonial?.username || ''} />
            <input type="text" placeholder="location" defaultValue={testimonial?.country || ''} />
          </div>
          <textarea placeholder="description" defaultValue={testimonial?.content || ''} />
        </div>
      </div>
      <div className="modal-window-action__modal__button">
        {testimonial !== null && (
          <button className="delete-btn" onClick={deletePartner}>
            Delete
          </button>
        )}
        <button
          className="section-btn-gradient"
          onClick={id === null ? addTestimonial : updateTestimonial}
        >
          Save
        </button>
      </div>
    </AdminModalWindow>
  );
};

export default ModalTestimonial;
