import React from 'react';

import debounce from 'lodash/debounce';
import './AdminSearch.scss';

interface AdminSearchProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  placeholder: string;
}

const AdminSearch: React.FC<AdminSearchProps> = ({ searchQuery, setSearchQuery, placeholder }) => {
  const TIME_OUT = 500;

  const searchItem = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.trim();
    setSearchQuery(query);
  }, TIME_OUT);

  return (
    <div className="page-section__search">
      <input
        className="seach__input"
        onChange={searchItem}
        placeholder={placeholder}
        defaultValue={searchQuery}
      />
    </div>
  );
};

export default AdminSearch;
