import React, { useEffect, useState } from 'react';

import AdminRemoveIcon from '../../../../general/assets/svg/AdminRemoveIcon';
import EditIconAction from '../../../../general/assets/svg/EditIconAction';
import PlusIcon from '../../../../general/assets/svg/PlusIcon';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import createFandQService from '../../../../general/services/FandQ';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import { ModalQuestionAndAnswer } from './ModalQuestionAndAnswer';
import { ModalQuestionAndAnswerGroup } from './ModalQuestionAndAnswerGroup';

interface QuestionAnswer {
  id: string;
  question: string;
}

interface FAQGroup {
  id: string;
  name: string;
  questionAnswers: QuestionAnswer[];
}

const AdminFrequentlyAskedQuestion: React.FC = () => {
  const [isAddGroup, setIsAddGroup] = useState<boolean>(false);
  const [editGroup, setEditGroup] = useState<FAQGroup | null>(null);

  const [isAddQuestion, setIsAddQuestion] = useState<boolean>(false);
  const [editquestionAnswer, isEditQuestionAndAnswer] = useState<any>(null);

  const {
    data: questionAndAnswer,
    isLoading: loadingF,
    refetch: getF,
  } = useCustomQuery<FAQGroup[]>(queryKeys.ADMIN_F_GROUP, () =>
    createFandQService(adminInstance).getFAQGroup(),
  );

  const { mutate: deleteFAQGroup } = useCustomMutation(
    createFandQService(adminInstance).deleteFAQGroup,
    undefined,
    {
      onSuccess() {
        getF();
      },
    },
  );

  const { mutate: deleteFAQ } = useCustomMutation(
    createFandQService(adminInstance).deleteFAQ,
    undefined,
    {
      onSuccess() {
        getF();
      },
    },
  );

  const isOpenFAQ = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsAddQuestion(true);
    isEditQuestionAndAnswer({ gropId: e.currentTarget.dataset.id });
  };

  const deleteGroup = async (e: React.MouseEvent<HTMLButtonElement>) => {
    deleteFAQGroup(e.currentTarget.dataset.id as string);
  };

  const deleteQuestionAnswer = async (e: React.MouseEvent<HTMLButtonElement>) => {
    deleteFAQ(e.currentTarget.dataset.id as string);
  };

  const openUpdateGroup = (e: React.MouseEvent<HTMLButtonElement>) => {
    setEditGroup(questionAndAnswer?.find((item) => item.id === e.currentTarget.dataset.id) || null);
    setIsAddGroup(true);
  };

  const openUpdateFAQ = (e: React.MouseEvent<HTMLButtonElement>) => {
    const group = questionAndAnswer?.find((item) => item.id === e.currentTarget.dataset.group);
    const result: any = group?.questionAnswers.find(
      (item) => item.id === e.currentTarget.dataset.id,
    );
    if (result) {
      result['gropId'] = e.currentTarget.dataset.group;
      isEditQuestionAndAnswer(result);
      setIsAddQuestion(true);
    }
  };

  useEffect(() => {
    if (!isAddGroup && !isAddQuestion) {
      getF();
      setEditGroup(null);
      isEditQuestionAndAnswer(null);
    }
  }, [getF, isAddGroup, isAddQuestion]);

  return (
    <>
      {isAddQuestion && (
        <ModalQuestionAndAnswer isOpen={setIsAddQuestion} faq={editquestionAnswer} />
      )}
      {isAddGroup && <ModalQuestionAndAnswerGroup isOpen={setIsAddGroup} group={editGroup} />}
      <div className="page-section__content">
        <div className="page-section__buttons">
          <button onClick={() => setIsAddGroup(true)}>
            <PlusIcon stroke="white" />
            <span>Add Group</span>
          </button>
        </div>
        <div className="page-section__content__list-section">
          {loadingF && (
            <div className="page-update-overlay">
              <Preloader />
            </div>
          )}
          <div className="page-section__content__groups">
            {questionAndAnswer?.length === 0 ? (
              <h2 className="page-section__content__groups__empty">No FAQ</h2>
            ) : (
              <>
                {questionAndAnswer?.map((group, index) => (
                  <div key={index} className="page-section__content__group">
                    <div className="page-section__content__group__header">
                      <div className="page-section__content__group__header__name">{group.name}</div>
                      <div className="page-section__content__group__header__action">
                        <button data-id={group?.id} onClick={isOpenFAQ}>
                          <PlusIcon />
                        </button>
                        <button
                          data-id={group?.id}
                          className="page-section__content__group-action__edit"
                          onClick={openUpdateGroup}
                        >
                          <EditIconAction />
                        </button>
                        <button data-id={group?.id} onClick={deleteGroup}>
                          <AdminRemoveIcon />
                        </button>
                      </div>
                    </div>
                    <div className="page-section__content__group__content">
                      {group?.questionAnswers?.length === 0 ? (
                        <div className="page-section__content__group__content__empty">
                          Question group is empty
                        </div>
                      ) : (
                        <ul className="page-section__content__group__content__list">
                          {group?.questionAnswers.map((questionAnswer, index) => (
                            <li key={index}>
                              <div className="group__content__list__info">
                                Q: {questionAnswer.question}
                              </div>
                              <div className="group__content__list__action">
                                <button
                                  data-group={group?.id}
                                  data-id={questionAnswer.id}
                                  onClick={openUpdateFAQ}
                                >
                                  <EditIconAction />
                                </button>
                                <button data-id={questionAnswer.id} onClick={deleteQuestionAnswer}>
                                  <AdminRemoveIcon />
                                </button>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminFrequentlyAskedQuestion;
