import { useState, useCallback } from 'react';

interface Errors {
  name: string;
  nickname: string;
  description: string;
  img: string;
  firstLinkName: string;
  firstLinkUrl: string;
  notCorrectSocialLink: string;
  referralCode: string;
  affiliate: string;
  lastName: string;
  email: string;
  phone: string;
  company: string;
  street1: string;
  street2: string;
  street3: string;
  streetNumber: string;
  country: string;
  city: string;
  state: string;
  zip: string;
}

type SetError = (key: keyof Errors, message: string) => void;

const useRequired = () => {
  const [errors, setErrors] = useState<Errors>({
    name: '',
    nickname: '',
    description: '',
    img: '',
    firstLinkName: '',
    firstLinkUrl: '',
    notCorrectSocialLink: '',
    referralCode: '',
    affiliate: '',
    lastName: '',
    email: '',
    phone: '',
    company: '',
    street1: '',
    street2: '',
    street3: '',
    streetNumber: '',
    country: '',
    city: '',
    state: '',
    zip: '',
  });

  const setError: SetError = useCallback((key, message) => {
    setErrors((prevErrors) => ({ ...prevErrors, [key]: message }));

    setTimeout(() => {
      setErrors((prevErrors) => ({ ...prevErrors, [key]: '' }));
    }, 2000);
  }, []);

  return { errors, setError };
};

export default useRequired;
