import './AdminList.scss';

import React, { useEffect, useRef, useState } from 'react';

import DropDownIcon from '../../../../general/assets/svg/DropDownIcon';

interface RoleSelectorProps {
  roles: string[];
  currentStatus: string;
}

const RoleSelector: React.FC<RoleSelectorProps> = ({ roles, currentStatus }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(currentStatus);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="role-status" ref={dropdownRef}>
      <div className="role-status-select" onClick={() => setIsOpen(!isOpen)}>
        <div className="role-status__current-options">
          <div className="role-payment-status__current-options__name">{selectedOption}</div>
          <DropDownIcon />
        </div>
      </div>
      {isOpen && (
        <div className="role-status__list-options">
          <ul>
            {roles.map((option, index) => (
              <li
                key={index}
                className="role-status__list-options__option"
                onClick={() => handleOptionClick(option)}
                style={{
                  backgroundColor: selectedOption === option ? '#0F1928' : '',
                }}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default RoleSelector;
