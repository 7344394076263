import { AxiosInstance } from 'axios';

interface PaymentService {
  getAllCurrencies: () => Promise<any>;
  createTransaction: (body: any) => Promise<any>;
  getTransactionStatus: (body: any) => Promise<any>;
  getBankTransferDetails: () => Promise<any>;
}

const createPaymentService = (instance: AxiosInstance): PaymentService => ({
  // not use
  async getAllCurrencies() {
    try {
      const response = await instance.get(`/Payment/getCurrencies`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async createTransaction(body: any) {
    try {
      const response = await instance.post(`/Payment/createTransaction`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getTransactionStatus(body: any) {
    try {
      const response = await instance.post(`/Payment/getPaymentStatus`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getBankTransferDetails() {
    try {
      const response = await instance.get(`/BankTranksefDetails`);
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createPaymentService;
