import React from 'react';

const CloseModalCrossIcon: React.FC = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1.2135L11 11.2135M1 11.2135L11 1.2135"
      stroke="#25A8CF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CloseModalCrossIcon;
