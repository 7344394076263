import React, { ChangeEvent, DragEvent, useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { setReciept } from '../../../../../general/redux/reducers/ClientCartProcessing-Reducer';
import { setInfo, setType, types } from '../../../../../general/redux/reducers/Error-Reducer';
import createClientService from '../../../../../general/services/client';
import { clientInstance } from '../../../../../general/services/main/axiosInstances';

interface DragAndDropProps {
  onFileDrop: (file: File) => void;
  preview: string | null;
  setPreview: (preview: string | null) => void;
  onClick: () => void;
}

const DragAndDropCheck: React.FC<DragAndDropProps> = ({
  onFileDrop,
  preview,
  setPreview,
  onClick,
}) => {
  const dispatch = useDispatch();
  const [dragging, setDragging] = useState<boolean>(false);

  const handleDragEnter = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  }, []);

  const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  }, []);

  const handleDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);

      const files = e.dataTransfer.files;
      if (files && files.length > 0) {
        const file = files[0];
        if (file.type.startsWith('image/') || file.type === 'application/pdf') {
          if (file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setPreview(reader.result as string);
            };
            reader.readAsDataURL(file);
          }
          onFileDrop(file);
        }
        e.dataTransfer.clearData();
      }
    },
    [onFileDrop, setPreview],
  );

  const handleRemoveImage = () => {
    setPreview(null);
    onFileDrop(null as any);
    dispatch(setReciept(null));
  };

  return (
    <div
      className={`drop-zone ${dragging ? 'dragging' : ''}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={onClick}
    >
      {preview ? (
        <div className="image-preview-container">
          <img src={preview} alt="Preview" className="preview-image" />
          <button className="remove-image-button" onClick={handleRemoveImage}>
            &times;
          </button>
        </div>
      ) : (
        <div className="drop-message">
          <p>
            {dragging
              ? 'Release to drop the file here'
              : 'Drag & drop your payment receipt image or PDF here, or click here to select file'}
          </p>
        </div>
      )}
    </div>
  );
};

const PaymentReceiptUpload: React.FC = () => {
  const dispatch = useDispatch();

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const [isImg, setIsImg] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);

  const handleFileDrop = (file: File) => {
    setSelectedFile(file);
  };

  const { mutate: uploadReciept } = useMutation(createClientService(clientInstance).uploadReciept, {
    onSuccess: (data) => {
      dispatch(setReciept(data.data.receiptId));
    },
  });

  useEffect(() => {
    selectedFile && uploadReciept({ image: selectedFile, isImg });
  }, [uploadReciept, selectedFile, isImg]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && (file.type.startsWith('image/') || file.type === 'application/pdf')) {
      setIsImg(file.type.startsWith('image/'));

      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result as string);
          handleFileDrop(file);
        };
        reader.readAsDataURL(file);
      } else {
        handleFileDrop(file);
      }
    } else {
      dispatch(setType(types.WARNING));
      dispatch(
        setInfo({
          n: types.WARNING,
          r: { s: 'You can upload only images or pdf' },
        }),
      );
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div id="reciept" className="payment-receipt-upload">
      <h2>Upload Payment Receipt</h2>
      <DragAndDropCheck
        onFileDrop={handleFileDrop}
        preview={preview}
        setPreview={setPreview}
        onClick={handleButtonClick}
      />
      <input
        type="file"
        accept="image/*,application/pdf"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      {selectedFile && (
        <div className="file-info">
          <p>
            <span style={{ color: '#25a8cf' }}>Selected file</span> {selectedFile.name}
          </p>
        </div>
      )}
    </div>
  );
};

export default PaymentReceiptUpload;
