interface Link {
  id: number;
  name: string;
  url: string;
}

interface UpdateAction {
  type: 'update';
  id: number;
  field: keyof Link;
  value: string;
}

interface SwapAction {
  type: 'swap';
  payload: { from: number; to: number };
}

interface AddAction {
  type: 'add';
}

interface RemoveAction {
  type: 'remove';
  id: number;
}

type Action = UpdateAction | SwapAction | AddAction | RemoveAction;

export const linksReducer = (state: Link[], action: Action): Link[] => {
  switch (action.type) {
    case 'add':
      return [...state, { id: state.length, name: '', url: '' }];
    case 'update':
      return state.map((link) =>
        link.id === action.id ? { ...link, [action.field]: action.value } : link,
      );
    case 'remove':
      return state.length > 1 ? state.filter((link) => link.id !== action.id) : state;
    case 'swap':
      const newState = [...state];
      const { from, to } = action.payload;
      [newState[from], newState[to]] = [newState[to], newState[from]];
      return newState;
    default:
      throw new Error('Unknown action type');
  }
};
