import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AdminState {
  a4d: string;
  a4cd: Record<string, any>;
}

const initialState: AdminState = {
  a4d: '',
  a4cd: {},
};

const AdminSlice = createSlice({
  name: 'a4',
  initialState,
  reducers: {
    setAdminAuth: (state, action: PayloadAction<string>) => {
      state.a4d = action.payload;
    },
    setAdminData: (state, action: PayloadAction<Record<string, any>>) => {
      state.a4cd = action.payload;
    },
    logOutAdmin: (state) => {
      state.a4d = '';
      state.a4cd = {};
    },
  },
});

export const { setAdminAuth, setAdminData, logOutAdmin } = AdminSlice.actions;
export default AdminSlice.reducer;
