import React from 'react';

const ShareIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4247_250)">
      <path
        d="M19.64 7.22C21.2196 7.22 22.5 5.93953 22.5 4.36C22.5 2.78047 21.2196 1.5 19.64 1.5C18.0605 1.5 16.78 2.78047 16.78 4.36C16.78 5.93953 18.0605 7.22 19.64 7.22Z"
        stroke="#25A8CF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M4.36 14.8599C5.93953 14.8599 7.22 13.5794 7.22 11.9999C7.22 10.4204 5.93953 9.13989 4.36 9.13989C2.78047 9.13989 1.5 10.4204 1.5 11.9999C1.5 13.5794 2.78047 14.8599 4.36 14.8599Z"
        stroke="#25A8CF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M19.64 22.5C21.2196 22.5 22.5 21.2196 22.5 19.64C22.5 18.0605 21.2196 16.78 19.64 16.78C18.0605 16.78 16.78 18.0605 16.78 19.64C16.78 21.2196 18.0605 22.5 19.64 22.5Z"
        stroke="#25A8CF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M17.0799 5.63989L6.91992 10.7199"
        stroke="#25A8CF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M17.0799 18.36L6.91992 13.28"
        stroke="#25A8CF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_4247_250">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ShareIcon;
