import React from 'react';

interface FAQSchemaProps {
  items: {
    question: string;
    answer: string;
  }[];
}

const FAQSchema: React.FC<FAQSchemaProps> = ({ items }) => {
  const faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: items.map((item) => ({
      '@type': 'Question',
      name: item.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.answer,
      },
    })),
  };

  return <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>;
};

export default FAQSchema;
