import './FAQandQA.scss';

import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import createHelpCenterServiceService from '../../../../general/services/helpCenter';
import { clientInstance } from '../../../../general/services/main/axiosInstances';
import CanonicalLink from '../../../components/SEO/CanonicalLink';
import FAQSchema from '../../../components/SEO/FAQSchema';
import QuestionItem from './QuestionItem';

export interface Question {
  question: string;
  answer: string;
}

export interface QuestionGroup {
  id: number;
  name: string;
  questionAnswers: Question[];
}

const FAQ = () => {
  const navigate = useNavigate();

  const { data: faqs, isLoading: faqsLoading } = useCustomQuery<QuestionGroup[]>(
    queryKeys.FAQS,
    () => createHelpCenterServiceService(clientInstance).getAllFAQ(),
    undefined,
    () => {
      navigate('/');
    },
  );

  // Состояние для отслеживания ID открытого вопроса
  const [openQuestionId, setOpenQuestionId] = useState<string | null>(null);

  // Обработчик для открытия/закрытия вопросов
  const handleToggle = (questionId: string) => {
    setOpenQuestionId((prev) => (prev === questionId ? null : questionId));
  };

  const faqItems = useMemo(
    () =>
      faqs
        ? faqs.flatMap((group) =>
            group.questionAnswers.map((qa) => ({
              question: qa.question,
              answer: qa.answer,
            })),
          )
        : [],
    [faqs],
  );

  return (
    <section className="help-center__aq page-container">
      <div className="help-center__aq__title">
        Frequently Asked <span className="faq__span">Questions</span> (FAQ)
      </div>
      {faqsLoading ? (
        <div className="help-center__aq__preloader">
          <Preloader />
        </div>
      ) : faqs ? (
        <>
          <CanonicalLink href={window.location.href} />
          <FAQSchema items={faqItems} />
          {faqs.map((group, index) => (
            <div key={index} className="help-center__aq__question-group">
              <div className="help-center__aq__question-group__title">{group.name}</div>
              {group.questionAnswers.length === 0 ? (
                <div className="help-center__aq__question-group__empty">
                  <Preloader />
                </div>
              ) : (
                group.questionAnswers.map((question, qIndex) => {
                  const questionId = `${group.id}-${qIndex}`;
                  return (
                    <QuestionItem
                      key={qIndex}
                      question={question}
                      isOpen={openQuestionId === questionId}
                      onToggle={() => handleToggle(questionId)}
                    />
                  );
                })
              )}
            </div>
          ))}
        </>
      ) : (
        <p>No FAQs available</p>
      )}
    </section>
  );
};

export default FAQ;
