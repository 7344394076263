import './BreadCrumbs.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import HomeIcon from '../../../general/assets/svg/HomeIcon';

interface Crumb {
  title: string;
  path: string;
}

interface BreadCrumbsProps {
  crumbs: Crumb[];
}

const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ crumbs }) => {
  if (crumbs.length <= 1) {
    // Не отображаем хлебные крошки для корневой страницы
    return null;
  }

  const staticName = (name: string): string => {
    switch (name) {
      case 'Qa':
        return 'Q&A';
      case 'Faq':
        return 'FAQ';
      default:
        return name;
    }
  };

  // URLs where there are no pages
  const staticUrl = (url: string): string | undefined => {
    switch (url) {
      case '/products':
        return '';
      case '/help-center':
        return '';
      default:
        return url;
    }
  };

  return (
    <nav className="breadcrumb__container" aria-label="breadcrumb">
      <ol className="breadcrumb">
        {crumbs.map((crumb, index) => (
          <div key={index} style={{ display: 'flex' }}>
            <li className={`breadcrumb-item ${index === crumbs.length - 1 ? 'active' : ''}`}>
              {index === crumbs.length - 1 ? (
                <span>{staticName(crumb.title)}</span>
              ) : (
                <Link to={staticUrl(crumb.path) || ''}>
                  {index === 0 ? <HomeIcon /> : crumb.title}
                </Link>
              )}
            </li>
            <span style={{ padding: '0 10px' }}>/</span>
          </div>
        ))}
      </ol>
    </nav>
  );
};

export default BreadCrumbs;
