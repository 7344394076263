import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';

import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../general/queryKeys';
import { adminInstance } from '../../../general/services/main/axiosInstances';
import createMessagesService from '../../../general/services/messages';

const AdminMessages: React.FC = () => {
  const { data: newMessagesCount } = useCustomQuery<number>(queryKeys.MESSAGES_COUNT_ADMIN, () =>
    createMessagesService(adminInstance).getMessageCount(),
  );

  const { data: newSimpleMessagesCount } = useCustomQuery<number>(
    queryKeys.SIMPLE_MESSAGES_COUNT_ADMIN,
    () => createMessagesService(adminInstance).getSimpleMessageCount(),
  );

  return (
    <div className="page-section">
      <div className="page-section__top-tabs">
        <NavLink
          end
          to="/admin/messages"
          className={({ isActive }) =>
            'page-section__top__tab' + (isActive ? ' page-active-tab' : '')
          }
        >
          Messages
          {newMessagesCount !== undefined && newMessagesCount > 0 && (
            <span className="new-message">{newMessagesCount}</span>
          )}
        </NavLink>
        <NavLink
          to="contact-us"
          className={({ isActive }) =>
            'page-section__top__tab' + (isActive ? ' page-active-tab' : '')
          }
        >
          Contact us
          {newSimpleMessagesCount !== undefined && newSimpleMessagesCount > 0 && (
            <span className="new-message">{newSimpleMessagesCount}</span>
          )}
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default AdminMessages;
