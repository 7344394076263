import React from 'react';

const LogOutIconClient: React.FC = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.37587 24.9119H8.52777C13.7157 24.9119 16.2162 22.8671 16.6485 18.2868C16.6952 17.8077 16.3447 17.3754 15.854 17.3286C15.3866 17.2819 14.9426 17.6441 14.8958 18.1232C14.557 21.7921 12.8277 23.1592 8.51608 23.1592H8.36419C3.60859 23.1592 1.92602 21.4766 1.92602 16.721V9.1027C1.92602 4.34711 3.60859 2.66454 8.36419 2.66454H8.51608C12.851 2.66454 14.5803 4.055 14.8958 7.79404C14.9542 8.2731 15.3632 8.63532 15.854 8.58859C16.3447 8.55353 16.6952 8.12121 16.6602 7.64214C16.2629 2.99171 13.7507 0.911865 8.52777 0.911865H8.37587C2.63878 0.911865 0.185032 3.36561 0.185032 9.1027V16.721C0.185032 22.4581 2.63878 24.9119 8.37587 24.9119Z"
      fill="white"
    />
    <path
      d="M8.6578 13.7886H21.9547C22.4338 13.7886 22.8311 13.3913 22.8311 12.9122C22.8311 12.4332 22.4338 12.0359 21.9547 12.0359H8.6578C8.17873 12.0359 7.78146 12.4332 7.78146 12.9122C7.78146 13.3913 8.17873 13.7886 8.6578 13.7886Z"
      fill="white"
    />
    <path
      d="M19.3485 17.7024C19.5705 17.7024 19.7925 17.6206 19.9678 17.4454L23.8821 13.531C24.2209 13.1922 24.2209 12.6313 23.8821 12.2925L19.9678 8.37816C19.6289 8.03931 19.0681 8.03931 18.7292 8.37816C18.3904 8.71701 18.3904 9.27787 18.7292 9.61672L22.0243 12.9118L18.7292 16.2068C18.3904 16.5456 18.3904 17.1065 18.7292 17.4454C18.8928 17.6206 19.1265 17.7024 19.3485 17.7024Z"
      fill="white"
    />
  </svg>
);

export default LogOutIconClient;
