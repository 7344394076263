import './AdminArticle.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import useRequired from '../../../../general/hooks/useRequired';
import { queryKeys } from '../../../../general/queryKeys';
import { setInfo, setType, types } from '../../../../general/redux/reducers/Error-Reducer';
import createArticlesService from '../../../../general/services/articles';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import TextEditor, { sendImgSettingsConstatns } from './Editor/TextEditor';

// Types
interface ImageProps {
  url: string | ArrayBuffer | null;
  file?: File;
}

interface AdminArticleProps {
  isEdit: boolean;
}

// ImageComponent
const ImageComponent = (
  img: string,
  setImage: React.Dispatch<React.SetStateAction<ImageProps | null>>,
) => {
  const deleteImg = () => {
    setImage(null);
  };

  return (
    <div
      onClick={deleteImg}
      className="selected-img"
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
      }}
      aria-label="Выбранное изображение"
    />
  );
};

// Main Component
const AdminArticle: React.FC<AdminArticleProps> = ({ isEdit }) => {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editor = useRef<any>(null);
  const save = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [namePost, setNamePost] = useState<string>('');
  const [statusPost, setStatusPost] = useState<boolean>(true);
  const [image, setImage] = useState<ImageProps | null>(null);
  const { errors, setError } = useRequired();

  const { refetch: getArticle } = useCustomQuery(
    queryKeys.CURRENT_ARTICLE,
    () => createArticlesService(adminInstance).getEditArticle(id as string),
    undefined,
    undefined,
    {
      enabled: false,
      keepPreviousData: false,
      onSuccess: (data: any) => {
        setNamePost(data.title);
        editor.current.fromHTML(data.description);
        setStatusPost(data.isActive);

        setImage({
          url: data.imageUrl,
          file: undefined,
        });
      },
    },
  );

  useEffect(() => {
    if (isEdit && id) {
      getArticle();
    }
  }, [isEdit, id, getArticle]);

  // Send article to server
  const handleSaveClick = async () => {
    if (namePost === '') {
      setError('name', 'Name is required');
      return;
    }

    const parser = new DOMParser();
    const doc = editor.current.toHTML();
    const parsedDoc = parser.parseFromString(doc, 'text/html');
    const content = parsedDoc.body.innerHTML;
    const textContent = parsedDoc.body.textContent;

    if (textContent === '' || textContent === undefined) {
      setError('description', 'Description is required');
      return;
    }

    if (image === null || image === undefined) {
      setError('img', 'Image is required');
      return;
    }

    setIsLoading(true);
    try {
      if (isEdit) {
        // Edit existing article
        await createArticlesService(adminInstance).putArticle({
          id: id as string,
          title: namePost,
          description: content,
          content: content,
        });

        await createArticlesService(adminInstance).putArticleActivity({
          id: id as string,
          isActive: statusPost,
        });

        if (image.file !== undefined) {
          await createArticlesService(adminInstance).post_putArticlesImg({
            id: id as string,
            image: image.file,
            isEdit: isEdit,
          });
        }

        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'Article successfully changed' },
          }),
        );
      } else {
        // Create new article
        const response = await createArticlesService(adminInstance).postArticles({
          title: namePost,
          description: content,
          content: content,
          isActive: statusPost,
        });

        if (response.name === 'AxiosError') {
          dispatch(setType(types.ERROR));
          dispatch(
            setInfo({
              n: response.message,
              r: Object.prototype.hasOwnProperty.call(response, 'response')
                ? {
                    d: response.response.data,
                    s: response.response.statusText,
                  }
                : '',
            }),
          );
        } else {
          await createArticlesService(adminInstance).post_putArticlesImg({
            id: response,
            image: image.file,
            isEdit: isEdit,
          });

          navigate('/admin/articles');
        }
      }
    } catch (error) {
      // Handle any errors that occur during the async operations
      dispatch(setType(types.ERROR));
      dispatch(
        setInfo({
          n: 'An error occurred',
          r: { s: 'An unknown error occurred' },
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value === 'true';
    setStatusPost(newValue);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const errors: { f: string; s: string }[] = [];
    const selectedImage = event.target.files?.[0];

    if (selectedImage) {
      const sizeInMB = selectedImage.size / 1024; // размер в мегабайтах
      if (sizeInMB > 200) {
        errors.push({ f: selectedImage.name, s: sizeInMB.toFixed(2) });
      }

      if (errors.length > 0) {
        dispatch(setType(types.WARNING));
        dispatch(
          setInfo({
            n: 'Uploading images',
            r: {
              d: '',
              s: `Some images weigh more than 120 kB.\n\n${errors
                .map((error) => `${error.f} - ${error.s} kB`)
                .join('\n')}`,
            },
          }),
        );
      } else {
        const reader = new FileReader();

        reader.onloadend = () => {
          // При успешном чтении изображения, установите его в состояние
          setImage({
            url: reader.result,
            file: selectedImage,
          });
        };
        reader.readAsDataURL(selectedImage);
      }
    }
  };

  // Styles
  const divChoseImgRegionTopMargin: React.CSSProperties = {
    marginTop: '15px',
  };

  const divChoseImgPadings: React.CSSProperties = {
    padding: '20px 0px 40px 0px',
  };

  const divStatusRadio: React.CSSProperties = {
    padding: '10px',
  };

  const pImageHeader: React.CSSProperties = {
    margin: '0px',
    padding: '20px 0px 0px 30px',
  };

  return (
    <div className="page-section">
      <div className="page-section__top">
        <div className="page-section__top__title">
          {isEdit ? 'ARTICLE EDITING' : 'ARTICLE CREATION'}
        </div>
      </div>
      <div className="page-section__conten__section">
        <div className="block">
          <div className="postName">
            <label className="input-header">Name Post</label>
            <input
              placeholder={errors.name ? errors.name : ''}
              className={errors.name ? 'error_required' : ''}
              value={namePost}
              onChange={(event) => setNamePost(event.target.value)}
              id="post_name"
              type="text"
              required
            />
          </div>
          <TextEditor
            ref={editor}
            errorDescriptionRequired={errors.description}
            sendImgSettings={sendImgSettingsConstatns.article}
            isNeedVideo={true}
          />
        </div>
        <div className="block">
          <div className="status_field">
            <div className="status_title">Status</div>
            <div style={divStatusRadio} className="status_radio">
              <div className="radio">
                <input
                  type="radio"
                  name="status"
                  value="true"
                  checked={statusPost === true}
                  onChange={handleRadioChange}
                />
                <label className="status">Active</label>
              </div>
              <div className="radio">
                <input
                  type="radio"
                  name="status"
                  value="false"
                  checked={statusPost === false}
                  onChange={handleRadioChange}
                />
                <label className="status">Disabled</label>
              </div>
            </div>
          </div>
          <div style={divChoseImgRegionTopMargin} className="image_field">
            <p style={pImageHeader}>Image</p>
            <div style={divChoseImgPadings}>
              <div className={errors.img ? 'chose_img error_required' : 'chose_img'}>
                {image ? (
                  ImageComponent(image.url as string, setImage)
                ) : (
                  <div
                    className="plus"
                    onClick={() => document.getElementById('fileInput')?.click()}
                  >
                    <input
                      id="fileInput"
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{ display: 'none' }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="block">
          <div
            ref={save}
            onClick={() => handleSaveClick()}
            className={`buttonSave ${isLoading ? 'disabledSave' : ''}`}
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminArticle;
