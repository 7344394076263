import React from 'react';

interface DropDownIconProps {
  dropDownBlock?: () => void;
  className?: string;
  style?: any;
}

const DropDownIcon: React.FC<DropDownIconProps> = ({ dropDownBlock, className, style }) => (
  <svg
    onClick={dropDownBlock}
    className={className}
    style={style}
    width="18"
    height="11"
    viewBox="0 0 18 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2891 1.05948L9.28906 9.05948L1.28906 1.05948"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default DropDownIcon;
