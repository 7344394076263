import React from 'react';

const ProfileHomeIcon: React.FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.4009 29.0357C31.5655 29.0357 34.1309 26.5847 34.1309 23.5612C34.1309 20.5377 31.5655 18.0867 28.4009 18.0867C25.2363 18.0867 22.6709 20.5377 22.6709 23.5612C22.6709 26.5847 25.2363 29.0357 28.4009 29.0357Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M17.9009 39.0676L18.2709 37.109C18.708 34.847 19.9606 32.8028 21.8112 31.3314C23.6618 29.8601 25.9933 29.0545 28.4009 29.0548C30.8113 29.0554 33.1451 29.8636 34.9961 31.3387C36.8471 32.8139 38.0979 34.8626 38.5309 37.1281L38.9009 39.0867"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <rect
      x="1.00088"
      y="1.16665"
      width="54.8"
      height="54.8"
      rx="27.4"
      stroke="white"
      strokeWidth="1.2"
    />
  </svg>
);

export default ProfileHomeIcon;
