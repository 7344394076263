import { useCallback, useState } from 'react';

const usePreloader = () => {
  const [isPreloader, setIsPreloader] = useState(false);

  const showPreloader = useCallback(() => setIsPreloader(true), []);
  const hidePreloader = useCallback(() => setIsPreloader(false), []);

  return { isPreloader, showPreloader, hidePreloader };
};

export default usePreloader;
