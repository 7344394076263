import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Client } from '../../types/types';

interface ClientsTrackingState {
  clients: Client[];
}

const initialState: ClientsTrackingState = {
  clients: [],
};

const ClientsTracking = createSlice({
  name: 'clientsTracking',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<Client[]>) => {
      state.clients = action.payload;
    },
  },
});

export const { init } = ClientsTracking.actions;
export default ClientsTracking.reducer;
