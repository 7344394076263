import React from 'react';

const ChooseCategoriIcon: React.FC = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.91 2H11.09C10.8138 2 10.59 2.22386 10.59 2.5V5.32C10.59 5.59614 10.8138 5.82 11.09 5.82H13.91C14.1861 5.82 14.41 5.59614 14.41 5.32V2.5C14.41 2.22386 14.1861 2 13.91 2Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M5.32 2H2.5C2.22386 2 2 2.22386 2 2.5V5.32C2 5.59614 2.22386 5.82 2.5 5.82H5.32C5.59614 5.82 5.82 5.59614 5.82 5.32V2.5C5.82 2.22386 5.59614 2 5.32 2Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M22.5001 2H19.6801C19.4039 2 19.1801 2.22386 19.1801 2.5V5.32C19.1801 5.59614 19.4039 5.82 19.6801 5.82H22.5001C22.7762 5.82 23.0001 5.59614 23.0001 5.32V2.5C23.0001 2.22386 22.7762 2 22.5001 2Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M13.91 10.59H11.09C10.8138 10.59 10.59 10.8139 10.59 11.09V13.91C10.59 14.1861 10.8138 14.41 11.09 14.41H13.91C14.1861 14.41 14.41 14.1861 14.41 13.91V11.09C14.41 10.8139 14.1861 10.59 13.91 10.59Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M5.32 10.59H2.5C2.22386 10.59 2 10.8139 2 11.09V13.91C2 14.1861 2.22386 14.41 2.5 14.41H5.32C5.59614 14.41 5.82 14.1861 5.82 13.91V11.09C5.82 10.8139 5.59614 10.59 5.32 10.59Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M22.5001 10.59H19.6801C19.4039 10.59 19.1801 10.8139 19.1801 11.09V13.91C19.1801 14.1861 19.4039 14.41 19.6801 14.41H22.5001C22.7762 14.41 23.0001 14.1861 23.0001 13.91V11.09C23.0001 10.8139 22.7762 10.59 22.5001 10.59Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M13.91 19.18H11.09C10.8138 19.18 10.59 19.4039 10.59 19.68V22.5C10.59 22.7761 10.8138 23 11.09 23H13.91C14.1861 23 14.41 22.7761 14.41 22.5V19.68C14.41 19.4039 14.1861 19.18 13.91 19.18Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M5.32 19.18H2.5C2.22386 19.18 2 19.4039 2 19.68V22.5C2 22.7761 2.22386 23 2.5 23H5.32C5.59614 23 5.82 22.7761 5.82 22.5V19.68C5.82 19.4039 5.59614 19.18 5.32 19.18Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M22.5001 19.18H19.6801C19.4039 19.18 19.1801 19.4039 19.1801 19.68V22.5C19.1801 22.7761 19.4039 23 19.6801 23H22.5001C22.7762 23 23.0001 22.7761 23.0001 22.5V19.68C23.0001 19.4039 22.7762 19.18 22.5001 19.18Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default ChooseCategoriIcon;
