import React from 'react';

const AdminMailIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.33333 12.75L2.5 18.5833" stroke="#25A8CF" strokeWidth="1.5" strokeMiterlimit="10" />
    <path
      d="M17.1666 18.5833L11.3333 12.75"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M17.5 7.91667V18.75H2.5V7.91667L10 1.25L17.5 7.91667Z"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M17.5 7.91675L10 15.0834L2.5 7.91675"
      stroke="#25A8CF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default AdminMailIcon;
