import './Clients.scss';

import React from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';

import DropDownIcon from '../../../general/assets/svg/DropDownIcon';

const AdminClients: React.FC = () => {
  const location = useLocation();
  const isHome =
    !location.pathname.includes('clients/') || location.pathname.includes('clients/subscribers');

  return (
    <div className="page-section">
      <div
        style={{ marginTop: '10px' }}
        className="page-section__top-tabs page-section__top-tabs__clients"
      >
        {!isHome ? (
          <div>
            <NavLink
              end
              to="newsletter_workshop"
              className={({ isActive }) =>
                'page-section__top__tab' + (isActive ? ' page-active-tab' : '')
              }
            >
              Newsletter Workshop
            </NavLink>
            <NavLink
              to="recipient_list"
              className={({ isActive }) =>
                'page-section__top__tab' + (isActive ? ' page-active-tab' : '')
              }
            >
              Recipient list & Send Planner
            </NavLink>
          </div>
        ) : (
          <div>
            <NavLink
              end
              to="/admin/clients"
              className={({ isActive }) =>
                'page-section__top__tab' + (isActive ? ' page-active-tab' : '')
              }
            >
              Clients
            </NavLink>
            <NavLink
              to="subscribers"
              className={({ isActive }) =>
                'page-section__top__tab' + (isActive ? ' page-active-tab' : '')
              }
            >
              Subscribers
            </NavLink>
          </div>
        )}
        {isHome && (
          <div className="page-section__buttons">
            <div>
              <button>
                <DropDownIcon />
                <span>Add newsletter</span>
              </button>
              <ul className="drop_down">
                <NavLink end to="newsletter_workshop">
                  <li>
                    <span>Newsletter Workshop</span>
                  </li>
                </NavLink>
                <NavLink end to="recipient_list">
                  <li>
                    <span>Recipient list & Send Planner</span>
                  </li>
                </NavLink>
              </ul>
            </div>
          </div>
        )}
      </div>
      <Outlet />
    </div>
  );
};

export default AdminClients;
