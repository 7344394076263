import { createContext } from 'react';

export type Theme = 'dark' | 'light';

export const themes = {
  dark: 'dark' as Theme,
  light: 'light' as Theme, // 'light' theme
};

export interface ThemeContextType {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

export const ThemeContext = createContext<ThemeContextType | undefined>(undefined);
