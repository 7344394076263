import React from 'react';

const PersonalDataIcon: React.FC<{ dropDownBlock: () => void }> = ({ dropDownBlock }) => (
  <svg
    onClick={dropDownBlock}
    width="21"
    height="21"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3431_363)">
      <path
        d="M6.26224 5.89167C7.64755 5.89167 8.77057 4.76865 8.77057 3.38333C8.77057 1.99802 7.64755 0.875 6.26224 0.875C4.87693 0.875 3.75391 1.99802 3.75391 3.38333C3.75391 4.76865 4.87693 5.89167 6.26224 5.89167Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M1.53125 11.4576L1.93958 9.43339C2.13914 8.43374 2.67886 7.53408 3.46692 6.88747C4.25497 6.24086 5.2427 5.88723 6.26208 5.88673C7.23354 5.88478 8.17786 6.20708 8.94542 6.80256"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M10.4382 13.1245C12.2842 13.1245 13.7807 11.628 13.7807 9.78195C13.7807 7.93594 12.2842 6.43945 10.4382 6.43945C8.59219 6.43945 7.0957 7.93594 7.0957 9.78195C7.0957 11.628 8.59219 13.1245 10.4382 13.1245Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M12.1133 8.66797L10.1649 10.6163L9.05078 9.50797"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_3431_363">
        <rect width="14" height="14" fill="white" transform="translate(0.65625)" />
      </clipPath>
    </defs>
  </svg>
);

export default PersonalDataIcon;
