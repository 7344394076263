import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';

const AdminAdmins: React.FC = () => {
  return (
    <div className="page-section">
      <div className="page-section__top-tabs">
        <NavLink
          end
          to="/admin/admins"
          className={({ isActive }) =>
            'page-section__top__tab' + (isActive ? ' page-active-tab' : '')
          }
        >
          Admins
        </NavLink>
        <NavLink
          to="emails"
          className={({ isActive }) =>
            'page-section__top__tab' + (isActive ? ' page-active-tab' : '')
          }
        >
          Emails
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default AdminAdmins;
