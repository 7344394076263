import './CustomTable.scss';

import React from 'react';

import Preloader from '../../../../general/components/preloader/Preloader';
import VariableDataTd from './VariableDataTd';

interface CustomTableProps {
  data: any[];
  headers: string[];
  refreshMethod?: (item: any) => void;
  deleteMethod?: (item: any) => void;
  isAction?: boolean;
  nameOfAction?: string;
  actionComponent?: (
    item: any,
    deleteMethod?: (item: any) => void,
    refreshMethod?: (item: any) => void,
  ) => React.ReactNode;
  children?: React.ReactNode;
  color?: string;
  isMobile?: boolean;
  mobHeader?: string;
}

const CustomTable: React.FC<CustomTableProps> = ({
  data,
  headers,
  refreshMethod,
  deleteMethod = undefined,
  isAction = true,
  nameOfAction = undefined,
  actionComponent,
  color = undefined,
  isMobile,
}) => {
  return (
    <div className="table-section">
      <table className="table-block" id="myTable">
        <thead className="table-block__thead">
          <tr className="table-block__thead__row">
            {!isMobile ? (
              <>
                {headers.map((header) => (
                  <th key={header}>{header}</th>
                ))}
                {isAction && <th>{nameOfAction !== undefined ? `${nameOfAction}` : 'Action'}</th>}
              </>
            ) : (
              <th colSpan={100}>{headers.join(' / ')}</th>
            )}
          </tr>
        </thead>
        <tbody className="table-block__tbody">
          {!isMobile ? (
            <>
              {data === null ? (
                <tr className="table-block__tbody__loading">
                  <td colSpan={headers.length + 1}>
                    <div className="tbody__loadred">
                      <Preloader />
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {data?.length === 0 ? (
                    <tr className="table-block__tbody__empty-row">
                      <td colSpan={headers.length + 1}>No data</td>
                    </tr>
                  ) : (
                    <>
                      {data?.map((item, index) => (
                        <tr
                          key={index}
                          style={{
                            background:
                              color !== undefined && (index + 1) % 2 === 0
                                ? `${color}`
                                : 'transparent',
                          }}
                          className="table-block__tbody__row"
                        >
                          {headers.map((header) => (
                            <td key={header}>
                              <VariableDataTd header={header} item={item} />
                            </td>
                          ))}
                          {isAction && (
                            <td>
                              <div className="table-block__tbody__change-item">
                                {actionComponent &&
                                  (deleteMethod !== undefined
                                    ? actionComponent(item, deleteMethod, refreshMethod)
                                    : actionComponent(item, refreshMethod))}
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {data?.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    background:
                      color !== undefined && (index + 1) % 2 === 0 ? `${color}` : 'transparent',
                  }}
                  className="table-block__tbody__row"
                >
                  <td>
                    {headers
                      .map((header) => <VariableDataTd key={header} header={header} item={item} />)
                      .reduce((acc, curr) => (
                        <>
                          {acc} / {curr}
                        </>
                      ))}
                    <div style={{ marginTop: '10px' }} className="table-block__tbody__change-item">
                      {actionComponent &&
                        (deleteMethod !== undefined
                          ? actionComponent(item, deleteMethod, refreshMethod)
                          : actionComponent(item, refreshMethod))}
                    </div>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
