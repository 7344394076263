import './ProfileOrders.scss';

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import { RootState } from '../../../../general/redux/store';
import { routesClient } from '../../../../general/routes';
import createClientService from '../../../../general/services/client';
import { clientInstance } from '../../../../general/services/main/axiosInstances';
import { images } from '../../../../general/StaticData';
import { Order } from '../../../../general/types/types';
import OrderInfo from './OrderInfo';

const ProfileOrders: React.FC = () => {
  const navigate = useNavigate();
  const userId = useSelector((state: RootState) => state.clps.cp2);

  useEffect(() => {
    if (!userId) navigate(routesClient.home.path);
  }, [navigate, userId]);

  const {
    data: orders,
    isLoading: loadingOrders,
    refetch: getOrders,
  } = useCustomQuery<Order[]>(
    queryKeys.PROFILE_ORDERS,
    () => createClientService(clientInstance).getClientOrders(userId, 50, 1),
    (data: any) => data.items,
  );

  return (
    <div className="profile-orders-page">
      <div className="profile-orders-page__title-section">Orders</div>
      <div className="profile-orders-page__orders-list">
        {loadingOrders ? (
          <div className="profile-orders-page__product-list__empty">
            <Preloader />
          </div>
        ) : (
          <>
            {orders && orders?.length > 0 ? (
              <ul className="asd">
                {orders?.map((order, index) => (
                  <li className="asdasd" key={index}>
                    <OrderInfo order={order} refresh={getOrders} />
                  </li>
                ))}
              </ul>
            ) : (
              <div className="profile-orders-page__product-list__empty">
                <div className="profile-orders-page__product-list__empty__img">
                  <img src={images.noGoods} alt="empty-list" />
                </div>
                <div className="profile-orders-page__product-list__empty__text">
                  The product list is empty
                  <span>Fill it with goods</span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileOrders;
