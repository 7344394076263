import React from 'react';

const HashRateIcon: React.FC = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_817_100)">
      <path
        d="M14.2267 1.81256H2.77333C2.07009 1.81256 1.5 2.38265 1.5 3.08589V14.5392C1.5 15.2425 2.07009 15.8126 2.77333 15.8126H14.2267C14.9299 15.8126 15.5 15.2425 15.5 14.5392V3.08589C15.5 2.38265 14.9299 1.81256 14.2267 1.81256Z"
        stroke="#B8C6CC"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M12.313 4.99255H4.67969V12.6259H12.313V4.99255Z"
        stroke="#B8C6CC"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path d="M5.95312 3.71922V4.99256" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M5.95312 13.9059V12.6326" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M8.5 3.71922V4.99256" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M8.5 13.9059V12.6326" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M11.0469 3.71922V4.99256" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M11.0469 13.9059V12.6326" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M3.40625 11.3592H4.67958" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M13.5936 11.3592H12.3203" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M3.40625 8.81256H4.67958" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M13.5936 8.81256H12.3203" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M3.40625 6.2659H4.67958" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M13.5936 6.2659H12.3203" stroke="#B8C6CC" strokeWidth="1.2" strokeMiterlimit="10" />
      <path d="M8.50042 9.45257H6.59375L8.50042 6.2659V9.45257Z" fill="#B8C6CC" />
      <path d="M8.5 8.17255H10.4067L8.5 11.3592V8.17255Z" fill="#B8C6CC" />
    </g>
    <defs>
      <clipPath id="clip0_817_100">
        <rect width="16" height="16" fill="white" transform="translate(0.5 0.812561)" />
      </clipPath>
    </defs>
  </svg>
);

export default HashRateIcon;
