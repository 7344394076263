import React from 'react';

const WishListProfileIcon: React.FC = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1319_2175)">
      <path
        d="M14.7744 2.15714C13.9628 2.17457 13.1697 2.40265 12.4728 2.81901C11.776 3.23537 11.1993 3.82571 10.7994 4.53214C10.3995 3.82571 9.82279 3.23537 9.12593 2.81901C8.42907 2.40265 7.63596 2.17457 6.82438 2.15714C4.18271 2.15714 2.04938 4.53213 2.04938 7.46547C2.04938 12.7655 10.7994 18.0738 10.7994 18.0738C10.7994 18.0738 19.5494 12.7655 19.5494 7.46547C19.5494 4.53213 17.416 2.15714 14.7744 2.15714Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_1319_2175">
        <rect width="20" height="20" fill="white" transform="translate(0.799377 0.115479)" />
      </clipPath>
    </defs>
  </svg>
);

export default WishListProfileIcon;
