import './AdminInfoBlock.scss';

import React, { MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';

import AddIcon from '../../../../general/assets/svg/AddIcon';
import DeleteIconRound from '../../../../general/assets/svg/DeleteIconRound';
import EditIconRound from '../../../../general/assets/svg/EditIconRound';
import InfoIcon from '../../../../general/assets/svg/InfoIcon';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import { setInfo } from '../../../../general/redux/reducers/Error-Reducer';
import createHomePageService from '../../../../general/services/homePage';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import { InfoBlcok } from '../../../../general/types/types';
import InfoBlockModal from './Editor';

const AdminInfoBlock: React.FC = () => {
  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState(false);
  const [isEditId, setIsEditId] = useState<string | null>(null);

  const {
    data: infoBlocks,
    isLoading,
    refetch: getBlocks,
  } = useCustomQuery<InfoBlcok[]>(
    queryKeys.INFO_BLOCK,
    createHomePageService(adminInstance).getAllBlocks,
  );

  const { mutate: deleteInfoBlock } = useCustomMutation(
    createHomePageService(adminInstance).deleteBlock,
  );

  const openModal = () => {
    setIsModal(true);
  };

  const deleteBlock = async (e: MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.dataset.id;

    if (id) {
      deleteInfoBlock(id);
    }
  };

  const editBlock = (e: MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.dataset.id;

    if (id) {
      setIsModal(true);
      setIsEditId(id);
    }
  };

  const notMoreThree = () => {
    dispatch(
      setInfo({
        n: 'Request failed',
        r: {
          d: {
            detail: 'The information block cannot contain more than 3 buttons.',
          },
          s: '',
        },
      }),
    );
  };

  return (
    <>
      {isModal && <InfoBlockModal isOpen={setIsModal} id={isEditId} refetch={getBlocks} />}
      <div className="home-container__header">
        <div className="home-container__header__icon-name">
          <InfoIcon />
          <span>Info block</span>
        </div>
        <button
          className="home-container__header__button"
          onClick={infoBlocks && infoBlocks?.length < 3 ? openModal : notMoreThree}
        >
          <AddIcon color="#25A8CF" />
        </button>
      </div>
      <div className="home-container__content__info-block">
        {isLoading ? (
          <div className="home-container__info-block__preloader">
            <Preloader />
          </div>
        ) : (
          <>
            {infoBlocks && infoBlocks?.length === 0 ? (
              <div className="home-container__info-block__empty">No blocks</div>
            ) : (
              <ul>
                {infoBlocks?.map((btn, index) => (
                  <li key={index}>
                    <div className="home-container__info-block__info">
                      <div
                        className="selected-img"
                        style={{
                          backgroundImage: `url(${btn?.buttonIconUrl})`,
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          width: '25px',
                          height: '25px',
                        }}
                      />
                      <span>{btn?.buttonText}</span>
                    </div>
                    <div className="home-container__info-block__actions">
                      <button data-id={btn?.id} onClick={editBlock}>
                        <EditIconRound />
                      </button>
                      <button data-id={btn?.id} onClick={deleteBlock}>
                        <DeleteIconRound />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AdminInfoBlock;
