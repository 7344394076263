import React from 'react';

interface ArrowRightIconPros {
  fill: string;
}

const ArrowRightIcon: React.FC<ArrowRightIconPros> = ({ fill }) => (
  <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.2525 8.70711C25.643 8.31658 25.643 7.68342 25.2525 7.29289L18.8886 0.928932C18.498 0.538408 17.8649 0.538408 17.4743 0.928932C17.0838 1.31946 17.0838 1.95262 17.4743 2.34315L23.1312 8L17.4743 13.6569C17.0838 14.0474 17.0838 14.6805 17.4743 15.0711C17.8649 15.4616 18.498 15.4616 18.8886 15.0711L25.2525 8.70711ZM0.54541 9L24.5454 9V7L0.54541 7V9Z"
      fill={fill}
    />
  </svg>
);

export default ArrowRightIcon;
