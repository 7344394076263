import { AxiosInstance } from 'axios';

interface ArticlesService {
  postArticleContentImage: (image: File) => Promise<any>;
  deleteArticle: (id: string) => Promise<any>;
  getActiveArticles: (pageSize: number, page: number) => Promise<any>;
  getArticleByUrl: (url: string) => Promise<any>;
  getAllArticles: (query: string, pageSize: number, page: number) => Promise<any>;
  getEditArticle: (id: string) => Promise<any>;
  putArticle: (data: {
    id: string;
    title: string;
    description: string;
    content: string;
  }) => Promise<any>;
  post_putArticlesImg: (data: { id: string; image: any; isEdit: boolean }) => Promise<any>;
  putArticleActivity: (data: { id: string; isActive: boolean }) => Promise<any>;
  postArticles: (data: {
    title: string;
    description: string;
    content: string;
    isActive: boolean;
  }) => Promise<any>;
}

const createArticlesService = (instance: AxiosInstance): ArticlesService => ({
  async postArticles(data: {
    title: string;
    description: string;
    content: string;
    isActive: boolean;
  }) {
    try {
      const response = await instance.post(`/Article`, data);
      return response;
    } catch (error) {
      return error;
    }
  },

  async postArticleContentImage(image: File) {
    const formData = new FormData();
    formData.append('image', image);

    const data = {
      image: image,
    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await instance.post('/Article/contentImage', data, {
        headers,
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteArticle(id: string) {
    try {
      const data = {
        id: id,
      };
      const response = await instance.delete(`/Article`, { data });
      return response;
    } catch (error) {
      return error;
    }
  },

  async putArticleActivity(data: { id: string; isActive: boolean }) {
    try {
      const response = await instance.put(`/Article/activity`, {
        id: data.id,
        isActive: data.isActive,
      });

      return response;
    } catch (error) {
      return error;
    }
  },

  async post_putArticlesImg(data: { id: string; image: any; isEdit: boolean }) {
    try {
      const formData = new FormData();
      formData.append('image', data.image);

      const headers = {
        'Content-Type': 'multipart/form-data',
      };

      const response = await instance.post(
        data.isEdit
          ? `/Article/updateImage?ArticleId=${data.id}`
          : `/Article/addImage?ArticleId=${data.id}`,
        {
          image: data.image,
        },
        { headers },
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async putArticle(data: { id: string; title: string; description: string; content: string }) {
    try {
      const response = await instance.put(`/Article`, {
        id: data.id,
        title: data.title,
        description: data.description,
        content: data.content,
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async getEditArticle(id: string) {
    try {
      const response = await instance.get(`Article/byId?Id=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getAllArticles(query: string, pageSize: number, page: number) {
    try {
      const response = await instance.get(
        `/Article/all?SearchQuery=${encodeURIComponent(query)}&PageSize=${pageSize}&Page=${page}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async getActiveArticles(pageSize: number, page: number) {
    try {
      const response = await instance.get(`/Article/active?PageSize=${pageSize}&Page=${page}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getArticleByUrl(url: string) {
    try {
      const response = await instance.get(`/Article/byUrl?Url=${url}`);
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createArticlesService;
