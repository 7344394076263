import './AdminCompanyPartners.scss';

import React, { MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';

import AddIcon from '../../../../general/assets/svg/AddIcon';
import EditIcon from '../../../../general/assets/svg/EditIconAction';
import PeopleIcon from '../../../../general/assets/svg/PeopleIcon';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import { setPartnerIdToEdit } from '../../../../general/redux/reducers/HomeStaticInfo-Reducer';
import createHomePageService from '../../../../general/services/homePage';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import { CompanyPertner } from '../../../../general/types/types';
import ModalCompanyPartner from './Editor';

const AdminCompanyPartners: React.FC = () => {
  const dispatch = useDispatch();
  const [isModal, setIsModal] = useState(false);

  const openModal = () => {
    setIsModal(true);
    dispatch(setPartnerIdToEdit(null));
  };

  const openEditModal = (e: MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.dataset.id;
    if (id) {
      setIsModal(true);
      dispatch(setPartnerIdToEdit(id));
    }
  };

  const {
    data: partners,
    isLoading,
    refetch: getPartners,
  } = useCustomQuery<CompanyPertner[]>(queryKeys.COMPANY_PARTNERS, () =>
    createHomePageService(adminInstance).getPartners(),
  );

  return (
    <>
      {isModal && <ModalCompanyPartner isOpen={setIsModal} refresh={getPartners} />}
      <>
        <div className="home-container__header">
          <div className="home-container__header__icon-name">
            <PeopleIcon />
            <span>Company Partners</span>
          </div>
          <button className="home-container__header__button" onClick={openModal}>
            <AddIcon color="#25A8CF" />
          </button>
        </div>
        <div className="home-container__company-partners">
          {isLoading ? (
            <div className="home-container__company-partners__preloader">
              <Preloader />
            </div>
          ) : (
            <>
              {partners?.length === 0 ? (
                <div className="home-container__company-partners__empty">No partners</div>
              ) : (
                <>
                  {partners?.map((partner, index) => (
                    <div key={index} className="home-container__company-partners__partner">
                      <div
                        className="picture"
                        style={{
                          backgroundImage: `url(${partner?.imageUrl + `?` + Math.random()})`,
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          width: '100%',
                          height: '100%',
                        }}
                      />
                      <div className="home-container__company-partners__partner-hover">
                        <button data-id={partner?.id} onClick={openEditModal}>
                          <EditIcon />
                        </button>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </>
    </>
  );
};

export default AdminCompanyPartners;
