import './About.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import LinkedInStaffIcon from '../../../general/assets/svg/LinkedInStaffIcon';
import Preloader from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../general/queryKeys';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import createStaffService from '../../../general/services/staff';
import { StaffView } from '../../../general/types/types';
import CanonicalLink from '../../components/SEO/CanonicalLink';
import AboutSlider from '../../components/sliders/about-slider/AboutSlider';

const About: React.FC = () => {
  const { data: staff, isLoading: staffLoading } = useCustomQuery<StaffView[]>(
    queryKeys.STAFF,
    () => createStaffService(clientInstance).getAllStaff(),
    (data) => data.sort((a, b) => a.orderNumber - b.orderNumber),
  );

  return (
    <>
      <CanonicalLink href={window.location.href} />
      <section className="about-us-section page-container">
        <div className="about-us-section__row">
          <div className="about-us-section__title-text">
            <div className="about-us-section__title">
              <span>History of Creation</span>
              <div className="about-us-section__title__decoration">
                <span>and Inspiration</span>
                <div className="about-us-section__title__decoration__circle">
                  <article className="circleSVG" />
                  <article className="circleSVG" />
                  <article className="circleSVG" />
                </div>
              </div>
            </div>
            <div className="about-us-section__text">
              <p>
                History of Creation and Inspiration: ASICXchange was founded in 2018 in Canada when
                we realized that the cryptocurrency market needed a reliable supplier of equipment
                and related services. Specializing in cryptocurrency mining equipment sales, we were
                inspired to provide customers with high-quality equipment and services to help them
                succeed in the cryptocurrency world.
              </p>
            </div>
          </div>
          <div className="about-us-section__title-img">
            <div className="img" />
          </div>
        </div>
        <div className="about-us-section__row">
          <div className="about-us-section__content-img">
            <div className="about-us-section__content-img__first">
              <div className="img" />
            </div>
            <div className="about-us-section__content-img__second">
              <div className="img" />
            </div>
          </div>
          <div className="about-us-section__content-text">
            <p>
              Achievements and Challenges: In the field of cryptocurrency mining, we've generated
              revenue of 100 million, closed thousands of ASICs SOLD, and provided unique services
              like Day2Day pricing. Our team of specialists has built and operated highly efficient
              mining facilities, including a 36.5-megawatt operation in Quebec. In addition, we've
              cultivated an expansive global transportation network, providing protection with
              insured and cost-effective deliveries. This not only emphasizes our dedication to
              operational efficiency but also underscores our commitment to ensuring the financial
              safety of each transaction for our clients. Strategic Priorities and Development
              Directions:
            </p>
            <p>
              1. Eco-Friendly Mining: - Use of renewable energy sources. - Focus on energy
              efficiency and carbon emissions reduction. - Conscious social responsibility: caring
              for nature, supporting local communities, and transparent business practices.
            </p>
            <p>
              2. Global Mining Infrastructure: - Allocation and organization of mining equipment
              hosting. - Goal: to provide efficient mining opportunities for everyone, regardless of
              their location. - Active collaboration with global industry partners to implement
              innovations.
            </p>
            <p>
              3. Educational Platform: - Expanding cryptocurrency and blockchain technology
              education opportunities.
            </p>
            <p>
              4. Comprehensive Customer Support: - Development of a platform for comprehensive
              customer support, from equipment selection and purchase to consulting on placement and
              cost optimization. - Providing access to up-to-date information on profitability, ROI,
              and other key mining indicators. Our plans are focused not only on growth and
              development but also on sharing knowledge, technology, and innovation to contribute to
              the development of the entire industry.
            </p>
          </div>
        </div>
        <div className="about-us-section__row">
          <div className="about-us-section__our-team">
            <div className="about-us-section__our-team__title">our team</div>
            <div className="about-us-section__our-team__members">
              {staffLoading ? (
                <div className="about-us-section__our-team__staff__preloader">
                  <Preloader />
                </div>
              ) : (
                staff &&
                staff?.map(
                  (staff, index) =>
                    !staff.isFired && (
                      <div key={index} className="about-us-section__our-team__member">
                        <div
                          className="about-us-section__our-team__member-img"
                          style={{
                            backgroundImage: `url(${staff.imageURL})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                          }}
                        />
                        <div className="about-us-section__our-team__member-info-linkedIn">
                          <div className="about-us-section__our-team__member-info">
                            <h4>{staff.fullName}</h4>
                            <p>{staff.position}</p>
                          </div>
                          <div className="about-us-section__our-team__member-linkedIn">
                            <Link to={staff.linkedIn} target="_blank">
                              <LinkedInStaffIcon />
                            </Link>
                          </div>
                        </div>
                      </div>
                    ),
                )
              )}
            </div>
          </div>
        </div>
        <div className="about-us-section__row">
          <div className="about-us-section__achivements-slider">
            <div className="about-us-section__achivements">
              <div className="about-us-section__achivements__title">
                Achievements and Challenges
              </div>
              <div className="about-us-section__achivements__list">
                <div className="about-us-section__achivements__list__item">
                  <p>Over $100,000,000 in Sales</p>
                </div>
                <div className="about-us-section__achivements__list__item">
                  <p>Over 120,000 ASICs Sold Worldwide</p>
                </div>
                <div className="about-us-section__achivements__list__item">
                  <p>36.5 MW Quebec Facility: Another Milestone by Our Specialist Team</p>
                </div>
                <div className="about-us-section__achivements__list__item">
                  <p>Insured Global Shipping: Fast, Secure, and Cost-Effective</p>
                </div>
              </div>
            </div>
            <div className="about-us-section__slider">
              <AboutSlider />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
