import React from 'react';

import { Theme, themes } from '../../contexts/ThemeContext';

interface ProfileIconProps {
  isUser: string;
  theme: Theme;
}

const ProfileIcon: React.FC<ProfileIconProps> = ({ isUser, theme }) => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      fill={isUser !== '' ? (theme === themes.light ? 'black' : '#25A8CF') : ''}
      stroke={
        isUser !== ''
          ? theme === themes.light
            ? 'black'
            : '#25A8CF'
          : theme === themes.light
            ? 'black'
            : 'white'
      }
      x="1.04541"
      y="1"
      width="39"
      height="39"
      rx="19.5"
    />
    <path
      stroke={
        isUser !== ''
          ? theme === themes.light
            ? 'white'
            : 'black'
          : theme === themes.light
            ? 'black'
            : 'white'
      }
      d="M20.5454 20.9691C23.71 20.9691 26.2754 18.518 26.2754 15.4945C26.2754 12.471 23.71 10.02 20.5454 10.02C17.3808 10.02 14.8154 12.471 14.8154 15.4945C14.8154 18.518 17.3808 20.9691 20.5454 20.9691Z"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      stroke={
        isUser !== ''
          ? theme === themes.light
            ? 'white'
            : 'black'
          : theme === themes.light
            ? 'black'
            : 'white'
      }
      d="M10.0454 31.001L10.4154 29.0424C10.8525 26.7805 12.1051 24.7362 13.9557 23.2649C15.8063 21.7935 18.1379 20.988 20.5454 20.9883C22.9558 20.9888 25.2897 21.7971 27.1407 23.2722C28.9916 24.7474 30.2425 26.7961 30.6754 29.0615L31.0454 31.0201"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default ProfileIcon;
