import './AdminVideo.scss';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Preloader, { preloaderStyles } from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import { setInfo, setType, types } from '../../../../general/redux/reducers/Error-Reducer';
import createHomePageService from '../../../../general/services/homePage';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import AdminModalWindow from '../../../components/modal-window/ModalWindow';
import DragAndDrop from './DragAndDrop';

interface VideoModalProps {
  isOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const VideoModal: React.FC<VideoModalProps> = ({ isOpen }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [isPreloader, setIsPreloader] = useState<boolean>(false);

  const { mutate: saveNewVideo } = useCustomMutation(
    createHomePageService(adminInstance).postMainPageVideo,
    undefined,
    {
      onSuccess: (data) => {
        if (data) {
          dispatch(setType(types.SUCCESS));
          dispatch(
            setInfo({
              n: types.SUCCESS,
              r: { s: 'New video succesfully updated' },
            }),
          );
        }
      },
    },
  );

  const closeModal = () => {
    isOpen(false);
  };

  const saveVideoLink = async () => {
    setIsPreloader(true);
    const formData = new FormData();
    if (file) {
      formData.append('Video', file);
    }

    if (file === null) {
      dispatch(
        setInfo({
          n: 'Request failed',
          r: { d: { detail: 'No video selected' }, s: '' },
        }),
      );
    } else {
      saveNewVideo(formData);
      closeModal();
    }

    setIsPreloader(false);
  };

  return (
    <AdminModalWindow isOpen={isOpen} title={'Video'} mWidth="50%">
      <div className="modal-window-action__modal__video-home-page">
        <DragAndDrop file={file} setFile={setFile} />
      </div>
      <div className="modal-window-action__modal__button">
        <button className="section-btn-gradient" onClick={saveVideoLink}>
          {isPreloader ? <Preloader style={preloaderStyles.DARK} /> : 'Save'}
        </button>
      </div>
    </AdminModalWindow>
  );
};

export default VideoModal;
