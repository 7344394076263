import './PublicOffer.scss';

import React from 'react';

const PublicOffer: React.FC = () => {
  return (
    <div className="static-agreements-page page-container">
      <div className="static-agreements-page__title">Public offer</div>
      <div className="static-agreements-page__content">
        <div className="static-agreements-page__content__text-block">
          <h3 className="static-agreements-page__content__text-block__title">
            1. Subject of the agreement
          </h3>
          <span className="static-agreements-page__content__text-block__text">
            The Seller undertakes to transfer to the Buyer's ownership the products (hereinafter to
            be referred to as the Goods) being offered for purchase at the Seller’s web-site being
            AsixChange (hereinafter to be referred to as the Web-site), and the Buyer undertakes to
            accept the Goods and pay for it in accordance with the valid price-list published at the
            web-site in the amount and in the order set forth by this Agreement.
          </span>
        </div>
        <div className="static-agreements-page__content__text-block">
          <h3 className="static-agreements-page__content__text-block__title">
            2. Order Placement Procedure
          </h3>
          <span className="static-agreements-page__content__text-block__text">
            <ul className="static-agreements-page__public-offer-ul">
              <li>
                2.1. The Goods are to be ordered by the Buyer via the web-site shop.myrig.com or
                through the operator during the Seller’s working hours.
              </li>
              <li>
                2.2. Upon registering at the web-site the Buyer undertakes to provide the following
                personal registration information:
                <br />
                <ul>
                  <li>2.2.1. Surname, name;</li>
                  <li>2.2.2. Actual delivery address;</li>
                  <li>2.2.3. E-mail address;</li>
                  <li>2.2.4. Phone contact number (mobile, landline).</li>
                </ul>
              </li>
              <li>
                2.3. When placing the Order through the Operator, the Buyer undertakes to provide
                the information stated in Sub Cl. 2.2. of the present Agreement. The conditions of
                the Agreement are considered accepted by entering of the corresponding data by the
                Buyer into the registration form at the web-site or when placing the Order through
                the Operator.
              </li>
              <li>
                2.4. The Buyer has the right to edit its registration information. The Operator
                shall neither alter nor edit the registration information about the Buyer without
                the Buyer’s consent hereto. The Seller undertakes not to disclose the Buyer’s data
                stated when registering at the web-site and placing the Order to the persons having
                no relation to execution of the Order. Having approved the Order of the chosen
                Goods, the Buyer provides the Operator with the required information according to
                the procedure specified in Sub Cl. 2.2. of this Agreement.
              </li>
              <li>
                2.5. The Seller and the Operator bear no responsibility for the content and
                credibility of the information provided by the Buyer when placing the Order.
              </li>
              <li>
                2.6. The Buyer is responsible for credibility of the information provided when
                placing the Order. The Buyer shall bear all possible commercial risks related with
                his actions resulting to the errors and discrepancies in the data provided by the
                Buyer.
              </li>
              <li>
                2.7. All information material presented at the web-site is for reference purposes
                only and cannot in full manner provide the reliable information about some specific
                properties and characteristics of the Goods. Should the Buyer have any questions
                regarding the properties and characteristics of the Goods, the Buyer is to apply for
                the Operator’s assistance before placing the Order.
              </li>
              <li>
                2.8. When placing the Order, the Buyer should correctly indicate the delivery
                instructions. One single Order may be placed for one date, time and address
              </li>
              <li>
                2.9. The Buyer has the right either to confirm or to cancel the order until it is
                paid only.
              </li>
              <li>
                2.10. Buyer’s payment for the Order placed at the web-site is considered as the
                Buyer’s consent with the conditions of the Agreement. Date of payment for the Order
                is the date of conclusion of the Agreement. Payment date is considered to be the
                date of money arrival to the Seller’s account.
              </li>
              <li>
                2.11. Prices for any items of the Goods stated at the web-site can be changed by the
                Seller unilaterally without notification of the Buyer till the moment the order is
                paid.
              </li>
              <li>
                2.12. Before paying for the Order the Buyer is obliged to clarify the validity of
                prices (by receiving of the written confirmation) with the Seller or Operator for
                the items of the Goods being added to the Order.
              </li>
              <li>
                2.13. In case a pre-order is placed, after the offer conditions had been accepted,
                the Buyer cannot cancel the order. No refunds shall be given.
              </li>
              <li>
                2.14. Payment for the Order is to be performed as a cashless payment: online, via
                POS-terminal or by a bank transfer the Seller’s account. If a bank transfer payment
                is chosen by the Client, the Client has the right to pay for the Goods in any bank
                providing such service. When receiving the Goods, the Client should produce passport
                or receipt of payment.
              </li>
              <li>
                2.15. Placement of the Order at the web-site does not mean a reservation of the
                Goods for the Buyer, it does not fix the Goods’ cost and grants no rights to the
                Buyer for the Goods.
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PublicOffer;
