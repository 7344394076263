import React from 'react';

const NotCopiedTableIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2256 12.4186H16.4756C17.3961 12.4186 18.1423 11.6725 18.1423 10.752V4.91862C18.1423 3.99814 17.3961 3.25195 16.4756 3.25195H10.6423C9.72175 3.25195 8.97559 3.99814 8.97559 4.91862V6.16862"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6426 9.08545H4.80924C3.88877 9.08545 3.14258 9.83162 3.14258 10.7521V16.5854C3.14258 17.5059 3.88877 18.2521 4.80924 18.2521H10.6426C11.5631 18.2521 12.3092 17.5059 12.3092 16.5854V10.7521C12.3092 9.83162 11.5631 9.08545 10.6426 9.08545Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NotCopiedTableIcon;
