import React from 'react';

const EditIconRound: React.FC = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#746A81" />
    <g clipPath="url(#clip0_622_2)">
      <path
        d="M11.2175 19.0108L8.4292 19.5942L9.01253 16.8058L17.4359 8.33584C17.582 8.18935 17.7557 8.07323 17.9469 7.99414C18.1381 7.91505 18.3431 7.87456 18.55 7.875C18.9677 7.875 19.3684 8.04094 19.6637 8.33631C19.9591 8.63168 20.125 9.03229 20.125 9.45C20.1255 9.65693 20.085 9.8619 20.0059 10.0531C19.9268 10.2443 19.8107 10.418 19.6642 10.5642L11.2175 19.0108Z"
        stroke="#746A81"
        strokeMiterlimit="10"
      />
      <path d="M7.3208 20.125H20.6791" stroke="#746A81" strokeMiterlimit="10" />
      <path d="M18.4566 11.7715L16.2283 9.54321" stroke="#746A81" strokeMiterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_622_2">
        <rect width="14" height="14" fill="white" transform="translate(7 7)" />
      </clipPath>
    </defs>
  </svg>
);

export default EditIconRound;
