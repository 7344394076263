import './Information.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Preloader from '../../../../../general/components/preloader/Preloader';
import usePreloader from '../../../../../general/hooks/usePreloader';
import useScrollTo from '../../../../../general/hooks/useScrollTo';
import {
  billingState,
  deliveryMethodsEnum,
  setDeliveryMethodRedux,
  setIsBillingTheSame,
  setIsOptionalLocationVisible,
  setIsOptionLocationBillingVisible,
} from '../../../../../general/redux/reducers/ClientCartProcessing-Reducer';
import { RootState } from '../../../../../general/redux/store';
import { clientInstance } from '../../../../../general/services/main/axiosInstances';
import createProductsService from '../../../../../general/services/products';
import { ProductMax } from '../../../../../general/types/types';
import Inputs from './Inputs';
import SelfPickUp from './pickUp/SelfPickUp';

const Information: React.FC = () => {
  const dispatch = useDispatch();
  const scrollTo = useScrollTo();
  const user = useSelector((state: RootState) => state.clientCartProcessing.user);
  const isOptionalLocationVisible = useSelector(
    (state: RootState) => state.clientCartProcessing.isOptionalLocationVisible,
  );
  const isBillingTheSame = useSelector(
    (state: RootState) => state.clientCartProcessing.isBillingTheSame,
  );
  const isOptionLocationBillingVisible = useSelector(
    (state: RootState) => state.clientCartProcessing.isOptionLocationBillingVisible,
  );
  const fieldsErrors = useSelector((state: RootState) => state.clientCartProcessing.fieldsErrors);
  const deliveryMethodRedux = useSelector(
    (state: RootState) => state.clientCartProcessing.deliveryMethod,
  );
  const [hasRun, setHasRun] = useState(false);
  const userCart = useSelector((state: RootState) => state.pc);
  const userCartCount = useSelector((state: RootState) => state.pc.count);

  const [products, setProducts] = useState<ProductMax[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | undefined>();
  const comment = useRef<HTMLTextAreaElement>(null);
  const { isPreloader, showPreloader, hidePreloader } = usePreloader();

  useEffect(() => {
    const fetchData = async () => {
      try {
        showPreloader();
        const productPromises = userCart.pc.map((item) =>
          createProductsService(clientInstance).getProductByURL(item.p.url),
        );

        const productResults = await Promise.all(productPromises);

        const newProducts = productResults.map((result) => result.data);

        setProducts((prevProducts) => [...prevProducts, ...newProducts]);
      } catch (error) {
        console.error('Error fetching product data:', error);
      } finally {
        hidePreloader();
      }
    };

    if (userCart.pc && userCart.pc.length > 0) {
      fetchData();
    }
  }, [userCart, userCartCount, showPreloader, hidePreloader]);

  const hasNoStores = useCallback(
    () => products.some((product) => !product.stores || product.stores.length === 0),
    [products],
  );

  useEffect(() => {
    if (!hasRun && products.length > 0) {
      if (hasNoStores()) {
        dispatch(setDeliveryMethodRedux(deliveryMethodsEnum.SHIPPING));
      }

      setHasRun(true);
    }
  }, [hasRun, products, hasNoStores, dispatch]);

  useEffect(() => {
    if (selectedOption === 'no') {
      dispatch(setIsBillingTheSame(billingState.NOT_SAME));
    } else if (selectedOption === 'yes') {
      dispatch(setIsBillingTheSame(billingState.SAME));
    }

    if (isBillingTheSame === billingState.SAME) {
      setSelectedOption('yes');
    } else if (isBillingTheSame === billingState.NOT_SAME) {
      setSelectedOption('no');
    }
  }, [dispatch, selectedOption, isBillingTheSame]);

  useEffect(() => {
    const anchor = sessionStorage.getItem('scrollToAnchor');

    if (anchor) {
      setTimeout(() => {
        scrollTo(anchor);
        sessionStorage.removeItem('scrollToAnchor');
      }, 1000);
    }
  }, [scrollTo]);

  const setDefaultBorder = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    e.target.style.border = '1px solid #25A8CF';
  };

  return (
    <>
      <div className="shipping-fields">
        <>
          {isPreloader ? (
            <div className="preloader">
              <Preloader />
            </div>
          ) : (
            <>{!hasNoStores() && <SelfPickUp products={products} />}</>
          )}
        </>
        {deliveryMethodRedux === deliveryMethodsEnum.SHIPPING && (
          <div id="shipping-fields" className="cart-proccesing-section__grid">
            <Inputs
              errors={fieldsErrors}
              user={user}
              locationVisible={isOptionalLocationVisible}
              setLocationVisible={() =>
                dispatch(setIsOptionalLocationVisible(!isOptionalLocationVisible))
              }
              method={'Shipping'}
            />
            <div
              id="comment"
              style={{ gridColumn: !isOptionalLocationVisible ? '1/3' : undefined }}
              className="cart-proccesing-section__input-block"
            >
              <label>Additional information (optional)</label>
              <textarea ref={comment} onFocus={setDefaultBorder} />
            </div>
          </div>
        )}

        <>
          {deliveryMethodRedux === deliveryMethodsEnum.SHIPPING && (
            <div id="billing-question" className="billing-radio">
              <div>
                <div>
                  <label className="custom-radio">
                    <input
                      id="billing"
                      type="radio"
                      value="yes"
                      checked={selectedOption === 'yes'}
                      onChange={(e) => {
                        setSelectedOption(e.target.value);
                        dispatch(setIsBillingTheSame(billingState.SAME));
                      }}
                    />
                    <span
                      style={{
                        color: isBillingTheSame === billingState.SAME ? '#25A8CF' : '',
                      }}
                      className="radio-btn"
                    >
                      Yes?
                    </span>
                  </label>
                </div>
                <div>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      value="no"
                      checked={selectedOption === 'no'}
                      onChange={(e) => {
                        setSelectedOption(e.target.value);
                        dispatch(setIsBillingTheSame(billingState.NOT_SAME));
                      }}
                    />
                    <span
                      style={{
                        color: isBillingTheSame === billingState.NOT_SAME ? '#25A8CF' : '',
                      }}
                      className="radio-btn"
                    >
                      No?
                    </span>
                  </label>
                </div>
              </div>
              <span className="question">Billing address is the same?</span>
            </div>
          )}

          <div id="billing-fields" className="cart-proccesing-section__grid">
            {(isBillingTheSame === billingState.NOT_SAME ||
              deliveryMethodRedux === deliveryMethodsEnum.SELF_PICKUP) && (
              <Inputs
                errors={fieldsErrors}
                user={user}
                locationVisible={isOptionLocationBillingVisible}
                setLocationVisible={() =>
                  dispatch(setIsOptionLocationBillingVisible(!isOptionLocationBillingVisible))
                }
                method={'Billing'}
              />
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default Information;
