import React from 'react';

const BusketIcon: React.FC = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1319_2153)">
      <path
        d="M3.64105 6.1405H17.9577V16.4822C17.9577 17.1143 17.7066 17.7205 17.2597 18.1674C16.8127 18.6144 16.2065 18.8655 15.5744 18.8655H6.02439C5.39229 18.8655 4.78608 18.6144 4.33911 18.1674C3.89215 17.7205 3.64105 17.1143 3.64105 16.4822V6.1405Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M6.8244 8.52381V5.34048C6.8244 4.28624 7.2432 3.27519 7.98865 2.52973C8.73411 1.78427 9.74517 1.36548 10.7994 1.36548C11.8536 1.36548 12.8647 1.78427 13.6102 2.52973C14.3556 3.27519 14.7744 4.28624 14.7744 5.34048V8.52381"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_1319_2153">
        <rect width="20" height="20" fill="white" transform="translate(0.799377 0.115479)" />
      </clipPath>
    </defs>
  </svg>
);

export default BusketIcon;
