import React, { ChangeEvent, DragEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setInfo } from '../../../../../general/redux/reducers/Error-Reducer';
import Confirm from '../../../../components/confirm/Confirm';

interface EditImage {
  url: string;
  id: number;
}

interface UploadImagesProps {
  filesArray: (File | EditImage)[];
  dragedImgs: { goal: string | null; targetIndex: number }[];
  editImages: { url: string; id: number }[];
  removeArray: number[];
}

const UploadImages: React.FC<UploadImagesProps> = ({
  filesArray,
  dragedImgs,
  editImages,
  removeArray,
}) => {
  const dispatch = useDispatch();
  const [imageSlots, setImageSlots] = useState<Array<string | null>>(Array(10).fill(null));
  const [deleteImgAsk, setDeleteImgAsk] = useState<boolean>(false);
  const [deleteImgId, setDeleteImgId] = useState<number | null>(null);

  const disableInput = {
    display: 'none',
  };

  useEffect(() => {
    if (editImages.length > 0) {
      setImageSlots((prevImageSlots) => {
        const updatedSlots = [...prevImageSlots];
        let slotIndex = updatedSlots.indexOf(null); // Start adding files from the first empty slot

        editImages.forEach((file) => {
          if (slotIndex === -1) {
            return;
          } // Stop adding if no empty slots

          filesArray.push(file);
          updatedSlots[slotIndex] = file.url; // Add file URL to the current empty slot
          slotIndex = updatedSlots.indexOf(null, slotIndex + 1); // Update index for the next empty slot
        });
        return updatedSlots;
      });
    }
  }, [editImages, filesArray]);

  const loadImages = (data: FileList | File[]) => {
    const errors: { f: string; s: string }[] = [];
    const files = Array.from(data); // Create an array of URLs from selected files

    files.forEach((file) => {
      const sizeInMB = file.size / 1024; // size in kilobytes
      if (sizeInMB > 200) {
        errors.push({ f: file.name, s: sizeInMB.toFixed(2) });
      }
    });

    if (errors.length > 0) {
      dispatch(
        setInfo({
          n: 'Uploading images',
          r: {
            d: '',
            s: `Some images weigh more than 200 kB.\n\n${errors
              .map((error) => `${error.f} - ${error.s} kB`)
              .join('\n')}`,
          },
        }),
      );
    }

    setImageSlots((prevImageSlots) => {
      const updatedSlots = [...prevImageSlots];
      let slotIndex = updatedSlots.indexOf(null); // Start adding files from the first empty slot
      files.forEach((file) => {
        if (slotIndex === -1) {
          return;
        } // Stop adding if no empty slots

        const sizeInMB = file.size / 1024; // size in kilobytes

        if (sizeInMB < 200) {
          const fileUrl = URL.createObjectURL(file); // Create URL for each file
          filesArray.push(file);
          updatedSlots[slotIndex] = fileUrl; // Add file URL to the current empty slot
          slotIndex = updatedSlots.indexOf(null, slotIndex + 1); // Update index for the next empty slot
        } else {
          errors.push({ f: file.name, s: sizeInMB.toFixed(2) });
        }
      });
      return updatedSlots;
    });
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      loadImages(event.target.files);
    }
  };

  const handleDropImages = (event: DragEvent<HTMLLabelElement>) => {
    if (event.dataTransfer.files) {
      loadImages(event.dataTransfer.files);
    }
  };

  const deleteImg = () => {
    setImageSlots((prevImageSlots) => {
      const updatedSlots = [...prevImageSlots];
      // Remove image from the slot
      if (deleteImgId !== null) {
        updatedSlots[deleteImgId] = null;
        const fileToDelete = filesArray[deleteImgId];
        if ('id' in fileToDelete && editImages.some((item) => item.id === fileToDelete.id)) {
          removeArray.push(fileToDelete.id);
        }
        filesArray.splice(deleteImgId, 1);
      }
      // Move all null values to the end of the array
      return [
        ...updatedSlots.filter((slot) => slot !== null),
        ...updatedSlots.filter((slot) => slot === null),
      ];
    });
  };

  //#region drag&drop
  const handleDrop = (e: DragEvent<HTMLLIElement>, targetIndex: number) => {
    e.preventDefault();
    const draggedIndex = parseInt(e.dataTransfer.getData('draggedImageIndex'), 10); // Index of the dragged image

    if (draggedIndex === targetIndex) return;

    setImageSlots((prevImageSlots) => {
      const newImageSlots = [...prevImageSlots];
      const temp = newImageSlots[targetIndex];
      newImageSlots[targetIndex] = newImageSlots[draggedIndex];
      newImageSlots[draggedIndex] = temp;

      dragedImgs.push({
        goal: newImageSlots[targetIndex],
        targetIndex: targetIndex,
      });
      dragedImgs.push({
        goal: newImageSlots[draggedIndex],
        targetIndex: draggedIndex,
      });

      return newImageSlots;
    });
  };

  const handleDragOver = (e: DragEvent<HTMLLIElement>) => {
    e.preventDefault(); // Necessary for drop to work
  };

  const handleDragStart = (e: DragEvent<HTMLLIElement>, index: number) => {
    e.dataTransfer.setData('draggedImageIndex', index.toString()); // Index of the dragged image
  };
  //#endregion

  return (
    <>
      <ul className="product-content-section__side-bar__image-loaders">
        {imageSlots.map((imageUrl, index) => (
          <li
            key={index}
            onClick={(e) => {
              const target = e.target as HTMLElement;
              if (target.dataset.id !== undefined) {
                setDeleteImgId(parseInt(target.dataset.id, 10));
                setDeleteImgAsk(true);
              }
            }}
            className="image-loaders"
            draggable={imageUrl !== null}
            onDragStart={(e) => handleDragStart(e, index)}
            onDrop={(e) => handleDrop(e, index)}
            onDragOver={handleDragOver}
          >
            {imageUrl !== null ? (
              <div
                className="img"
                data-id={index}
                style={{
                  backgroundImage: `url(${imageUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            ) : (
              <label
                htmlFor={`upload-photo-${index}`}
                onDrop={handleDropImages}
                onDragOver={(e) => e.preventDefault()}
                className="img-loader"
              >
                +
              </label>
            )}
            <input
              id={`upload-photo-${index}`}
              type="file"
              multiple
              onChange={handleImageChange}
              style={imageUrl !== null ? disableInput : undefined}
            />
          </li>
        ))}
      </ul>
      {deleteImgAsk && (
        <Confirm
          title="Delete confirm"
          text={'Are you shure want delete this image?'}
          handler={setDeleteImgAsk}
          clickEvent={() => {
            deleteImg();
          }}
        />
      )}
    </>
  );
};

export default UploadImages;
