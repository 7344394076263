import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import './RecipientList.scss';

import React, { useEffect, useRef, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { useDispatch } from 'react-redux';

import CalendarClearIcon from '../../../../../general/assets/svg/CalendarClearIcon';
import CalendarEmptyIcon from '../../../../../general/assets/svg/CalendarEmptyIcon';
import Preloader from '../../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../../general/hooks/useCustomQuerry';
import usePagination from '../../../../../general/hooks/usePagination';
import { useResize } from '../../../../../general/hooks/useResize';
import { queryKeys } from '../../../../../general/queryKeys';
import { setInfo, setType, types } from '../../../../../general/redux/reducers/Error-Reducer';
import createClientService from '../../../../../general/services/client';
import createMailingService from '../../../../../general/services/mailing';
import { adminInstance } from '../../../../../general/services/main/axiosInstances';
import { PaggingObj, Subscriber } from '../../../../../general/types/types';
import CustomPagination from '../../../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../../../components/tables/custom-table/CustomTable';
import ActionButton from './ActionButton/ActionButton';

const getClearIcon = () => {
  return (
    <div style={{ color: 'white' }}>
      <CalendarClearIcon />
    </div>
  );
};

const getCalendarIcon = () => {
  return (
    <div style={{ color: 'white' }}>
      <CalendarEmptyIcon />
    </div>
  );
};

const RecipientList: React.FC = () => {
  const size = useResize();
  const userLanguage = navigator.language || navigator['language'];

  const dispatch = useDispatch();
  const contentContainer = useRef<HTMLDivElement>(null);
  const [isPagePreloader] = useState<boolean>(false);
  const [isAllActivated, setIsAllActivated] = useState<boolean | undefined>(undefined);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');

  const { page, pageSize, setPage, setPageSize } = usePagination();
  const [date, onChange] = useState<Date | null>(new Date());

  const {
    data: subscribers,
    isLoading: subscribersLoading,
    refetch: getData,
  } = useCustomQuery<PaggingObj<Subscriber>>(queryKeys.ADMIN_SUBSCRIBERS, () =>
    createClientService(adminInstance).getSubscribers(pageSize, page),
  );

  useEffect(() => {
    getData();
  }, [page, pageSize, getData]);

  const { mutate: createNewsLetter } = useCustomMutation(
    createMailingService(adminInstance).createNewsLetter,
    undefined,
    {
      onSuccess: () => {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'News letter sucessfully created' },
          }),
        );
      },
    },
  );

  const { mutate: updateNewsLetter } = useCustomMutation(
    createMailingService(adminInstance).updateNewsLetter,
    undefined,
    {
      onSuccess: () => {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'News letter sucessfully updated' },
          }),
        );
      },
    },
  );

  const { mutate: sendNewsLetter } = useCustomMutation(
    createMailingService(adminInstance).sendNewsLetter,
    undefined,
    {
      onSuccess: () => {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'News letter sucessfully sent' },
          }),
        );
      },
    },
  );

  const { mutate: changeStatusSubscriberByID } = useCustomMutation(
    createClientService(adminInstance).changeStatusSubscriberByID,
  );

  const { mutate: changeStatusSubscribers } = useCustomMutation(
    createClientService(adminInstance).changeStatusSubscribers,
  );

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsPreview(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleChangeStatusAll = (isActivate: boolean) => {
    changeStatusSubscribers(isActivate);
    setIsAllActivated(isActivate);

    dispatch(setType(types.SUCCESS));
    dispatch(
      setInfo({
        n: types.SUCCESS,
        r: {
          s: isActivate
            ? 'All subscribers successfully activated'
            : 'All subscribers successfully deactivated',
        },
      }),
    );
  };

  const handleLoadLastDraft = async () => {
    const { data } = await createMailingService(adminInstance).getNewsLetter();

    const lastLetter = data.items[0];
    if (!lastLetter) {
      alert('No drafts');
      return;
    }

    setTitle(lastLetter.title);

    // Create a new HTML document to manipulate the content
    const doc = document.implementation.createHTMLDocument();
    doc.body.innerHTML = lastLetter.content;
    const myContent = contentContainer.current;

    // Create a container to hold the modified content
    const container = document.createElement('div');
    container.innerHTML = doc.body.innerHTML;
    container.innerHTML = container.innerHTML.replace(/percent/gi, '%');

    // Create a temporary div to hold the modified content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = container.innerHTML;

    // Find the content to insert
    const content = container.querySelector('#with_unsubscribe')?.querySelector('div');
    if (myContent && content) {
      myContent.insertAdjacentElement('afterbegin', content);
    }
  };

  const handleSendMailing = async () => {
    if (subscribers !== undefined && subscribers.items.length > 0) {
      const hasActiveUser = subscribers.items.some((user: { isActive: boolean }) => user.isActive);
      if (hasActiveUser) {
        // Fetch the newsletter using async/await
        const { data } = await createMailingService(adminInstance).getNewsLetter();
        const currentNewsletter = data.items[0];

        if (currentNewsletter !== undefined) {
          // Send the newsletter to each active subscriber
          for (const subscriber of subscribers.items) {
            if (subscriber.isActive) {
              sendNewsLetter(currentNewsletter.id);
            }
          }

          alert('Mailing successfully done');

          // Create a new newsletter draft
          await createNewsLetter({
            title: currentNewsletter.title,
            content: currentNewsletter.content,
          });
        } else {
          alert('No drafts');
        }
      } else alert('No active subscribers');
    } else alert('No subscribers');
  };

  const handleSchedule = async (date: Date | null) => {
    onChange(date);

    // Await the newsletter fetch
    const { data } = await createMailingService(adminInstance).getNewsLetter();

    if (date) {
      const currentNewsletter = data.items[0];

      const eastTimeLocale = 'en-US';
      const estTime = date.toLocaleString(eastTimeLocale, {
        timeZone: 'America/New_York',
      });
      const estTimeISO = new Date(estTime).toISOString();
      const ourDate = new Date(date);

      if (!currentNewsletter.wasPosted) {
        const ourDateInLocale = ourDate.toLocaleDateString(userLanguage);
        const ourTimeInLocale = ourDate.toLocaleTimeString(userLanguage);

        const estDateInLocale = new Date(estTimeISO).toLocaleDateString(eastTimeLocale);
        const estTimeInLocale = new Date(estTimeISO).toLocaleTimeString(eastTimeLocale);

        // Await the update to the newsletter
        updateNewsLetter({
          id: currentNewsletter.id,
          title: currentNewsletter.title,
          content: currentNewsletter.content,
          timeToPost: estTimeISO,
        });

        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: {
              s: `Schedule time successfully changed to: ${ourDateInLocale} - ${ourTimeInLocale}\nEastern Standard Time (EST): ${estDateInLocale} - ${estTimeInLocale}`,
            },
          }),
        );
      } else {
        onChange(null);
        alert('You need to select another schedule time');

        // Await the creation of a new newsletter
        createNewsLetter({
          title: currentNewsletter.title,
          content: currentNewsletter.content,
        });
      }
    }
  };

  const actionButtons = (item: any) => (
    <ActionButton
      updateState={setIsAllActivated}
      trigger={isAllActivated}
      item={item}
      refreshMethod={changeStatusSubscriberByID}
    />
  );

  return (
    <div className="recipient-list__container">
      <div className="page-section__content-container">
        {!isPreview ? (
          <>
            <div className="recipient-list_grid-item">
              <div className="date-time-picker__container">
                <DateTimePicker
                  className={'calendar react-datetime-picker__wrapper'}
                  clearIcon={getClearIcon()}
                  calendarIcon={getCalendarIcon()}
                  yearPlaceholder="yyyy"
                  monthPlaceholder="mm"
                  dayPlaceholder="dd"
                  autoFocus={true}
                  locale={userLanguage}
                  minDate={new Date()}
                  onChange={onChange}
                  value={date}
                />
                <button className="button" onClick={() => handleSchedule(date)}>
                  📌
                </button>
              </div>
            </div>
            <div className="recipient-list_grid-item">
              <button className="button" style={{ margin: '10px' }} onClick={handleSendMailing}>
                Send now
              </button>
            </div>
            <div className="recipient-list_grid-item">
              <button
                className="button"
                style={{ margin: '10px' }}
                onClick={() => {
                  handleLoadLastDraft();
                  setIsPreview(!isPreview);
                }}
              >
                Preview
              </button>
            </div>
            <div className="recipient-list_grid-item">
              <div className="page-section__content__list-section">
                <div className="list-section__content">
                  {(isPagePreloader || subscribersLoading) && (
                    <div className="page-update-overlay">
                      <Preloader />
                    </div>
                  )}
                  <CustomTable
                    data={subscribers ? subscribers?.items : []}
                    headers={['email']}
                    isAction={true}
                    nameOfAction="Is Subscribed"
                    actionComponent={actionButtons}
                    refreshMethod={changeStatusSubscriberByID}
                    isMobile={size.width < 1150}
                  />
                </div>
              </div>
              {subscribers && subscribers?.pageSize < subscribers?.totalCount && (
                <CustomPagination
                  currentPage={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  isNextPage={subscribers.hasNextPage}
                  isPrevPage={subscribers.hasPreviousPage}
                  total={subscribers.totalCount}
                />
              )}
            </div>
            <div className="recipient-list_grid-item">
              <button className="button" onClick={() => handleChangeStatusAll(false)}>
                Deactivate all
              </button>
            </div>
            <div className="recipient-list_grid-item">
              <button className="button" onClick={() => handleChangeStatusAll(true)}>
                Activate all
              </button>
            </div>
          </>
        ) : (
          <div className="recipient-list_grid-item preview">
            <div id="myModal" className="preview">
              <div className="preview-content">
                <span className="close" onClick={() => setIsPreview(!isPreview)}>
                  &times;
                </span>
                <h1 id="title" className="title">
                  {title}
                </h1>
                <div ref={contentContainer} className="content"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecipientList;
