import React from 'react';

import { Theme, themes } from '../../contexts/ThemeContext';

interface WishListIconProps {
  isUser: string;
  theme: Theme;
}

const WishListIcon: React.FC<WishListIconProps> = ({ isUser, theme }) => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    stroke={isUser !== '' ? 'white' : 'white'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      fill={isUser !== '' ? (theme === themes.light ? 'black' : '#25A8CF') : ''}
      stroke={
        isUser !== ''
          ? theme === themes.light
            ? 'black'
            : '#25A8CF'
          : theme === themes.light
            ? 'black'
            : 'white'
      }
      x="1.04541"
      y="1"
      width="39"
      height="39"
      rx="19.5"
    />
    <path
      stroke={
        isUser !== ''
          ? theme === themes.light
            ? 'white'
            : 'black'
          : theme === themes.light
            ? 'black'
            : 'white'
      }
      d="M25.3154 10.9502C24.3415 10.9711 23.3898 11.2448 22.5535 11.7444C21.7173 12.2441 21.0253 12.9525 20.5454 13.8002C20.0655 12.9525 19.3735 12.2441 18.5373 11.7444C17.701 11.2448 16.7493 10.9711 15.7754 10.9502C12.6054 10.9502 10.0454 13.8002 10.0454 17.3202C10.0454 23.6802 20.5454 30.0502 20.5454 30.0502C20.5454 30.0502 31.0454 23.6802 31.0454 17.3202C31.0454 13.8002 28.4854 10.9502 25.3154 10.9502Z"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default WishListIcon;
