import React from 'react';
import { ProductMax } from '../../../general/types/types';

interface ProductSchemaProps {
  product: ProductMax;
}

const ProductSchema: React.FC<ProductSchemaProps> = ({ product }) => {
  if (!product) return null;

  const productSchema = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: product.name,
    image: product.image || product.images.map((img) => img.url),
    description: product.description,
    sku: product.id,
    brand: {
      '@type': 'Brand',
      name: product.manufacturer,
    },
    offers: {
      '@type': 'Offer',
      url: window.location.href,
      priceCurrency: 'USD',
      price: product.price.toString(),
      itemCondition: product.condition
        ? `https://schema.org/${product.condition}`
        : 'https://schema.org/NewCondition',
      availability:
        product.amount > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
      seller: {
        '@type': 'Organization',
        name: 'AsicXchange',
      },
    },
  };

  return <script type="application/ld+json">{JSON.stringify(productSchema)}</script>;
};

export default ProductSchema;
