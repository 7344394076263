import React from 'react';

const VideoIcon: React.FC = () => (
  <svg width="33" height="24" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.5808 3.35L24.2808 6.9V2C24.2808 1.46957 24.07 0.960859 23.695 0.585786C23.3199 0.210714 22.8112 0 22.2808 0H4.28076C3.2199 0 2.20248 0.421427 1.45233 1.17157C0.702189 1.92172 0.280762 2.93913 0.280762 4V20C0.280762 21.0609 0.702189 22.0783 1.45233 22.8284C2.20248 23.5786 3.2199 24 4.28076 24H22.2808C22.8112 24 23.3199 23.7893 23.695 23.4142C24.07 23.0391 24.2808 22.5304 24.2808 22V17.08L30.5808 20.63C30.7424 20.7356 30.929 20.7968 31.1217 20.8075C31.3145 20.8181 31.5067 20.7778 31.679 20.6907C31.8513 20.6035 31.9976 20.4726 32.1032 20.311C32.2088 20.1493 32.2701 19.9628 32.2808 19.77V4.2C32.2684 4.00808 32.206 3.82275 32.0997 3.66248C31.9934 3.50221 31.847 3.37261 31.675 3.28661C31.5029 3.2006 31.3114 3.1612 31.1194 3.17232C30.9274 3.18344 30.7417 3.24471 30.5808 3.35Z"
      fill="#25A8CF"
    />
  </svg>
);

export default VideoIcon;
