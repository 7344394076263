import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import DeleteActionIcon from '../../../general/assets/svg/DeleteActionIcon';
import EditIconAction from '../../../general/assets/svg/EditIconAction';
import PlusIcon from '../../../general/assets/svg/PlusIcon';
import Preloader from '../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import usePagination from '../../../general/hooks/usePagination';
import { useResize } from '../../../general/hooks/useResize';
import { queryKeys } from '../../../general/queryKeys';
import createArticlesService from '../../../general/services/articles';
import { adminInstance } from '../../../general/services/main/axiosInstances';
import { Article, PaggingObj } from '../../../general/types/types';
import Confirm from '../../components/confirm/Confirm';
import AdminSearch from '../../components/search/Search';
import CustomPagination from '../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../components/tables/custom-table/CustomTable';

interface ActionButtonsProps {
  item: Article;
  deleteMethod: (id: string) => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ item, deleteMethod }) => {
  const [deleteArticleAsk, setDeleteArticleAsk] = useState(false);

  return (
    <>
      <Link to={`/admin/articles/article/${item.id}`} style={{ width: 'auto' }}>
        <EditIconAction />
      </Link>
      <button
        onClick={() => setDeleteArticleAsk(true)}
        className="table-block__tbody__change-item__button"
      >
        <DeleteActionIcon />
      </button>
      {deleteArticleAsk && (
        <Confirm
          title="Delete confirm"
          text={'Are you sure you want to delete this article?'}
          handler={setDeleteArticleAsk}
          clickEvent={() => {
            deleteMethod(item.id);
          }}
        />
      )}
    </>
  );
};

const AdminArticles: React.FC = () => {
  const navigate = useNavigate();
  const size = useResize();
  const { page, pageSize, searchQuery, setPage, setPageSize, setSearchQuery } = usePagination();

  const actionButtons = (item: any, deleteMethod: any) => (
    <ActionButtons item={item} deleteMethod={deleteMethod} />
  );

  const {
    data: articles,
    isLoading: articleLoading,
    refetch: getData,
  } = useCustomQuery<PaggingObj<Article>>(queryKeys.ARTICLES_ADMIN, () =>
    createArticlesService(adminInstance).getAllArticles(searchQuery, pageSize, page),
  );

  const { mutate: deleteArticle } = useCustomMutation(
    createArticlesService(adminInstance).deleteArticle,
    undefined,
    {
      onSuccess: () => {
        getData();
      },
    },
  );

  const goCreateArticle = () => {
    navigate('/admin/articles/article');
  };

  useEffect(() => {
    getData();
  }, [searchQuery, page, pageSize, getData]);

  const deleteItem = async (id: string) => {
    deleteArticle(id);
  };

  return (
    <>
      <div className="page-section">
        <div className="page-section__content">
          <div className="page-section__top">
            <div className="page-section__top__title">Articles</div>
            <div className="page-section__buttons">
              <button onClick={goCreateArticle}>
                <PlusIcon stroke="white" />
                <span>Add article</span>
              </button>
            </div>
          </div>
          <div>
            <AdminSearch
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder={'Search by name'}
            />
          </div>
          <div className="page-section__content__list-section">
            <div className="list-section__content">
              {articleLoading && (
                <div className="page-update-overlay">
                  <Preloader />
                </div>
              )}
              <CustomTable
                data={articles ? articles?.items : []}
                headers={['image', 'title', 'status', 'date']}
                isAction={true}
                deleteMethod={deleteItem}
                actionComponent={actionButtons}
                isMobile={size.width < 950}
              />
            </div>
          </div>
          {articles && articles?.pageSize < articles?.totalCount && (
            <CustomPagination
              currentPage={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              isNextPage={articles.hasNextPage}
              isPrevPage={articles.hasPreviousPage}
              total={articles.totalCount}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AdminArticles;
