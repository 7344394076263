import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import CloseBig from '../../../../general/assets/svg/CloseBig';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import useKey from '../../../../general/hooks/useKey';
import { queryKeys } from '../../../../general/queryKeys';
import { setInfo } from '../../../../general/redux/reducers/Error-Reducer';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createProductsService from '../../../../general/services/products';
import { Characteristic, ICategory } from '../../../../general/types/types';
import CategoriesCharacteristics from './CategoriesCharacteristics';

interface CategoryModalProps {
  category?: ICategory;
  isModal: () => void;
  updateData: () => Promise<any>;
  method?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const CategoryModal: React.FC<CategoryModalProps> = ({ category, isModal, updateData, method }) => {
  const dispatch = useDispatch();
  const [currentIMG, setCurrentIMG] = useState<string>(
    category?.imageUrl ? `${category.imageUrl}?${Math.random()}` : '',
  );
  const [isCharacteristics, setIsCharacteristics] = useState<boolean>(
    category?.areMiningCharacteristics || false,
  );
  const [characteristics, setCharacteristics] = useState<any[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const sizeInMB = file.size / 1024; // размер в килобайтах
    if (sizeInMB > 120) {
      dispatch(
        setInfo({
          n: 'Uploading images',
          r: {
            d: '',
            s: `Some images weigh more than 120 kB.\n\n${file.name} - ${sizeInMB.toFixed(2)} kB`,
          },
        }),
      );
    } else {
      setCurrentIMG(URL.createObjectURL(file));
    }
  };

  useKey((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      isModal();
    }
  });

  const { isLoading: mainNamesLoading } = useCustomQuery<Characteristic[]>(
    queryKeys.PRODUCTS_MAIN_NAMES,
    () =>
      createProductsService(adminInstance).getMainGeneralNames(category?.id?.toString() as string),
    undefined,
    undefined,
    {
      onSuccess: (data: any) => {
        setCharacteristics(data);
      },
      enabled: !category?.id,
    },
  );

  const { mutate: postMainGeneralToCategory } = useCustomMutation(
    createProductsService(adminInstance).postMainGeneralToCategory,
  );

  const { mutate: deleteMainGeneralCategoryCharacteristic } = useCustomMutation(
    createProductsService(adminInstance).deleteMainGeneralCategoryCharacteristic,
  );

  const { mutate: putProductCategory } = useCustomMutation(
    createProductsService(adminInstance).putProductCategory,
  );

  const { mutate: putProductCategoryImage } = useCustomMutation(
    createProductsService(adminInstance).putProductCategoryImage,
  );

  const removedCharacteristic: number[] = [];

  const updateCharacteristics = async () => {
    const section = document.querySelector('.inputs__text__characteristics__section');
    if (!section) return;
    const inputs = section.querySelectorAll<HTMLInputElement>('input');

    inputs.forEach((item) => {
      if (
        !characteristics?.some((characteristic) => characteristic.id === Number(item.dataset.id))
      ) {
        postMainGeneralToCategory({
          categoryId: category?.id as string,
          name: item.value.trim(),
        });
      }
    });

    // Delete
    if (removedCharacteristic.length > 0) {
      removedCharacteristic.forEach((id) => {
        deleteMainGeneralCategoryCharacteristic(id?.toString() as string);
      });
    }
  };

  const changeCategory = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const input = document.getElementById('open-modal') as HTMLInputElement;
    const image = document.getElementById('upload-photo') as HTMLInputElement;
    const label = document.querySelector('.img-loader') as HTMLElement;
    const mining = document.getElementById('Category_mining') as HTMLInputElement;

    if (image.files?.length === 0 && !label.querySelector('img')) {
      label.style.border = '1px solid red';
    } else {
      label.style.border = '1px solid transparent';
    }

    if (input.value.trim() === '') {
      input.style.border = '1px solid red';
    } else {
      input.style.border = '1px solid transparent';
    }

    if (input.value.trim() !== '') {
      putProductCategory({
        categoryId: category?.id?.toString() as string,
        description: '',
        category: input.value,
      });

      if (image.files?.[0]) {
        const formData = new FormData();
        formData.append('Image', image.files[0]);
        putProductCategoryImage({
          categoryId: category?.id?.toString() as string,
          image: formData,
        });
      }

      if (!mining.checked) {
        await updateCharacteristics();
      }
      await updateData();
      isModal();
    }
  };

  return (
    <div className="modal-window-action">
      <div className="modal-window-action__overlay" onClick={isModal} />
      <div className="modal-window-action__modal">
        {mainNamesLoading ? (
          <div className="modal-window-action__modal__category-preloader">
            <Preloader />
          </div>
        ) : (
          <>
            <div className="modal-window-action__modal__title">
              <span>Categories</span>
              <button onClick={isModal}>
                <CloseBig />
              </button>
            </div>
            <div className="modal-window-action__modal__content">
              <div className="modal-window-action__modal__inputs">
                <div className="modal-window-action__modal__inputs__image">
                  <label htmlFor="upload-photo" className="img-loader">
                    {currentIMG ? <img src={currentIMG} alt="" /> : <>+</>}
                  </label>
                  <input
                    id="upload-photo"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="modal-window-action__modal__inputs__text">
                  <div className="modal-window-action__modal__inputs__text__top">
                    <div className="modal-window-action__modal__inputs__name">
                      <input
                        id="open-modal"
                        type="text"
                        defaultValue={category?.name || ''}
                        placeholder="name"
                      />
                    </div>
                    <div className="modal-window-action__modal__inputs__is-mining">
                      <input
                        type="checkbox"
                        id="Category_mining"
                        name="category_type"
                        value="mining"
                        defaultChecked={category?.areMiningCharacteristics || false}
                        disabled={category?.areMiningCharacteristics !== undefined}
                        onChange={() => setIsCharacteristics(!isCharacteristics)}
                      />
                      <label htmlFor="Category_mining">isMining</label>
                    </div>
                  </div>
                  {!isCharacteristics && (
                    <CategoriesCharacteristics
                      removedCharacteristic={removedCharacteristic}
                      characteristics={characteristics}
                    />
                  )}
                </div>
              </div>
              <div className="modal-window-action__modal__button">
                <button
                  className="section-btn-gradient"
                  onClick={method || changeCategory}
                  data-id={category?.id}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CategoryModal;
