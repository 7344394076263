import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ReferralCode {
  code: string;
  [key: string]: any;
}

interface ReferralCodeState {
  referralCodes: ReferralCode[];
}

const initialState: ReferralCodeState = {
  referralCodes: [],
};

const ReferralCodeSlice = createSlice({
  name: 'referralCodes',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<ReferralCode[]>) => {
      state.referralCodes = action.payload;
    },
    addReferralCode: (state, action: PayloadAction<ReferralCode>) => {
      state.referralCodes = [...state.referralCodes, action.payload];
    },
  },
});

export const { init, addReferralCode } = ReferralCodeSlice.actions;
export default ReferralCodeSlice.reducer;
