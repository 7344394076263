import './FAQandQA.scss';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import createHelpCenterServiceService from '../../../../general/services/helpCenter';
import { clientInstance } from '../../../../general/services/main/axiosInstances';
import CanonicalLink from '../../../components/SEO/CanonicalLink';
import FAQSchema from '../../../components/SEO/FAQSchema';
import { QuestionGroup } from './FAQ';
import QuestionItem from './QuestionItem';

const QA = () => {
  const navigate = useNavigate();

  const { data: qaes, isLoading: qaesLoading } = useCustomQuery<QuestionGroup[]>(
    queryKeys.QAES,
    () => createHelpCenterServiceService(clientInstance).getAllQA(),
    undefined,
    () => {
      navigate('/');
    },
  );

  // Отслеживание ID открытого вопроса
  const [openQuestionId, setOpenQuestionId] = useState<string | null>(null);

  const handleQuestionToggle = (questionId: string) => {
    // Переключение открытия/закрытия вопроса
    setOpenQuestionId((prev) => (prev === questionId ? null : questionId));
  };

  return (
    <section className="help-center__aq page-container">
      <div className="help-center__aq__title">
        <span>Questions</span> and answers
      </div>
      {qaesLoading ? (
        <div className="help-center__aq__preloader">
          <Preloader />
        </div>
      ) : qaes ? (
        <>
          <CanonicalLink href={window.location.href} />
          <FAQSchema
            items={qaes.flatMap((group) =>
              group.questionAnswers.map((qa) => ({
                question: qa.question,
                answer: qa.answer,
              })),
            )}
          />
          {qaes.map((group) => (
            <div key={group.id} className="help-center__aq__question-group">
              <div className="help-center__aq__question-group__title">{group.name}</div>
              {group.questionAnswers.length === 0 ? (
                <div className="help-center__aq__question-group__empty">
                  <Preloader />
                </div>
              ) : (
                group.questionAnswers.map((question, index) => {
                  const questionId = `${group.id}-${index}`;
                  return (
                    <QuestionItem
                      key={index}
                      question={question}
                      isOpen={openQuestionId === questionId}
                      onToggle={() => handleQuestionToggle(questionId)}
                    />
                  );
                })
              )}
            </div>
          ))}
        </>
      ) : (
        <p>No Questions and Answers available</p>
      )}
    </section>
  );
};

export default QA;
