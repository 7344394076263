const getFile = (arrayBuffer: ArrayBuffer, fileName: string): void => {
  const url = window.URL.createObjectURL(new Blob([arrayBuffer]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', `ASICXchange_${fileName}.xlsx`);
  document.body.appendChild(link);
  link.click();

  link.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export default getFile;
