import './ProductTable.scss';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import ChangeViewIcon from '../../../general/assets/svg/ChangeViewIcon';
import Preloader from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import usePagination from '../../../general/hooks/usePagination';
import { useResize } from '../../../general/hooks/useResize';
import { queryKeys } from '../../../general/queryKeys';
import { routesClient } from '../../../general/routes';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import createProductsService from '../../../general/services/products';
import { PaggingObj, ProductMin } from '../../../general/types/types';
import TBody from './TBody';
import THead from './THead';

interface Header {
  name: string;
  atr: string;
}

interface ProductTableProps {
  isRetail: boolean;
}

interface Price {
  PriceFrom?: string;
  PriceTo?: string;
}

interface Sort {
  SortColumn?: string;
  SortOrder?: string;
}

const headers: Header[] = [
  { name: 'Brand', atr: 'manufacturer' },
  { name: 'Name', atr: 'name' },
  { name: 'Price $', atr: 'price' },
  { name: 'Hashrate', atr: 'hashrate' },
  { name: 'Quantity', atr: 'amount' },
  { name: 'Power(W)', atr: 'energyConsumption' },
  { name: 'J/TH', atr: 'energyEfficiency' },
  { name: 'Condition', atr: 'condition' },
  { name: 'Algorithm', atr: 'algorithm' },
  { name: 'Location', atr: 'countryLocation' },
];

const ProductTable: React.FC<ProductTableProps> = ({ isRetail }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useResize();
  const { category } = useParams<{ category: string }>();

  const { page, pageSize } = usePagination(100);
  const [searchParams, setSearchParams] = useSearchParams();

  const [query, setQuery] = useState<string[]>([]);
  const [price, setPrice] = useState<Price>({});
  const [sort, setSort] = useState<Sort>({});

  const changeView = () => {
    const goToProducts = location.pathname.split('/');
    goToProducts.pop();
    navigate(goToProducts.join('/'));
  };

  const { data: currentCategory } = useCustomQuery(
    queryKeys.PRODUCT_CATEGORY_TABLE,
    () => createProductsService(clientInstance).getProductsCategories(),
    (data: any) => {
      return data?.find((item: any) => item.url === category);
    },
  );

  const {
    data: products,
    isLoading: productsLoading,
    refetch: getProducts,
  } = useCustomQuery<PaggingObj<ProductMin>>(
    queryKeys.PRODUCTS_CLIENTS,
    () =>
      createProductsService(clientInstance).getProducts(
        currentCategory?.id,
        isRetail,
        pageSize,
        page,
        query,
        price,
        sort,
      ),
    undefined,
    undefined,
    {
      enabled: false,
    },
  );

  useEffect(() => {
    const query: string[] = [];
    const price: Price = {};
    const sort: Sort = {};

    searchParams.forEach((values, key) => {
      if (key === 'PriceFrom') {
        price['PriceFrom'] = values;
      } else if (key === 'PriceTo') {
        price['PriceTo'] = values;
      } else if (key === 'sortColumn') {
        const vsp = values.split('-');
        sort['SortColumn'] = vsp[0];
        sort['SortOrder'] = vsp[1];
      } else {
        values.split(',').forEach((value) => {
          query.push(`${key}=${value}`);
        });
      }
    });

    setQuery(query);
    setPrice(price);
    setSort(sort);
  }, [searchParams]);

  useEffect(() => {
    if (width <= 1024) {
      navigate(routesClient.home.path);
      return;
    }

    if (currentCategory) {
      getProducts();
    }
  }, [currentCategory, query, price, sort, searchParams, getProducts, navigate, width]);

  return (
    <>
      <div className="products-section page-container" style={{ marginBottom: '10%' }}>
        <div className="products-section-top">
          <div className="products-section-top__title">
            CATALOG
            <span className="products-section-top__title__sub-text">A large assortment</span>
          </div>
          <div className="products-section-top__product-list-action">
            <div className="products-section-top__table">
              <button className="products-section-top__table-btn" onClick={changeView}>
                <ChangeViewIcon />
              </button>
            </div>
          </div>
        </div>

        {productsLoading ? (
          <div className="product-table-preloader">
            <Preloader />
          </div>
        ) : (
          <table className="producst__table">
            <THead
              headers={headers}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
            <TBody data={products?.items || []} />
          </table>
        )}
      </div>
    </>
  );
};

export default ProductTable;
