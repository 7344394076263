import React, { ReactNode, useEffect, useState } from 'react';

import { Theme, ThemeContext, themes } from '../../general/contexts/ThemeContext';
import { getDataForage, saveDataForage } from '../../general/utils/localforage';

// Асинхронная функция для получения текущей темы
const fetchTheme = async (): Promise<Theme> => {
  const theme = (await getDataForage<any>('theme')) as Theme;
  if (theme && Object.values(themes).includes(theme)) {
    return theme;
  }

  const userMedia = window.matchMedia('(prefers-color-scheme: dark)');
  return userMedia.matches ? themes.dark : themes.light;
};

// Определение пропсов для провайдера темы
interface ThemeProviderProps {
  children: ReactNode;
}

// Создание провайдера темы
const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(themes.light); // Инициализация по умолчанию

  useEffect(() => {
    const initializeTheme = async () => {
      const initialTheme = await fetchTheme();
      setTheme(initialTheme);
      document.documentElement.dataset.theme = initialTheme;
    };

    initializeTheme();
  }, []);

  useEffect(() => {
    document.documentElement.dataset.theme = theme;
    saveDataForage('theme', theme); // Сохранение темы при каждом изменении
  }, [theme]);
  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;
