import { AxiosInstance } from 'axios';

interface StaticPaymentInfosService {
  getInfos: () => Promise<any>;
  getStableCoinInfos: () => Promise<any>;
}

const createStaticPaymentInfosService = (instance: AxiosInstance): StaticPaymentInfosService => ({
  async getInfos() {
    try {
      const response = await instance.get('/StaticPaymentInfos/getInfos');
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getStableCoinInfos() {
    try {
      const response = await instance.get('/StaticPaymentInfos/getStableCoinInfos');
      return response;
    } catch (error) {
      throw error;
    }
  },
});

export default createStaticPaymentInfosService;
