import './FrequentlyAskedQuestion.scss';

import React from 'react';

import { queryClient } from '../../../..';
import CloseBig from '../../../../general/assets/svg/CloseBig';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useKey, { keys } from '../../../../general/hooks/useKey';
import { queryKeys } from '../../../../general/queryKeys';
import createFandQService from '../../../../general/services/FandQ';
import { adminInstance } from '../../../../general/services/main/axiosInstances';

interface ModalQuestionAndAnswerGroupProps {
  isOpen: (open: boolean) => void;
  group?: {
    id?: string;
    name?: string;
  } | null;
}

export const ModalQuestionAndAnswerGroup: React.FC<ModalQuestionAndAnswerGroupProps> = ({
  isOpen,
  group,
}) => {
  useKey((event) => {
    if (event.key === keys.ESCAPE) {
      isOpen && isOpen(false);
      closeModal();
    }
  });

  const { mutate: postFAQGroup, isLoading: posting } = useCustomMutation(
    createFandQService(adminInstance).postFAQGroup,
    undefined,
    {
      onSuccess() {
        queryClient.refetchQueries(queryKeys.ADMIN_F_GROUP);
        closeModal();
      },
    },
  );

  const { mutate: putFAQGroup, isLoading: updating } = useCustomMutation(
    createFandQService(adminInstance).putFAQGroup,
    undefined,
    {
      onSuccess() {
        queryClient.refetchQueries(queryKeys.ADMIN_F_GROUP);
        closeModal();
      },
    },
  );

  const closeModal = () => {
    isOpen(false);
  };

  const setDefaultBorder = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.style.outline = 'none';
  };

  const addGroup = async () => {
    const input = document.querySelector(
      '.modal-window-action__modal__faq-group__input',
    ) as HTMLInputElement;
    if (input.value.trim() === '') {
      input.style.outline = '1px solid red';
      return;
    } else {
      postFAQGroup(input.value);
    }
  };

  const updateGroup = async () => {
    const input = document.querySelector(
      '.modal-window-action__modal__faq-group__input',
    ) as HTMLInputElement;
    putFAQGroup({
      id: group?.id || '',
      name: input.value,
    });
  };

  return (
    <div
      className="modal-window-action"
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
      }}
    >
      <div className="modal-window-action__overlay" onClick={closeModal} />
      <div className="modal-window-action__modal__home" style={{ width: '500px' }}>
        {posting && updating && (
          <div className="modal-window-action__modal__home__loading">
            <Preloader />
          </div>
        )}
        <div className="modal-window-action__modal__title">
          <span>FAQ Group</span>
          <button onClick={closeModal}>
            <CloseBig />
          </button>
        </div>
        <div className="modal-window-action__modal__faq-group">
          <input
            onFocus={setDefaultBorder}
            className="modal-window-action__modal__faq-group__input"
            type="text"
            placeholder="group name"
            defaultValue={group?.name || ''}
          />
        </div>
        <div className="modal-window-action__modal__button">
          <button
            className="section-btn-gradient"
            onClick={group === null ? addGroup : updateGroup}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
