import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CookiePolicyState {
  acp: string;
}

const initialState: CookiePolicyState = {
  acp: 'No',
};

const CookiePolicySlice = createSlice({
  name: 'acp',
  initialState,
  reducers: {
    acceptCookiePolicy: (state, action: PayloadAction<string>) => {
      state.acp = action.payload;
    },
  },
});

export const { acceptCookiePolicy } = CookiePolicySlice.actions;
export default CookiePolicySlice.reducer;
