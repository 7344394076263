import React from 'react';

const AdminPlusIconWhite: React.FC = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 8.5H15.28" stroke="#FFF9F9" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M7.63989 0.859863V16.1399" stroke="#FFF9F9" strokeWidth="2" strokeMiterlimit="10" />
  </svg>
);

export default AdminPlusIconWhite;
