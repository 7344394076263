import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import AdminRemoveIcon from '../../../../general/assets/svg/AdminRemoveIcon';
import EditIconAction from '../../../../general/assets/svg/EditIconAction';
import Confirm from '../../../components/confirm/Confirm';

// Define the types for props
interface ActionButtonsProps {
  item: {
    id: string;
  };
  deleteMethod?: (id: string) => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ item, deleteMethod }) => {
  const [deleteProductAsk, setDeleteProductAsk] = useState<boolean>(false);

  return (
    <>
      <Link
        to={`/admin/products/product/` + item.id}
        className="table-block__tbody__change-item__button"
      >
        <EditIconAction />
      </Link>
      <button
        onClick={() => setDeleteProductAsk(!deleteProductAsk)}
        className="table-block__tbody__change-item__button"
      >
        <AdminRemoveIcon />
      </button>
      {deleteProductAsk && (
        <Confirm
          title="Delete confirm"
          text="Are you sure you want to delete this product?"
          handler={setDeleteProductAsk}
          clickEvent={() => {
            if (deleteMethod) deleteMethod(item.id);
          }}
        />
      )}
    </>
  );
};

export default ActionButtons;
