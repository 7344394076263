import '@draft-js-plugins/alignment/lib/plugin.css';
import '@draft-js-plugins/image/lib/plugin.css';
import '@draft-js-plugins/text-alignment/lib/plugin.css';
import './styles/buttonStyles.scss';
import './styles/editorStyles.scss';
import './styles/toolbarStyles.scss';
import './styles/urlToolbarStyles.scss';

import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createLinkPlugin from '@draft-js-plugins/anchor';
import {
  BlockquoteButton,
  BoldButton,
  CodeButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton,
} from '@draft-js-plugins/buttons';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import createDragNDropUploadPlugin from '@draft-js-plugins/drag-n-drop-upload';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createFocusPlugin from '@draft-js-plugins/focus';
import createImagePlugin from '@draft-js-plugins/image';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createTextAlignmentPlugin from '@draft-js-plugins/text-alignment';
import createVideoPlugin from '@draft-js-plugins/video';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import createArticlesService from '../../../../../general/services/articles';
import createMailingService from '../../../../../general/services/mailing';
import { adminInstance } from '../../../../../general/services/main/axiosInstances';
import converterFromHTML from './converters/converterFromHtml';
import converterToHTML from './converters/converterToHtml';
import HeadlinesButton from './headlines/Headlines';
import mockUpload from './mockUpload';
import alignmentStyles from './styles/alignmentStyles.module.scss';

export const sendImgSettingsConstatns = {
  article: 'article',
  mailing: 'mailing',
};

//#region plugin instance
const textAlignmentPlugin = createTextAlignmentPlugin({
  theme: {
    alignmentStyles: {
      draftLeft: alignmentStyles.draftLeft,
      draftRight: alignmentStyles.draftRight,
      draftCenter: alignmentStyles.draftCenter,
    },
  },
});

const inlineToolbarPlugin = createInlineToolbarPlugin({
  theme: {
    toolbarStyles: {
      toolbar: 'inlineToolbar',
    },
    buttonStyles: {
      button: 'inlineButton',
      buttonWrapper: 'inlineButtonWrapper',
      active: 'inlineButtonActive',
    },
  },
});

const linkPlugin = createLinkPlugin();
const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const decorator = composeDecorators(
  resizeablePlugin.decorator,
  alignmentPlugin.decorator,
  focusPlugin.decorator,
  blockDndPlugin.decorator,
);
const imagePlugin = createImagePlugin({ decorator });
const videoPlugin = createVideoPlugin();

const dragNDropFileUploadPlugin = createDragNDropUploadPlugin({
  handleUpload: mockUpload,
  addImage: (editorState, placeholderSrc: string) => {
    return imagePlugin.addImage(editorState, placeholderSrc, {});
  },
});

const { InlineToolbar } = inlineToolbarPlugin;
const { AlignmentTool } = alignmentPlugin;

const plugins = [
  textAlignmentPlugin,
  dragNDropFileUploadPlugin,
  blockDndPlugin,
  focusPlugin,
  alignmentPlugin,
  resizeablePlugin,
  imagePlugin,
  videoPlugin,
  inlineToolbarPlugin,
  linkPlugin,
];
//#endregion

interface TextEditorProps {
  setDescriptionPost?: (contentState: string) => void;
  errorDescriptionRequired: string;
  sendImgSettings: string;
  isNeedVideo: boolean;
}

const TextEditor = forwardRef(
  (
    { setDescriptionPost, errorDescriptionRequired, sendImgSettings, isNeedVideo }: TextEditorProps,
    ref,
  ) => {
    const regexLink = /youtu\.be\/([^?]+)/;
    const regetLinkUsed = /https:\/\/www\.youtube\.com\/embed\/([a-zA-Z0-9_-]+)\?si=[a-zA-Z0-9_-]+/;

    const [isValudatingUrlError, setIsValudatingUrlError] = useState(false);
    const [videoUrl, setVideoUrl] = useState<string>('');
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const editorRef = useRef<Editor | null>(null);
    const editorField = useRef<HTMLDivElement | null>(null);

    const onChange = (newEditorState: EditorState) => {
      setEditorState(newEditorState);
      if (setDescriptionPost) {
        const contextAsString = newEditorState.getCurrentContent().getPlainText();
        setDescriptionPost(contextAsString);
      }
    };

    const focusEditor = () => editorRef.current?.focus();

    //#region video
    const urlError = () => {
      setTimeout(() => {
        setIsValudatingUrlError(false);
      }, 2000);
      return 'error_required';
    };

    const extractYouTubeVideoId = (url: string): string | null => {
      const match = url.match(regexLink);
      if (match && match[1]) return match[1];
      else return null;
    };

    const isValidUrl = (url: string): boolean => {
      const urlRegex = regexLink;
      return urlRegex.test(url);
    };

    const addVideo = (src: string) => {
      if (isValidUrl(src)) {
        const newEditorState = videoPlugin.addVideo(editorState, {
          src: src,
        });
        onChange(newEditorState);
      } else {
        setIsValudatingUrlError(true);
        setVideoUrl('');
      }
    };
    //#endregion

    useImperativeHandle(ref, () => ({
      fromHTML(html: string) {
        const contentState = convertFromHTML(converterFromHTML())(html);
        setEditorState(EditorState.createWithContent(contentState));
      },

      toHTML() {
        return convertToHTML(converterToHTML(regetLinkUsed, extractYouTubeVideoId))(
          editorState.getCurrentContent(),
        );
      },
    }));

    const handleAddMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedMedia = event.target.files?.[0];
      if (!selectedMedia) return;

      const reader = new FileReader();
      reader.onloadend = (e) => {
        if (selectedMedia.type.includes('image')) {
          const img = new Image();
          const editor = document.getElementsByClassName('editor');

          img.onload = async function () {
            const newWidth =
              (this as HTMLImageElement).width > editor[0].clientWidth
                ? editor[0].clientWidth
                : (this as HTMLImageElement).width;

            const addNewImg = (img: string) => {
              const newEditorState = imagePlugin.addImage(editorState, img, {
                width: newWidth * 0.05,
              });
              onChange(newEditorState);
            };

            switch (sendImgSettings) {
              case sendImgSettingsConstatns.article: {
                try {
                  const { data } =
                    await createArticlesService(adminInstance).postArticleContentImage(
                      selectedMedia,
                    );
                  addNewImg(data);
                } catch (error) {
                  console.error('Error uploading article image:', error);
                }
                break;
              }
              case sendImgSettingsConstatns.mailing: {
                try {
                  const { data } =
                    await createMailingService(adminInstance).postImage(selectedMedia);
                  addNewImg(data);
                } catch (error) {
                  console.error('Error uploading mailing image:', error);
                }
                break;
              }
              default:
                break;
            }
          };

          img.src = e.target?.result as string;
        }
      };
      reader.readAsDataURL(selectedMedia);
      event.target.value = '';
    };

    const addVideoUrl = {
      margin: '0px',
    };

    return (
      <div className="text-editor__container">
        <div className="label_with_options">
          <label className="input_header" htmlFor="postName">
            Description
          </label>
          <div>
            <button onClick={() => document.getElementById('fileInputMedia')?.click()}>
              Add media
            </button>
            <input
              id="fileInputMedia"
              type="file"
              accept="image/*"
              onChange={handleAddMedia}
              style={{ display: 'none' }}
            />
          </div>
        </div>
        <div style={{ display: isNeedVideo ? 'flex' : 'none' }} className="url">
          <button onClick={() => addVideo(videoUrl)}>Add</button>
          <input
            style={addVideoUrl}
            className={isValudatingUrlError ? urlError() : ''}
            type="text"
            placeholder="Insert video URL (Share -> Copy URL)"
            value={videoUrl}
            onChange={(event) => setVideoUrl(event.target.value)}
          />
        </div>
        <div
          ref={editorField}
          onClick={focusEditor}
          className={errorDescriptionRequired ? 'error_required' : 'editor'}
        >
          <Editor
            placeholder={errorDescriptionRequired ? errorDescriptionRequired : undefined}
            editorKey="CustomInlineToolbarEditor"
            editorState={editorState}
            onChange={onChange}
            plugins={plugins}
            ref={editorRef}
          />
          <InlineToolbar>
            {(externalProps) => (
              <div>
                <div>
                  <HeadlinesButton
                    onOverrideContent={externalProps.onOverrideContent}
                    getEditorState={externalProps.getEditorState}
                    setEditorState={externalProps.setEditorState}
                    theme={externalProps.theme}
                  />
                  <ItalicButton {...externalProps} />
                  <BoldButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                </div>
                <div>
                  <textAlignmentPlugin.TextAlignment {...externalProps} />
                  <UnorderedListButton {...externalProps} />
                  <OrderedListButton {...externalProps} />
                </div>
                <div>
                  <CodeButton {...externalProps} />
                  <BlockquoteButton {...externalProps} />
                  <linkPlugin.LinkButton
                    onOverrideContent={
                      externalProps.onOverrideContent as (
                        component: React.ComponentType<any> | undefined,
                      ) => void
                    }
                    theme={externalProps.theme}
                  />
                </div>
              </div>
            )}
          </InlineToolbar>
          <AlignmentTool />
        </div>
      </div>
    );
  },
);

export default TextEditor;
