import React from 'react';

const ThumbsUpIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.27002 21.55H8.50002L10.83 22.5H18.75C19.4623 22.5007 20.1492 22.2356 20.6763 21.7566C21.2034 21.2776 21.5328 20.6191 21.6 19.91L22.48 10.78C22.48 10.64 22.48 10.5 22.48 10.36C22.48 9.78185 22.2503 9.22736 21.8415 8.81853C21.4327 8.4097 20.8782 8.18003 20.3 8.18003H15.63L16.15 7.09003C16.5598 6.23838 16.7717 5.30512 16.77 4.36003C16.77 3.98445 16.696 3.61254 16.5523 3.26555C16.4086 2.91856 16.1979 2.60328 15.9323 2.3377C15.6668 2.07213 15.3515 1.86146 15.0045 1.71773C14.6575 1.574 14.2856 1.50003 13.91 1.50003C13.6588 1.4987 13.4099 1.5472 13.1775 1.64272C12.9452 1.73823 12.7341 1.87887 12.5565 2.0565C12.3789 2.23412 12.2382 2.44521 12.1427 2.67754C12.0472 2.90987 11.9987 3.15883 12 3.41003C11.9988 4.73456 11.5592 6.02144 10.75 7.07003L8.42002 10.07H6.27002"
      fill="#25A8CF"
    />
    <path d="M6.27 9.13989H1.5V22.4999H6.27V9.13989Z" fill="#25A8CF" />
  </svg>
);

export default ThumbsUpIcon;
